import * as type from "../../types/API/commentTypes";

export function addBlogComment(payload: any) {
  return {
    type: type.ADD_COMMENT,
    payload: payload,
  };
}

export function deleteBlogComment(payload: any) {
  return {
    type: type.DELETE_COMMENT,
    payload: payload,
  };
}

export function approveComments (payload: any) {
  return {
    type: type.APPROVE_COMMENT,
    payload: payload,
  };
}

export function getApprovedBlogComments(payload: any) {
  return {
    type: type.GET_APPROVE_BLOG_COMMENT,
    payload: payload,
  };
}

// which are those not approved
export function getBlogCommentstoBeApprove() {
  return {
    type: type.GET_BLOG_COMMENTS_TO_BE_APPROVED,
    // payload: payload, // no payload
  };
}

export function getAllBlogsApprovedComments() {
  return {
    type: type.GET_ALL_BLOGS_APPROVED_COMMENTS,
  };
}

