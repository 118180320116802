import React from 'react'
import { Navigate } from 'react-router-dom'

interface PublicRouteProps{
    children: React.ReactNode
    loginData?: any
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children, loginData }) => {
    return  loginData?.data?.token ? <Navigate to="/dashboard" /> : <>{children}</>
}

const mapStateToProps = (state: any) => ({
    loginData: state.api.login,
});

export default PublicRoute