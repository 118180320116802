import * as type from "../../types/API/buyerSupplier";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const buyerSupplierReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_BUYER_SUPPLIER:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BUYER_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_BUYER_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addBuyerSupplierReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ADD_BUYER_SUPPLIER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_BUYER_SUPPLIER_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_BUYER_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editBuyerSupplierReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.EDIT_BUYER_SUPPLIER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_BUYER_SUPPLIER_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_BUYER_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteBuyerSupplierReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_BUYER_SUPPLIER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_BUYER_SUPPLIER_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_BUYER_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
