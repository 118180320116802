import React from "react";
import hero from "../../Utility/images/hero.png";
import "../../Utility/css/cardLayout.css";
import India from "../../Utility/images/flag.gif";
import Master from "../../Utility/images/ERP (1) (1).gif";
import Rocket from "../../Utility/images/icons8-rocket-40.png";
import "./HeroSection.css";

interface IHeroSectionProps {}

interface IHeroSectionState {}

class HeroSection extends React.Component<
  IHeroSectionProps,
  IHeroSectionState
> {
  constructor(props: IHeroSectionProps) {
    super(props);
  }
  render() {
    return (
      <>
        <section id="hero-13" className="hero-section">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-5 mb-100">
                <div className="hero-13-txt wow fadeInRight heading-hero-section">
                  {/* <span className="section-id">Strategies That Work</span> */}
                  <h6 className="sub-heading">
                    Automate your manufacturing workflow and enhance your margin
                    with ease
                  </h6>
                  <p className="p-lg b-30 mt-15 s-24">
                    Streamline your manufacturing process, create documents for
                    your sales and purchases, manage inventory, fetch real-time
                    data, and generate reports as per requirement.
                  </p>
                  {/* <a href="#banner-13" className="btn r-04 btn--theme hover--tra-black">Get started for free</a> */}
                  <div className="div-coming-soon">
                    <p className="p-lg  mt-10 s-24 text-rocket">
                      Coming Soon...{" "}
                      <span>
                        <img
                          className="rocket-icon"
                          src={Rocket}
                          alt="rocket-icon"
                        />
                      </span>
                    </p>
                  </div>
                  <p className="p-lg mt-5 s-24 text-india">
                    Proudly made in India for our manufacturers.{" "}
                    <span>
                      <img
                        className="image-india"
                        src={India}
                        alt="india-flag-icon"
                      />
                    </span>
                  </p>
                  <p className="p-lg btn-txt ico-15 s-24 text-credit">
                    <span className="flaticon-check"></span> No credit card
                    needed, free 90-day trial
                  </p>
                </div>
              </div>

              <div className="col-md-7">
                <div className="hero-13-img wow fadeInLeft">
                  <img
                    className="img-fluid image-hero-section"
                    src={Master}
                    alt="hero-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HeroSection;
