import * as type from '../../types/API/buyerSupplier'

export function getBuyerSupplier(payload: any) {
    return {
        type: type.GET_BUYER_SUPPLIER,
        payload: payload
    }
}

export function addBuyerSupplier(payload: any) {
    return {
        type: type.ADD_BUYER_SUPPLIER,
        payload: payload
    }
}

export function editBuyerSupplier(payload: any) {
    return {
        type: type.EDIT_BUYER_SUPPLIER,
        payload: payload
    }
}

export function deleteBuyerSupplier(payload: any) {
    return {
        type: type.DELETE_BUYER_SUPPLIER,
        payload: payload
    }
}