import { Card, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useEffect, useState } from "react";
import { AppConstants } from "../../../Appconstants";
import { connect } from "react-redux";
import { uploadImage } from "../../../redux/actions/API/imageUpload";
import { setDocumentSignatureData } from "../../../redux/actions/UI/createDocument";

interface ISignatureProps {
  companyId: number;
  userId: number;
  setDocumentSignatureData: Function;
  uploadImage: Function;
  token: string;
}

const Signature: React.FC<ISignatureProps> = ({
  setDocumentSignatureData,
  uploadImage,
}) => {
  const [file, setFile] = useState<string | null>(null);

  useEffect(() => {
    setDocumentSignatureData(file ? [file] : []);
  }, [file, setDocumentSignatureData]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      if (selectedFile.size > 32 * 1024) {
        message.error(
          `${selectedFile.name} exceeds the maximum file size of 32KB.`
        );
        return;
      }

      if (!["image/png", "image/jpeg"].includes(fileType)) {
        message.error("Only PNG and JPEG images are allowed.");
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile);

      uploadImage(formData);
    }
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1">
      <div className="card-content">
        <div className="title mb-10">
          {AppConstants.SIGNATURE_DETAIL.SIGNATURE_LABEL} &nbsp;
          <Tooltip title={AppConstants.SIGNATURE_DETAIL.SIGNATURE_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        <input type="file" accept="image/*" onChange={handleFileChange} />

        <div className="file-size-info">
          {AppConstants.SIGNATURE_DETAIL.SIGNATURE_UPLOAD_DETAILS}
        </div>
        <div>
          {file && (
            <img
              src={file}
              alt="Signature Preview"
              style={{ width: 100, height: 100, margin: "5px" }}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  token: state.api.login.data.token,
  dataSource: state.ui.createDocumentData?.documentSignatureData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentSignatureData: (payload: any) => dispatch(setDocumentSignatureData(payload)),
  uploadImage: (payload: any) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signature);