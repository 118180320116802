import * as type from "../../types/API/addressTypes";

export function addAddress(payload: any) {
  return {
    type: type.ADD_ADDRESS,
    payload: payload,
  };
}

export function getAddress(payload: any) {
  return {
    type: type.GET_ADDRESS,
    payload: payload,
  };
}

export function editAddress(payload: any) {
  return {
    type: type.EDIT_ADDRESS,
    payload: payload,
  };
}

export function deleteAddress(payload: any) {
  return {
    type: type.DELETE_ADDRESS,
    payload: payload,
  };
}

export function setDefaultAddress(payload: any) {
  return {
    type: type.SET_DEFAULT_ADDRESS,
    payload: payload,
  };
}
