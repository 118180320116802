import * as type from "../../types/API/uomType";

export function getUOM(payload: any) {
  return {
    type: type.GET_UOM,
    payload: payload,
  };
}

export function addUOM(payload: any) {
  return {
    type: type.ADD_UOM,
    payload: payload,
  };
}

export function editUOM(payload: any) {
  return {
    type: type.EDIT_UOM,
    payload: payload,
  };
}

export function deleteUOM(payload: any) {
  return {
    type: type.DELETE_UOM,
    payload: payload,
  };
}
