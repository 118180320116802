import { IAction } from "../../reducers/userMetadataReducer";
import { SET_APP_LOCATION } from "../../types/UI/appLocation";

export interface appLocation {
    pathname: string
}

export function setAppLocation(payload: appLocation ): IAction {
    return  {
        type: SET_APP_LOCATION,
        payload: payload
    }
}