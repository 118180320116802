import { Col, DatePicker, Input, Row, Select, Form, Button, Card, Modal, Divider } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment"
import './CustomMetaDataModal.css'

interface CustomMetaDataModalProps {
    mode: "form" | "table";
    customField: CustomField[];
    onSave: (fields: CustomField[]) => void;
    isVisible: boolean;
    onClose: () => void;
}

interface CustomField {
    key: string;
    label: string;
    fieldType: "text" | "integer" | "select" | "datetime";
    options?: string[];
    defaultValue: any;
}

const CustomMetaDataModal: React.FC<CustomMetaDataModalProps> = ({ mode, onSave, onClose, isVisible, customField }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [fields, setFields] = useState<CustomField[]>(customField);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);
    const [newFieldType, setNewFieldType] = useState<"text" | "integer" | "select" | "datetime">("text");
    const [newFieldLabel, setNewFieldLabel] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFieldOptions, setNewFieldOptions] = useState("");
    const [defaultValue, setDefaultValue] = useState("");
    const [isEditingIndex, setIsEditingIndex] = useState<number>()
    const [category, setCategory] = useState<string>("Sales Enquiry");
    const [isPageRefreshed, setIsPageRefreshed] = useState<boolean>(false);

    useEffect(() => {
        setFields(customFields);
    }, [customFields]);

    const getStorageKey = () => {
        return mode === "form" ? `customFields_form_${category}` : `customFields_table_${category}`;
    };

    useEffect(() => {
        if (!isEditing) {
            const savedFields = localStorage.getItem(getStorageKey());
            if (savedFields) {
                const parsedFields = JSON.parse(savedFields);
                setCustomFields(parsedFields);
                setFields(parsedFields);
            }
        }
    }, [category, mode, isEditing])

    const handleCustomModal = () => {
        if (newFieldLabel.trim() && newFieldType) {
            const newFieldKey = `customField_${customFields.length + 1}`;
            const newField: CustomField = {
                key: newFieldKey,
                label: newFieldLabel,
                fieldType: newFieldType as "text" | "integer" | "select" | "datetime", // Type assertion here
                options: newFieldType === "select" ? newFieldOptions.split(",").map(opt => opt.trim()) : undefined,
                defaultValue: defaultValue,
            };

            const updatedFields = isEditing
                ? customFields.map((field, index) => (index === isEditingIndex ? newField : field))
                : [...customFields, newField];

            setFields(updatedFields);
            setCustomFields(updatedFields);
            localStorage.setItem(getStorageKey(), JSON.stringify(updatedFields));
            resetForm()
            onSave(updatedFields);
            onClose();
        }
    };

    const handleSaveEdit = () => {
        if (newFieldLabel.trim() && newFieldType !== undefined && isEditingIndex !== undefined) {
            const newField: CustomField = {
                key: `customField_${isEditingIndex + 1}`,
                label: newFieldLabel,
                fieldType: newFieldType,
                options: newFieldType === "select" ? newFieldOptions.split(",").map(opt => opt.trim()) : undefined,
                defaultValue: defaultValue || "",
            };

            const updatedFields = isEditing
                ? customFields.map((field, index) => (index === isEditingIndex ? newField : field))
                : [...customFields, newField];

            setFields(updatedFields);
            setCustomFields(updatedFields);
            localStorage.setItem(getStorageKey(), JSON.stringify(updatedFields));
            resetForm();
            onSave(updatedFields);
            onClose();
        }
    };

    const resetForm = () => {
        setNewFieldLabel("");
        setNewFieldType("text");
        setNewFieldOptions("");
        setDefaultValue("");
        setIsEditing(false);
        setIsEditingIndex(undefined);
        setIsModalVisible(false);
    };

    const handleCancelCustom = () => {
        resetForm()
        onClose();
    };

    const handleEditCustomField = (index: number) => {
        const fieldToEdit = fields[index];
        setIsEditing(true);
        setIsEditingIndex(index);
        setNewFieldLabel(fieldToEdit.label);
        setNewFieldType(fieldToEdit.fieldType);
        setDefaultValue(fieldToEdit.defaultValue || "");
        if (fieldToEdit.fieldType === "select") {
            setNewFieldOptions(fieldToEdit.options?.join(", ") || "");
        }
    };

    const handleDeleteCustomField = (index: number) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
        setCustomFields(updatedFields);
        localStorage.setItem(getStorageKey(), JSON.stringify(updatedFields));
        onSave(updatedFields);
    }

    const handleLoadFields = () => {
        const savedFields = localStorage.getItem(`customFields_${category}`);
        if (savedFields) {
            setCustomFields(JSON.parse(savedFields));
            setIsPageRefreshed(true); // Set flag to true after loading
        }
    };

    const dateFormat = 'YYYY-MM-DD'

    return (
        <div>
            <Modal visible={isVisible} onCancel={handleCancelCustom}  className="modal-custom-fields" footer={null}>
                <Form onFinish={handleCustomModal}>
                    <Card className="card-container-select">
                        <div className="category-container">
                            <h6>Select Document</h6>
                            <Select
                                className="select-document"
                                value={category}
                                onChange={(value) => setCategory(value)}
                                options={[
                                    { value: "Sales Enquiry", label: "Sales Enquiry" },
                                    { value: "Sales Quotation", label: "Sales Quotation" },
                                ]}
                                style={{ marginBottom: 16 }}
                            />
                        </div>
                    </Card>
                    <Divider />

                    <Card className={`card-container-add ${newFieldType === "select" ? "select-active" : ""}`}>
                        <div className="new-field-container">
                            <h6 className="headingAddNewField">{mode === "form" ? "Add New Field" : "Add New Column"}</h6>
                            <Row justify="end">
                                <Button type="primary" className="buttonAddColumnField" onClick={handleCustomModal}>
                                    Add
                                </Button>
                            </Row>
                            <div className="divInputLabelName">
                                <Form.Item label="Field Name">
                                    <Input
                                        placeholder="Enter field name"
                                        value={isEditing ? "" : newFieldLabel}
                                        onChange={(e) => setNewFieldLabel(e.target.value)}
                                        className="labelInputModal"
                                    />
                                </Form.Item>
                            </div>
                            <div className="divSelectDataType">
                                <Form.Item className="fieldDataType" label="Data Type">
                                    <Select
                                        placeholder="Select field type"
                                        className="selectDatatype"
                                        onChange={(value) => setNewFieldType(value as "text" | "integer" | "select" | "datetime")}
                                        value={newFieldType}
                                        options={[
                                            { value: "text", label: "Text" },
                                            { value: "number", label: "Number" },
                                            { value: "select", label: "Select" },
                                            { value: "datetime", label: "Date" },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            {newFieldType === "select" && !isEditing && (
                                <div className="selectConditional">
                                    <Input
                                    placeholder="Enter options, separated by commas"
                                    value={newFieldOptions}
                                    onChange={(e) => setNewFieldOptions(e.target.value)}   
                                />
                                    </div>
                            )}
                            <div className={`divDefaultValue ${newFieldType === "select" ? 'margin-top-15' : ''}`}>
                                <Form.Item label="Default Value">
                                    {newFieldType === "datetime" ? (
                                        <DatePicker
                                            className="datePickerCustom"
                                            value={isEditing ? null : (defaultValue ? moment(defaultValue) : null)}
                                            onChange={(date, dateString) => setDefaultValue(dateString as string)}
                                            format={dateFormat}
                                        />
                                    ) : (
                                        <Input
                                            placeholder="Default value"
                                            value={isEditing ? "" : defaultValue}
                                            onChange={(e) => setDefaultValue(e.target.value)}
                                            className="inputDefault"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                    </Card>
                    <Divider />

                    <Card className="card-container" style={fields.length === 0 ? { height: '80px' } : {}}>
                        <div className="existing-fields-container">
                            <h6 style={
                                fields.length === 0
                                    ? { marginTop: '-13px' }
                                    : {}
                            }>Existing {mode === "form" ? "Fields" : "Columns"}</h6>
                            {fields.length > 0 ? (
                                fields.map((field, index) => (
                                    <div key={field.key} className="custom-field-item">
                                        {isEditing && isEditingIndex === index ? (
                                            <div className="edit-field-container">
                                                <div className="divInputLabelName">
                                                    <Form.Item label="Field Name"
                                                    >
                                                        <Input
                                                            placeholder="Enter field name"
                                                            value={newFieldLabel}
                                                            onChange={(e) => setNewFieldLabel(e.target.value)}
                                                            className="labelInputModal"
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="divSelectDataType">
                                                    <Form.Item className="fieldDataType" label="Data Type">
                                                        <Select
                                                            placeholder="Select field type"
                                                            className="selectDatatype"
                                                            onChange={(value) => setNewFieldType(value as "text" | "integer" | "select" | "datetime")}
                                                            value={newFieldType}
                                                            options={[
                                                                { value: "text", label: "Text" },
                                                                { value: "integer", label: "Integer" },
                                                                { value: "select", label: "Select" },
                                                                { value: "datetime", label: "DateTime" },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                {newFieldType === "select" && (
                                                    <Input
                                                        placeholder="Enter options, separated by commas"
                                                        value={newFieldOptions}
                                                        onChange={(e) => setNewFieldOptions(e.target.value)}
                                                        className="selectConditional"
                                                    />
                                                )}
                                               <div className={`divDefaultValue ${newFieldType === "select" ? 'margin-top-15' : ''}`}>
                                                <Form.Item label="Default Value">
                                                    {newFieldType === "datetime" ? (
                                                        <DatePicker
                                                            showTime
                                                            className="datePickerCustom"
                                                            value={defaultValue ? moment(defaultValue) : null}
                                                            onChange={(date, dateString) => setDefaultValue(dateString as string)}
                                                            format="YYYY-MM-DD"
                                                            picker="week"
                                                        />
                                                    ) : (
                                                        <Input
                                                            placeholder="Default value"
                                                            value={defaultValue}
                                                            onChange={(e) => setDefaultValue(e.target.value)}
                                                            className="inputDefault"
                                                        />
                                                    )}
                                                </Form.Item>
                                               </div>
                                                <Row justify="end">
                                                    <Button type="primary" onClick={handleCustomModal}>
                                                        Update
                                                    </Button>
                                                </Row>
                                            </div>
                                        ) : (
                                            <>
                                                <Input value={field.label} className="inputEditCustomField" />
                                                <Button type="link" onClick={() => handleEditCustomField(index)}>
                                                    Edit
                                                </Button>
                                                <Button type="link" danger className="delete-edit-custom" onClick={() => handleDeleteCustomField(index)}>
                                                    Delete
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className="paraghCustom">No custom {mode === "form" ? "fields" : "columns"} added yet.</p>
                            )}
                        </div>
                    </Card>
                </Form>

                {isPageRefreshed && (
                    <Button type="primary" onClick={handleLoadFields} style={{ marginTop: 16 }}>
                        Load Saved Fields
                    </Button>
                )}
            </Modal>
        </div>
    )
}

export default CustomMetaDataModal