import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";
import { addBlogComment, getApprovedBlogComments } from "../../redux/actions/API/commentActions";
import { extractBlogIdFromURL } from "../../Utility/blogUtility";

type NotificationType = "success" | "info" | "warning" | "error";

const { TextArea } = Input;

interface IAddBlogCommentsProps {
  addBlogComment: Function;
  addBlogCommentState: IDataResponse;
}

const AddBlogComments: React.FC<IAddBlogCommentsProps> = ({
  addBlogComment,
  addBlogCommentState,
}) => {
  const prevPropsRef = useRef<IAddBlogCommentsProps>();
  const [form] = Form.useForm();
  const [fullName, setFullName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [blogId, setBlogId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const blogId = extractBlogIdFromURL();
    setBlogId(blogId);
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.addBlogCommentState?.loading && !addBlogCommentState?.loading) {
      if (addBlogCommentState?.error?.length > 0) {
        openNotificationWithIcon("error", "Failed to add comment");
      } else {
        openNotificationWithIcon(
          "success",
          "Comment added successfully. It will be visible after approval."
        );
        setLoading(false);
      }
    }
    prevPropsRef.current = { addBlogComment, addBlogCommentState };
  }, [addBlogCommentState]);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    const blogCommentData = {
      fullName,
      userEmail,
      comments,
      blogId,
      ip_address: "127.0.0.1",
    };
    addBlogComment(blogCommentData);
    form.resetFields();
  };

  return (
    <>
      <Loader loading={loading}></Loader>
      <div
        // style={{
        //   maxWidth: "100%",
        //   padding: "20px",
        //   backgroundColor: "#f9f9f9",
        //   borderRadius: "8px",
        //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        // }}
      >
        <h2
          style={{
            fontSize: "24px",
            color: "#333",
            margin: "50px 0 20px 0"
          }}
        >
          Leave us a comment
        </h2>
        <Form form={form} onFinish={handleSubmit} layout="vertical" className="commentForm">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="fullname"
                label="Enter full name"
                rules={[
                  { required: true, message: "Please input your full name!" },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  className="input"
                  placeholder="Enter your email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="comments"
                label="Comments"
                rules={[
                  { required: true, message: "Please write comments!" },
                  { message: "Please write comments!" },
                ]}
              >
                <TextArea
                  rows={3}
                  placeholder="Enter your comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Comment
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  addBlogCommentState: state.api.addBlogComment
});
const mapDispatchToProps = (dispatch: any) => ({
  addBlogComment: (payload: any) => dispatch(addBlogComment(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBlogComments);
