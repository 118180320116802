import {
  Row,
  Col, 
} from "antd"; 
import "./documentDetails.css";

import { useEffect, useRef, useState } from "react";
import LogisticDetails from "../documentLogisticDetails/LogisticDetails";
import AdditionalDetails from "../documentAdditionalDetails/AdditionalDetails";
import Attachments from "../documentAttachmentDetails/Attachments";
import Signature from "../documentSignatureDetails/Signature";
import BankDetails from "../bankAccountDetails/BankDetails";

const DocumentDetails: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isEditing]);

  return (
    <>
      <div className="flexBox">
        <Attachments/>
        <Signature/>
        <BankDetails/>
      </div>
    </>
  );
};

export default DocumentDetails;
