import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { IManageCategoriesProps } from "./IManageCategories";
import { Alert, Button, Drawer, Form, Input, Space, Tree, Tooltip } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined, ArrowLeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import './manageCategories.css';
import { AppConstants } from "../../../Appconstants";

const { TreeNode } = Tree;

const ManageCategories: React.FC<IManageCategoriesProps> = (props) => {
    const [isManageCategoryOpen, setIsManageCategoryOpen] = useState<boolean>(props.isManageCategoryOpen);
    const [isAddManageCategoryOpen, setIsAddManageCategoryOpen] = useState<boolean>(props.isAddManageCategoryOpen);
    const [categoryName, setCategoryName] = useState<string>('');
    const [categoryDescription, setCategoryDescription] = useState<string>('');
    const [categoryLevel, setCategoryLevel] = useState<string>('Category');
    const [categories, setCategories] = useState<any>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [parentId, setParentId] = useState<string | null>(null);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [parentCategory, setParentCategory] = useState<string | null>(null);
    const [grandparentCategory, setGrandparentCategory] = useState<string | null>(null);

    useEffect(() => {
        setIsManageCategoryOpen(props.isManageCategoryOpen);
    }, [props.isManageCategoryOpen]);

    useEffect(() => {
        setIsAddManageCategoryOpen(props.isAddManageCategoryOpen);
    }, [props.isAddManageCategoryOpen]);

    useEffect(() => {
        if (props.categoryData) {
            setCategories(props.categoryData);
        }
        console.log('category data:', props.categoryData)
    }, [props.categoryData]);

    const handleSubmitCategory = () => {
        if (categoryName) {
            const newCategory = {
                id: Math.random().toString(36).substr(2, 9),
                name: categoryName,
                description: categoryDescription,
                children: [],
                parentId: parentId,
            };

            if (editMode && selectedNode) {
                const updatedCategories = updateCategoryTree(categories, selectedNode.key, {
                    ...selectedNode,
                    name: categoryName,
                    description: categoryDescription,
                });
                setCategories(updatedCategories);
            } else {
                const updatedCategories = parentId
                    ? addSubCategory(categories, parentId, newCategory)
                    : [...categories, newCategory];
                if (parentId) {
                    setExpandedKeys((prevKeys) => [...prevKeys, parentId]);
                }
                setCategories(updatedCategories);
            }

            resetForm();
        }
    };

    const resetForm = () => {
        setCategoryName('');
        setCategoryDescription('');
        setEditMode(false);
        setIsAddManageCategoryOpen(false);
        setParentId(null);
        setCategoryLevel(AppConstants.Category_Level.Category);
        setParentCategory(null);
        setGrandparentCategory(null);
    };

    const handleAddSubCategory = (category: any) => {
        setSelectedNode(category);
        setCategoryName('');
        setCategoryDescription('');
        setParentId(category.id);

        if (category.parentId) {
            setCategoryLevel(AppConstants.Category_Level.Micro_Category);
            const grandparent = getParentCategory(categories, category.parentId);
            setParentCategory(category.name);
            setGrandparentCategory(grandparent ? grandparent.name : null);
        } else {
            setCategoryLevel(AppConstants.Category_Level.SubCategory);
            setParentCategory(category.name);
            setGrandparentCategory(null);
        }
        setExpandedKeys((prevKeys) => [...prevKeys, category.id]);
        setIsAddManageCategoryOpen(true);
    };

    const handleEdit = (category: any) => {
        setSelectedNode(category);
        setCategoryName(category.name);
        setCategoryDescription(category.description);
        setEditMode(true);

        if (category.parentId) {
            setCategoryLevel(category.parentId ? AppConstants.Category_Level.Micro_Category : AppConstants.Category_Level.SubCategory );
        } else {
            setCategoryLevel('Category');
        }
        setIsAddManageCategoryOpen(true);
    };

    const handleDelete = (category: any) => {
        const updatedCategories = deleteCategory(categories, category.id);
        setCategories(updatedCategories);
    };

    const addSubCategory = (categories: any[], parentId: string, newCategory: any): any[] => {
        return categories.map((category) => {
            if (category.id === parentId) {
                return {
                    ...category,
                    children: [...(category.children || []), newCategory],
                };
            }
            return category.children
                ? { ...category, children: addSubCategory(category.children, parentId, newCategory) }
                : category;
        });
    };

    const updateCategoryTree = (categories: any[], key: string, updatedNode: any): any[] => {
        return categories.map(category => {
            if (category.id === key) {
                return { ...updatedNode };
            } else if (category.children) {
                return { ...category, children: updateCategoryTree(category.children, key, updatedNode) };
            }
            return category;
        });
    };

    const deleteCategory = (categories: any[], key: string): any[] => {
        return categories
            .filter(category => category.id !== key)
            .map(category => ({
                ...category,
                children: category.children ? deleteCategory(category.children, key) : [],
            }));
    };

    const getParentCategory = (categories: any[], parentId: string): any | null => {
        for (let category of categories) {
            if (category.id === parentId) return category;
            if (category.children) {
                const result = getParentCategory(category.children, parentId);
                if (result) return result;
            }
        }
        return null;
    };

    const toggleExpand = (key: string) => {
        setExpandedKeys((prevKeys) => {
            if (prevKeys.includes(key)) {
                return prevKeys.filter(k => k !== key);
            }
            return [...prevKeys, key];
        });
    };

    const renderSwitcherIcon = (category: any) => {
        const isExpanded = expandedKeys.includes(category.id);
        const hasChildren = category.children && category.children.length > 0;

        if (hasChildren) {
            return isExpanded ? (
                <DownOutlined className="expand-icon" onClick={(e) => toggleExpand(category.id)} />
            ) : (
                <RightOutlined className="expand-icon" onClick={(e) => toggleExpand(category.id)} />
            );
        } else {
            return null;
        }
    };

    const renderTreeNodes = (data: any[]) =>
        data.map(category => {
            const isTopLevel = !category.parentId;
            const isSubCategory = category.parentId && !getParentCategory(categories, category.parentId)?.parentId;
            const categoryLevel = isTopLevel
            ? AppConstants.Category_Level.Category
            : isSubCategory
            ? AppConstants.Category_Level.SubCategory
            : AppConstants.Category_Level.Micro_Category;
            return (
                <TreeNode
                    className="category-node"
                    title={(
                        <div>
                            <span>{category.name}</span>
                            <Space className="action-button" style={{ marginLeft: 'auto' }}>
                                {(isTopLevel || isSubCategory) && (
                                    <Tooltip title={isTopLevel ? AppConstants.Category_Level.Add_Sub_Category : AppConstants.Category_Level.Add_Micro_Category }>
                                        <Button
                                            className="button-icon"
                                            icon={<PlusOutlined />}
                                            onClick={() => handleAddSubCategory(category)}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip title={`Edit ${categoryLevel}`}>
                                <Button
                                    className="button-icon"
                                    icon={<EditOutlined />}
                                    onClick={() => handleEdit(category)}
                                />
                            </Tooltip>
                            <Tooltip title={`Delete ${categoryLevel}`}>
                                <Button
                                    className="button-icon"
                                    icon={<DeleteOutlined />}
                                    onClick={() => handleDelete(category)}
                                />
                            </Tooltip>
                            </Space>
                        </div>
                    )}
                    key={category.id}
                    selectable={false}
                    switcherIcon={renderSwitcherIcon(category)}
                >
                    {category.children && renderTreeNodes(category.children)}
                </TreeNode>
            );
        });


    return (
        <>
            <Drawer
                title={`Manage ${categoryLevel}`}
                placement="right"
                size="large"
                onClose={() => {
                    props.categoryDrawerStatus(false);
                    setIsManageCategoryOpen(false);
                }} 
                open={isManageCategoryOpen}
                extra={
                    <Space>
                        <Button onClick={() => {
                            props.categoryDrawerStatus(false);
                            setIsManageCategoryOpen(false);
                        }}>Cancel</Button>
                        {isAddManageCategoryOpen &&
                            <Button type="primary" onClick={() => handleSubmitCategory()}>
                                Submit
                            </Button>
                        }
                    </Space>
                }
            >
                {isAddManageCategoryOpen ? (
                    <>
                        <p className="backLinkSM" onClick={() => setIsAddManageCategoryOpen(false)}>
                            <ArrowLeftOutlined /> Back to Categories
                        </p>
                        <Alert message="You can easily manage Categories, Subcategories, and Microcategories by adding new ones, editing existing ones, or deleting any you no longer need. This helps keep your content organized and easy to navigate." type="info" />
                        <Form layout="vertical" className="category-form">
                            {grandparentCategory && (
                                <Form.Item label="Category">
                                    <Input value={grandparentCategory} readOnly />
                                </Form.Item>
                            )}
                            {parentCategory && (
                                <Form.Item label={grandparentCategory ? AppConstants.Category_Level.SubCategory : AppConstants.Category_Level.Category}>
                                    <Input value={parentCategory} readOnly />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="category"
                                label={`${categoryLevel} Name`}
                                rules={[{ required: true, message: `Please enter ${categoryLevel.toLowerCase()} name` }]}
                            >
                                <Input
                                    placeholder={`${categoryLevel} name`}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    value={categoryName}
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={`${categoryLevel} Description`}
                            >
                                <Input.TextArea
                                    placeholder={`Description of ${categoryLevel}`}
                                    onChange={(e) => setCategoryDescription(e.target.value)}
                                    value={categoryDescription}
                                />
                            </Form.Item>
                        </Form>
                    </>
                ) : (
                    <>
                        <Alert message="You can easily manage Categories, Subcategories, and Microcategories by adding new ones, editing existing ones, or deleting any you no longer need. This helps keep your content organized and easy to navigate." type="info" />
                        <div className="flexBox mt-20">
                            <Input placeholder="Search Category" className="input-search-category" />
                            <Button
                                type="primary"
                                className="buttonAddCategory"
                                onClick={() => {
                                    resetForm();
                                    setIsAddManageCategoryOpen(true);
                                    setEditMode(false);
                                    setCategoryLevel('Category');
                                    setSelectedNode(null);
                                }}
                            >
                                Add New Category
                            </Button>
                        </div>
                        {categories && categories.length > 0 ? (
                            <Tree className="list-category" showLine defaultExpandAll>{renderTreeNodes(categories)}</Tree>
                        ) : (
                            <div className="list-category center">
                                No Data Found!
                            </div>
                        )}
                    </>
                )}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    categoryData: state.api.getItems?.data?.category
});

const mapDispatchToProps = {
    // Add relevant dispatch actions here if needed
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories);