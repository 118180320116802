import React from "react";
import { connect } from "react-redux";

interface IDocumentSlicerProps {
    dataToPreview: any;
    companyName: string;
    logo: string;
}

const DocumentSlicer: React.FC<IDocumentSlicerProps> = ({ dataToPreview, companyName, logo }) => {
    return <div>
        <div className="documentHeader">
            <div>
                <h2>{dataToPreview?.documentType}</h2>
                <div className='mt-10 title'>Company Name: {companyName}</div>
                <div className='title'>Contact Number: +91-9876543210</div>
                <div className='title'>Address: {dataToPreview?.supplierBillingAddress}</div>

                <div className='mt-20 title'>Quotation Number: {dataToPreview?.documentNumber}</div>
                <div className='title'>
                    Quotation Date: {new Date(dataToPreview?.documentDate).toLocaleDateString("en-GB")}
                </div>
            </div>
            <div>
                <img
                    src={logo}
                    className="logo"
                    alt="Ease Margin"
                    style={{ margin: "0px 0px 0px -30px", height: "70px" }}
                />
            </div>
        </div>
        <h6>Buyer Details</h6>
        <div className="buyerSupplierContainer mt-10">
            <div className="supplierDetails" style={{ maxWidth: '40%' }}>
                <div>Company Name: {dataToPreview?.buyerName}</div>
                <div>Contact Number: {dataToPreview?.buyerContactNumber}</div>
                <div>Billing Address: {dataToPreview?.buyerBillingAddress}</div>
                <div>Delivery Address: {dataToPreview?.buyerDeliveryAddress} </div>
            </div>
            <div className="flexBox supplierDetails">
                <div>
                    <div>Enquiry No.: {dataToPreview?.enquiryNumber}</div>
                    <div>Enquiry Date: {new Date(dataToPreview?.enquiryDate).toLocaleDateString("en-GB")}</div>
                    <div>Exp. Delivery Date: {new Date(dataToPreview?.deliveryDate).toLocaleDateString("en-GB")}</div>
                    <div>Payment Term: {dataToPreview?.paymentTerm} </div>
                    <div>Reply Date: {dataToPreview?.replyDate} </div>
                    <div>Return Recieve Date: {dataToPreview?.returnRecieveDate} </div>
                    <div>Invoice Date: {dataToPreview?.invoiceDate} </div>
                </div>
                <div>
                    <div>OC Number: {dataToPreview?.OCNumber}</div>
                    <div>OC Date: {new Date(dataToPreview?.OCDate).toLocaleDateString("en-GB")}</div>
                    <div>POC Date: {new Date(dataToPreview?.POCDate).toLocaleDateString("en-GB")}</div>
                    <div>POC Name: {dataToPreview?.POCName} </div>
                    <div>POC Number: {dataToPreview?.POCNumber} </div>
                    <div>Transporter name: {dataToPreview?.transporterName} </div>
                    <div>Invoice No: {dataToPreview?.invoiceNumber} </div>
                </div>
                <div>
                    <div>TD No.: {dataToPreview?.TDNumber}</div>
                    <div>TD Date: {new Date(dataToPreview?.TDDate).toLocaleDateString("en-GB")}</div>
                    <div>TG No: {dataToPreview?.TGNumber}</div>
                    <div>Vehicle No: {dataToPreview?.VehicleNumber} </div>
                    <div>Bill Date: {dataToPreview?.billDate} </div>
                    <div>Reply Date: {dataToPreview?.replyDate} </div>
                    <div>Exp. Payment Date: {dataToPreview?.paymentDate} </div>
                </div>
            </div>
        </div>
        <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Item Description</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>UOM</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Qty.</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Discount %</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Total Discount %</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Tax Type</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Tax %</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Total Tax</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Total Before Tax</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Total After Tax</th>
                </tr>
            </thead>
            <tbody>
                {dataToPreview?.items?.map((item: any, index: number) => (
                    <tr key={item.id}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <span>{item.itemName}</span>
                            <br />
                            <span>{item.HSN !== "" ? "HSN Code - " + item.HSN : ""}</span>
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.UOM}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.quantity}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.price).toFixed(2)}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.discountOne}% - {item.discountTwo}%</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{Number(item?.totalDiscount).toFixed(2)}%</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.taxType}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.tax}%</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalTax).toFixed(2)}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalBeforeTax).toFixed(2)}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalAfterTax).toFixed(2)}</td>
                    </tr>
                ))}
            </tbody>
        </table>

        <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Additional Charges</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Tax%</th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>Total Charge</th>
                </tr>
            </thead>
            <tbody>
                {dataToPreview?.additionalCharges?.map((item: any, index: number) => (
                    <tr key={item.key}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.chargingFor}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {item.price}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.tax}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>₹ {item.total}</td>
                    </tr>
                ))}
            </tbody>
        </table>

        <div className="logisticDetails" style={{ columnGap: '100px' }}>
            <div className="logistic-info-section">
                <div className="bankDetails">
                    <h6>Bank Details</h6>
                    <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }} className='mt-10'>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Bank Name:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].bankName}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Account Holder Name:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].accountName}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Account Number:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].accountNumber}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">SWIFT Code:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].SWIFTCode}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">IFSC Code:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].IFSCCode}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">MICR Code:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].MICRCode}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Branch:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].branch}</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Address:</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview?.bankDetails[0].address}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h6 className='mt-20'>Logistic Details</h6>
                <p>{dataToPreview?.logisticDetails || "No logistic details"}</p>

                <h6 className="mt-20">Additional Details</h6>
                <p>
                    {dataToPreview?.additionalDetails || "No additional details"}
                </p>
                <h6 className="mt-20">Attachments</h6>
                <p>No attachment available</p>

                <h6>Terms and Conditions</h6>
                <p>{dataToPreview?.termsCondition}</p>

            </div>
            <div className="totals" style={{ marginTop: '25px' }}>
                <div className="total-item">
                    <span className="fw-500">Total Before Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalBeforeTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Total Tax (CGST and SGST)</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Total After Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item">
                    <span className="fw-500">Additional Charges</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + charge.total, 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="total-item grand-total">
                    <span className="fw-500">Grand Total</span>
                    <span className="fw-500">
                        ₹ {(
                            dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0) +
                            dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + Number(charge.total), 0)
                        ).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="signature total-item grand-total mt-20">
                    <div></div>
                    <div>
                        <img src={dataToPreview?.signature} alt="Signature" />
                        <p>Authorized Signature</p>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSlicer);
