import * as type from '../../types/API/bankDetailType'

export function addBankDetail(payload: any) {
    return {
      type: type.ADD_BANK_DETAIL,
      payload: payload,
    };
  }
  
  export function getBankDetail(payload: any) {
    return {
      type: type.GET_BANK_DETAIL,
      payload: payload,
    };
  }
  
  export function editBankDetail(payload: any) {
    return {
      type: type.EDIT_BANK_DETAIL,
      payload: payload,
    };
  }
  
  export function deleteBankDetail(payload: any) {
    return {
      type: type.DELETE_BANK_DETAIL,
      payload: payload,
    };
  }
  