import React from 'react';
import './App.css';
import Features from './Home/Components/Features';
import Header from './Home/Components/Header';
import HeroSection from './Home/Components/HeroSection';
import Statistics from './Home/Components/Statistics';
import TextContent from './Home/Components/TextContent';
import AppRoutes from './Home/Components/AppRoutes';
import { LoaderProvider } from './Home/Loader/LoadContext';
import Loader from './Home/Loader/Loader';
import LLMBot from './LLMBot/LLMBot';

function App() {
  return (
    <LoaderProvider>
      <div className="App">
        <AppRoutes />
        <LLMBot/>
      </div>
    </LoaderProvider>
  );
}

export default App;
