import React, { useState } from "react";
import {
  Alert,
  Button,
  Drawer,
  Result,
  Space,
  Progress,
  Typography,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import MicrosoftLogo from "../../../Utility/images/microsoft-icon.png";
import ItemExcelTemplate from "../../../Templates/Item-excel-template.xlsx";
import StoreExcelTemplate from "../../../Templates/Stores-excel-template.xlsx";
import CompanyExcelTemplate from "../../../../src/Templates/company-excel-template.xlsx";
import Success from "../../../Utility/images/success.png";
import "./bulkUpload.css";

const { Text } = Typography;
interface IBulkUploadProps {
  title: string;
  onClose: () => void;
  open: boolean;
  uploadTemplateText: string;
  templatePath: string;
  resultTitle: string;
  resultSubTitle: string;
}

const BulkUpload: React.FC<IBulkUploadProps> = ({
  title,
  onClose,
  open,
  uploadTemplateText,
  templatePath,
  resultTitle,
  resultSubTitle,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileError, setFileError] = useState<string | null>(null);
  const [file, setFile] = useState<RcFile | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const MAX_FILE_SIZE_KB = 25 * 1024;
  const VALID_FILE_TYPES = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  const handleDownload = () => {
    try {
      const templateMap: Record<string, string> = {
        "Item-excel-template": ItemExcelTemplate,
        "Stores-excel-template": StoreExcelTemplate,
        "company-excel-template": CompanyExcelTemplate,
      };
      const downloadUrl = templateMap[templatePath];
      if (!downloadUrl) throw new Error("Template not found");

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${templatePath}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const validateFile = (file: RcFile): boolean => {
    const isValidType = VALID_FILE_TYPES.includes(file.type);
    const isValidSize = file.size / 1024 < MAX_FILE_SIZE_KB;

    if (!isValidType) {
      setFileError(
        "Invalid file format. Please upload a valid XLS, XLSX, or CSV file."
      );
      return false;
    }

    if (!isValidSize) {
      setFileError(
        `File size exceeds the limit of ${25} MB.`
      );
      return false;
    }

    setFileError(null);
    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] as RcFile;
    if (selectedFile) {
      const isValid = validateFile(selectedFile);
      if (isValid) {
        setFile(selectedFile);
      }
    }
  };

  const handleUpload = () => {
    if (!file) {
      setFileError("No file selected.");
      return;
    }

    if (!validateFile(file)) return;

    setUploading(true);
    setUploadProgress(0);
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        setUploading(false);
        setUploadSuccess(true);
      }
    }, 500);
  };

  const resetState = () => {
    setFile(null);
    setFileError(null);
    setUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  return (
    <>
      <Drawer
        title={title}
        width={720}
        onClose={() => {
          resetState();
          onClose();
        }}
        open={open}
        maskClosable={false}
        extra={
          <Space>
            <Button
              onClick={() => {
                resetState();
                onClose();
              }}
            >
              Cancel
            </Button>
            {!uploadSuccess && (
              <Button onClick={handleUpload} type="primary">
                Upload
              </Button>
            )}
          </Space>
        }
      >
        {!uploadSuccess ? (
          <>
            <Alert
              message={(() => {
                switch (title) {
                  case "Bulk Upload":
                    return "You can create a bulk upload here, you simply need to download the excel template from the button below, fill it according to your need and submit it and your items will be created in bulk.";
                  case "Physical Stock Reconciliation":
                    return "Download the reconciliation template, update it, and upload to reconcile stocks.";
                  case "Bulk Edit Item":
                    return "You can perform a bulk edit here. Edit multiple items at once and update them as needed.";
                  default:
                    return "Please follow the instructions for your selected operation.";
                }
              })()}
              type="info"
              showIcon
              style={{ marginBottom: 16 }}
            />

            <div className="link-container mt-20">
              <div className="sub-link-container">
                <Space className="container-download">
                  <img
                    src={MicrosoftLogo}
                    className="logo-excel"
                    alt="Microsoft Logo"
                  />
                  <Text>{uploadTemplateText}</Text>
                </Space>
                <Button className="download-button" onClick={handleDownload}>
                  Download
                </Button>
              </div>
            </div>
            <div className="bulk-drawer-container">
              <div className="upload-input-container">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <UploadOutlined className="file-add-icon" /> Drag and Drop
                  file here or Choose file
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileChange}
                />
              </div>
              <div className="text-container">
                <p className="ant-upload-hint">
                  Supported formats: XLS, XLSX, CSV
                </p>
                <p>Maximum size: 25MB</p>
              </div>
              {file && (
                <div className="file-info flexBox">
                  <Text>{file.name}</Text>
                  <DeleteOutlined
                    onClick={() => setFile(null)}
                    style={{ marginLeft: 8, cursor: "pointer", color: "red" }}
                  />
                </div>
              )}
              {fileError && (
                <Alert
                  message={fileError}
                  type="error"
                  showIcon
                  style={{ marginTop: 16 }}
                />
              )}
              {uploadProgress > 0 && (
                <Progress
                  percent={uploadProgress}
                  status={uploadProgress === 100 ? "success" : "active"}
                  style={{ marginTop: 16 }}
                />
              )}
            </div>
          </>
        ) : (
          <Result
            icon={<img src={Success} alt="Success" />}
            title={resultTitle}
            subTitle={resultSubTitle}
          />
        )}
      </Drawer>
    </>
  );
};

export default BulkUpload;
