export const GET_BUYER_SUPPLIER = 'GET_BUYER_SUPPLIER';
export const GET_BUYER_SUPPLIER_SUCCESS = 'GET_BUYER_SUPPLIER_SUCCESS';
export const GET_BUYER_SUPPLIER_FAILED = 'GET_BUYER_SUPPLIER_FAILED';

export const ADD_BUYER_SUPPLIER = 'ADD_BUYER_SUPPLIER';
export const ADD_BUYER_SUPPLIER_SUCCESS = 'ADD_BUYER_SUPPLIER_SUCCESS';
export const ADD_BUYER_SUPPLIER_FAILED = 'ADD_BUYER_SUPPLIER_FAILED';

export const EDIT_BUYER_SUPPLIER = 'EDIT_BUYER_SUPPLIER';
export const EDIT_BUYER_SUPPLIER_SUCCESS = 'EDIT_BUYER_SUPPLIER_SUCCESS';
export const EDIT_BUYER_SUPPLIER_FAILED = 'EDIT_BUYER_SUPPLIER_FAILED';

export const DELETE_BUYER_SUPPLIER = 'DELETE_BUYER_SUPPLIER';
export const DELETE_BUYER_SUPPLIER_SUCCESS = 'DELETE_BUYER_SUPPLIER_SUCCESS';
export const DELETE_BUYER_SUPPLIER_FAILED = 'DELETE_BUYER_SUPPLIER_FAILED';