import React, { useEffect } from "react";
import { List, Avatar, Layout } from "antd";
import { connect } from "react-redux";
import { Content } from "antd/es/layout/layout";
import { getApprovedBlogComments } from "../../redux/actions/API/commentActions";
import { extractBlogIdFromURL } from "../../Utility/blogUtility";

interface IApprovedBlogCommentList {
  approvedComments: any;
  getApprovedBlogComments: Function;
}

const ApprovedBlogCommentList: React.FC<IApprovedBlogCommentList> = ({
  approvedComments,
  getApprovedBlogComments,
}) => {
  const blogId = extractBlogIdFromURL();

  useEffect(() => {
    getApprovedBlogComments({ blogId: Number(blogId) });
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (approvedComments && approvedComments.length > 0) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Content
            style={{
              maxWidth: "100%",
              padding: "5px",
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={approvedComments}
              renderItem={(item: any) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.id}`}
                      />
                    }
                    title={item.fullName || "Anonymous"}
                    description={
                      <>
                        <div style={{ color: "gray", fontSize: "0.85em" }}>
                          {item.createdAt
                            ? formatDate(item.createdAt)
                            : "Unknown"}
                        </div>
                        <div>{item.comments}</div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Content>
        </div>
      </Layout>
    );
  } else {
    return <div></div>;
  }
};
const mapStateToProps = (state: any) => ({
  approvedComments: state.api.getApprovedBlogComments.data?.reverse(),
  getApprovedBlogCommentsState: state.api.getApprovedBlogComments,
});
const mapDispatchToProps = (dispatch: any) => ({
  getApprovedBlogComments: (payload: any) =>
    dispatch(getApprovedBlogComments(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovedBlogCommentList);