import React, {useEffect} from 'react';
import Features from './Features';
import Header from './Header';
import HeroSection from './HeroSection';
import Statistics from './Statistics';
import TextContent from './TextContent';
import LLMBot from '../../LLMBot/LLMBot';

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id='App' className="App" style={{overflow:'hidden'}} onLoad={() => window.scrollTo(0, 0)}>
      <Header></Header>
      <HeroSection></HeroSection>
      {/* <Statistics></Statistics> */}
      <Features></Features>
      <TextContent></TextContent>
    </div>
  );
}

export default HomePage;
