export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';

export const GET_DOCUMENTS_BY_ID = 'GET_DOCUMENTS_BY_ID';
export const GET_DOCUMENTS_BY_ID_SUCCESS = 'GET_DOCUMENTS_BY_ID_SUCCESS';
export const GET_DOCUMENTS_BY_ID_FAILED = 'GET_DOCUMENTS_BY_ID_FAILED';

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED';