import { Card, Tooltip, message } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useEffect, useState } from "react";
import { AppConstants } from "../../../Appconstants";
import { connect } from "react-redux";
import { uploadImage } from "../../../redux/actions/API/imageUpload";
import { setDocumentAttachmentsData } from "../../../redux/actions/UI/createDocument";

interface IAttachmentsProps {
  companyId: number;
  userId: number;
  token: string;
  setDocumentAttachmentsData: Function;
  uploadImage: Function;
}

const Attachments: React.FC<IAttachmentsProps> = ({ setDocumentAttachmentsData, uploadImage, token }) => {
  const [attachments, setAttachments] = useState<Array<{ base64: string, name: string, type: string }>>([]);

  useEffect(() => {
    setDocumentAttachmentsData(attachments.map((att) => att.base64));
  }, [attachments, setDocumentAttachmentsData]);

  const handleAttachmentsChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAttachments = event.target.files?.[0];

    if (selectedAttachments) {
      const attachmentsType = selectedAttachments.type;
      if (selectedAttachments.size > 5 * 1024 * 1024) {
        message.error(`${selectedAttachments.name} exceeds the maximum file size of 5MB.`);
        return;
      }

      const base64String = await readFileAsBase64(selectedAttachments);

      if (base64String) {
        if (attachments.length >= 5) {
          message.error("You can upload a maximum of 5 files.");
          return;
        }

        setAttachments((prevAttachments) => [
          ...prevAttachments,
          { base64: base64String, name: selectedAttachments.name, type: selectedAttachments.type }
        ]);

        const blob = base64ToBlob(base64String, attachmentsType);
        const formData = new FormData();
        formData.append("image", blob, selectedAttachments.name);

        uploadImage(formData);
      }
    }
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const renderAttachmentPreview = (attachment: { base64: string, name: string, type: string }, index: number) => {
    if (attachment.type.startsWith("image/")) {
      return (
        <img
          key={index}
          src={attachment.base64}
          alt={`Attachment ${index + 1}`}
          style={{ width: 100, height: 100, margin: "5px" }}
        />
      );
    } else {
      // For non-image files, show the file name with a download link
      return (
        <div key={index} style={{ margin: "5px" }}>
          <a href={attachment.base64} download={attachment.name}>
            {attachment.name}
          </a>
        </div>
      );
    }
  };

  return (
    <>
      <Card className="custom-card no-header-border flex-grow-1 fileCard">
        <div className="card-content">
          <div className="title mb-10">
            {AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_LABEL} &nbsp;
            <Tooltip title={AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_LABEL}>
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          </div>
          <input
            type="file"
            accept="*/*"
            onChange={handleAttachmentsChange}
          />
          <div className="flexBox mt-10 fileList">
            <div>attachment_2113213123.png</div>
            <div className="deleteIcon"><DeleteOutlined /></div>
          </div>
          <div className="file-size-info">
            {AppConstants.ATTACHEMETS_DETAIL.ATTACHEMETS_UPLOAD_DETAILS}
          </div>
          <div>
            {attachments.map((attachment, index) =>
              renderAttachmentPreview(attachment, index)
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  token: state.api.login.data.token,
  dataSource: state.ui.createDocumentData?.documentAttachmentsData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentAttachmentsData: (payload: any) => dispatch(setDocumentAttachmentsData(payload)),
  uploadImage: (payload: any) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
