import * as type from "../../types/API/types";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getCategoriesReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case type.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
