import { TableProps, Tooltip } from "antd";
import React from "react";
import { Space, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { AppConstants } from "../../Appconstants";
import ColumnWidths from "./columnWidth";

interface IItems {
  numberOfItems: number;
  itemValueBeforeTax: string;
  extraChargesBeforeTax: string;
  totalBeforeTax: string;
  totalTax: string;
  totalAfterTax: string;
  nonTaxableExtraCharge: string;
  tcsAmount: string;
  ProformaGrandTotal: string;
  advancePaid: string;
  amountToPay: string;
  paymentTerm: string;
  paymentDueDate: string;
}

interface DataType {
  documentNumber: string;
  documentDate: string;
  documentType: string;
  documentStatus: string;
  poNumber: string;
  poDate: string;
  ocNumber: string;
  ocDate: string;
  ocDeliveryDate: string;
  items: IItems[];
}

const columnRender = {
  renderDocumentColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "documentNumber",
    key: "documentNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderDocumentStatusColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "documentStatus",
    key: "documentStatus",
    width: ColumnWidths.DEFAULT,
    render: (status: number) =>
      status === 1
        ? AppConstants.DOCUMENT_STATUS.Sent
        : AppConstants.DOCUMENT_STATUS.Not_Sent,
  }),

  renderDocumentDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "documentDate",
    key: "documentDate",
    width: ColumnWidths.DEFAULT,
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
  }),

  renderOCConversionStatusColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "OCConversionStatus",
    key: "OCConversionStatus",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerNameColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerName",
    key: "buyerName",
    width: ColumnWidths.DEFAULT,
  }),

  renderDraftDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "draftDate",
    key: "draftDate",
    width: ColumnWidths.DEFAULT,
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
  }),

  renderEnquiryDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "enquiryDate",
    key: "enquiryDate",
    width: ColumnWidths.DEFAULT,
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
  }),

  renderOCCreatedColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "ocCreated",
    key: "ocCreated",
    width: ColumnWidths.DEFAULT,
    render: (ocCreated) => (ocCreated ? "Yes" : "No"),
  }),

  renderItemValueBeforeTaxColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "itemValueBeforeTax",
    key: "itemValueBeforeTax",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      ` ${
        record.items
          ?.reduce(
            (acc: number, item: any) => acc + Number(item.totalBeforeTax),
            0
          )
          .toFixed(2) ?? 0
      }`,
  }),

  renderExtraChargeBeforeTaxColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "extraChargesBeforeTax",
    key: "extraChargesBeforeTax",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      `${
        record.additionalCharges
          ?.reduce((acc: number, charge: any) => acc + charge.total, 0)
          .toFixed(2) ?? 0
      }`,
  }),

  renderTotalBeforeTaxColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "totalBeforeTax",
    key: "totalBeforeTax",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      ` ${
        record.items
          ?.reduce(
            (acc: number, item: any) => acc + Number(item.totalBeforeTax),
            0
          )
          .toFixed(2) ?? 0
      }`,
  }),

  renderTotalTaxColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "totalTax",
    key: "totalTax",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      ` ${
        record.items
          ?.reduce((acc: number, item: any) => acc + Number(item.totalTax), 0)
          .toFixed(2) ?? 0
      }`,
  }),

  renderTotalAfterTaxColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "totalAfterTax",
    key: "totalAfterTax",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      ` ${
        record.items
          ?.reduce(
            (acc: number, item: any) => acc + Number(item.totalAfterTax),
            0
          )
          .toFixed(2) ?? 0
      }`,
  }),

  renderNonTaxableExtraCharge: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "nonTaxableExtraCharges",
    key: "nonTaxableExtraCharges",
    width: ColumnWidths.DEFAULT,
  }),

  renderTcsAmountColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "tcsAmount",
    key: "tcsAmount",
    width: ColumnWidths.DEFAULT,
  }),

  renderGrandTotalColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "grandTotal",
    key: "grandTotal",
    width: ColumnWidths.DEFAULT,
    render: (_: any, record: any) =>
      ` ${
        (
          record.items?.reduce(
            (acc: number, item: any) => acc + Number(item.totalAfterTax),
            0
          ) +
          record.additionalCharges?.reduce(
            (acc: number, charge: any) => acc + Number(charge.total),
            0
          )
        ).toFixed(2) ?? 0
      }`,
  }),

  renderOCNumberColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "OCNumber",
    key: "OCNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderPaymentTermColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "paymentTerm",
    key: "paymentTerm",
    width: ColumnWidths.DEFAULT,
  }),

  renderEnquiryNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "enquiryNumber",
    key: "enquiryNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderNumberOfItemsColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "numberOfItems",
    key: "numberOfItems",
    width: ColumnWidths.DEFAULT,
  }),

  renderOriginalCreationDate: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "originalCreationDate",
    key: "originalCreationDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderInvoicingStatusColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoicingStatus",
    key: "invoicingStatus",
    width: ColumnWidths.DEFAULT,
  }),

  renderPONumberColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "POCNumber",
    key: "POCNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderPODateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "POCDate",
    key: "POCDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderDeliveryDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    width: ColumnWidths.DEFAULT,
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
  }),

  renderAdvancePaidColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "advancePaid",
    key: "advancePaid",
    width: ColumnWidths.DEFAULT,
  }),

  renderOCBalanceColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "OCBalance",
    key: "OCBalance",
    width: ColumnWidths.XTRALARGE,
  }),

  renderInvoiceRaisedColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoiceRaisedTotalValue",
    key: "invoiceRaisedTotalValue",
    width: ColumnWidths.LARGE,
  }),

  renderBalanceInvoiceColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "balanceInvoiveToBeRaised",
    key: "balanceInvoiveToBeRaised",
    width: ColumnWidths.LARGE,
  }),

  renderCommentsColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "comments",
    key: "comments",
    width: ColumnWidths.DEFAULT,
  }),

  renderSQNumberColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "SQNumber",
    key: "SQNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderSQDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "SQDate",
    key: "SQDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderCGSTColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "CGST",
    key: "CGST",
    width: ColumnWidths.DEFAULT,
  }),

  renderSGSTColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "SGST",
    key: "SGST",
    width: ColumnWidths.DEFAULT,
  }),

  renderIGSTColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "IGST",
    key: "IGST",
    width: ColumnWidths.DEFAULT,
  }),

  renderCessColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "Cess",
    key: "Cess",
    width: ColumnWidths.DEFAULT,
  }),

  renderDrafterNameColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "drafterName",
    key: "drafterName",
    width: ColumnWidths.DEFAULT,
  }),

  renderSenderNameColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "senderName",
    key: "senderName",
    width: ColumnWidths.DEFAULT,
  }),

  renderStoreNameColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "store",
    key: "store",
    width: ColumnWidths.DEFAULT,
  }),

  renderNetworkTagsColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "networkTags",
    key: "networkTags",
    width: ColumnWidths.DEFAULT,
  }),

  renderTransactionTagsColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "transactionTags",
    key: "transactionTags",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerDeliveryAddressColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddress",
    key: "buyerDeliveryAddress",
    width: ColumnWidths.MAX,
  }),

  renderCustomerDeliveryAddressGSTINColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddressGSTIN",
    key: "buyerDeliveryAddressGSTIN",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerDeliveryAddressStateColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddressState",
    key: "buyerDeliveryAddressState",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerDeliveryAddressCountryColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddressCountry",
    key: "buyerDeliveryAddressCountry",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerDeliveryAddressPINColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddressPIN",
    key: "buyerDeliveryAddressPIN",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerBillingAddressColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerBillingAddress",
    key: "buyerBillingAddress",
    width: ColumnWidths.MAX,
  }),

  renderCustomerBillingAddressGSTINColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerBillingAddressGSTIN",
    key: "buyerBillingAddressGSTIN",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerBillingAddressStateColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerBillingAddressState",
    key: "buyerBillingAddressState",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerBillingAddressCountryColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerBillingAddressCountry",
    key: "buyerBillingAddressCountry",
    width: ColumnWidths.LARGE,
  }),

  renderCustomerBillingAddressPINColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerBillingAddressPIN",
    key: "buyerBillingAddressPIN",
    width: ColumnWidths.LARGE,
  }),

  renderAttachmentsColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "attachments",
    key: "attachments",
    width: ColumnWidths.DEFAULT,
  }),

  renderProformaInvoiceRaisedTotalValueColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "proformaInvoiceRaisedTotalValue",
    key: "proformaInvoiceRaisedTotalValue",
    width: "300px",
  }),

  renderLastProrformaInvoiceNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastProformaInvoiceNumber",
    key: "lastProformaInvoiceNumber",
    width: ColumnWidths.LARGE,
  }),

  renderLastProformaInvoiceDateColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastProformaInvoiceDate",
    key: "lastProformaInvoiceDate",
    width: ColumnWidths.LARGE,
  }),

  renderLastInvoiceNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastInvoiceNumber",
    key: "lastInvoiceNumber",
    width: ColumnWidths.LARGE,
  }),

  renderLastInvoiceDateColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastInvoiceDate",
    key: "lastInvoiceDate",
    width: ColumnWidths.LARGE,
  }),

  renderTotalPaymentLoggedColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "totalPaymentLogged",
    key: "totalPaymentLogged",
    width: ColumnWidths.LARGE,
  }),

  renderPercentageTotalPaymentLoggedColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "percentageTotalPaymentLogged",
    key: "percentageTotalPaymentLogged",
    width: ColumnWidths.LARGE,
  }),

  renderPercentageTotalPaymentToLogColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "percentagePaymentToLog",
    key: "percentagePaymentToLog",
    width: ColumnWidths.LARGE,
  }),

  renderBalanceAmountToPayColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "balanceAmountToPay",
    key: "balanceAmountToPay",
    width: ColumnWidths.LARGE,
  }),

  renderInvoiceNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoiceNumber",
    key: "invoiceNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderInvoiceAmountColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoiceAmount",
    key: "invoiceAmount",
    width: ColumnWidths.DEFAULT,
  }),

  renderInvoiceDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoiceDate",
    key: "invoiceDate",
    width: ColumnWidths.DEFAULT,
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
  }),

  renderTransporterNameColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "transporterName",
    key: "transporterName",
    width: ColumnWidths.LARGE,
  }),

  renderDispatchDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "dispatchDate",
    key: "dispatchDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderTrackingNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "trackingNumber",
    key: "trackingNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderDeliveryLocationColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "deliveryLocation",
    key: "deliveryLocation",
    width: ColumnWidths.LARGE,
  }),

  renderTransportDocumentNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "transportDocumentNumber",
    key: "transportDocumentNumber",
    width: ColumnWidths.XTRALARGE,
  }),

  renderTransportGSTNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "transporterGSTNumber",
    key: "transporterGSTNumber",
    width: ColumnWidths.XTRALARGE,
  }),

  renderTransportVehicleNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "vehicleNumber",
    key: "vehicleNumber",
    width: ColumnWidths.LARGE,
  }),

  renderPayToTransportColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "payToTransport",
    key: "payToTransport",
    width: ColumnWidths.DEFAULT,
  }),

  renderInvoiceTypeColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "invoiceType",
    key: "invoiceType",
    width: ColumnWidths.DEFAULT,
  }),

  renderChallanNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "challanNumber",
    key: "challanNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderChallanDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "challanDate",
    key: "challanDate",
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
    width: ColumnWidths.DEFAULT,
  }),

  renderOCDateColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "OCDate",
    key: "OCDate",
    render: (text: string) => {
      return text ? new Date(text).toLocaleDateString() : "";
    },
    width: ColumnWidths.DEFAULT,
  }),

  renderPaymentStatusColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    width: ColumnWidths.DEFAULT,
  }),

  renderBalanceAmountColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "balanceAmount",
    key: "balanceAmount",
    width: ColumnWidths.DEFAULT,
  }),

  renderCreditNoteNumber: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "creditNoteNumber",
    key: "creditNoteNumber",
    width: ColumnWidths.DEFAULT,
  }),

  renderTotalCreditNoteAmount: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "totalCreditNoteAmount",
    key: "totalCreditNoteAmount",
    width: ColumnWidths.DEFAULT,
  }),

  renderDebitNoteNumber: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "debitNoteNumber",
    key: "debitNoteNumber",
  }),

  renderTotalDebitNoteAmount: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    width: ColumnWidths.DEFAULT,
  }),

  renderPaymentDueDate: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "paymentdDueDate",
    key: "paymentdDueDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerReferenceColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "customerReference",
    key: "customerReference",
    width: ColumnWidths.DEFAULT,
  }),

  renderLastModifiedDateColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastModifiedDate",
    key: "lastModifiedDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderPlaceofSupplyColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "placeOfSupply",
    key: "placeOfSupply",
    width: ColumnWidths.DEFAULT,
  }),

  renderVendorCodeColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "vendorCode",
    key: "vendorCode",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerDeliveryAddressCityColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerDeliveryAddressCity",
    key: "buyerDeliveryAddressCity",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerBillingAddressCityColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "customerBillingAddressCity",
    key: "customerBillingAddressCity",
    width: ColumnWidths.DEFAULT,
  }),

  renderSalesPersonColumn: (title: string): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "salesPerson",
    key: "salesPerson",
    width: ColumnWidths.DEFAULT,
  }),

  renderSalesReturnAmountColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastModifiedDate",
    key: "lastModifiedDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderSalesReturnNumberColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "lastModifiedDate",
    key: "lastModifiedDate",
    width: ColumnWidths.DEFAULT,
  }),

  renderCustomerContactColumn: (
    title: string
  ): ColumnsType<DataType>[number] => ({
    title,
    dataIndex: "buyerContact",
    key: "buyerContact",
    width: ColumnWidths.DEFAULT,
  }),
};

export const salesEnquiryColumns: TableProps<DataType>["columns"] = [
  columnRender.renderDocumentColumn("Doc No."),
  columnRender.renderDocumentDateColumn("Document Date"),
  columnRender.renderDocumentStatusColumn("Document Status"),
  columnRender.renderOCConversionStatusColumn("OC Conversion Status"),
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderDraftDateColumn("Draft Date"),
  columnRender.renderEnquiryDateColumn("Enquiry Date"),
  columnRender.renderOCCreatedColumn("OC Created"),
  columnRender.renderItemValueBeforeTaxColumn("Item Value - Before Tax"),
  columnRender.renderExtraChargeBeforeTaxColumn("Extra Charges - Before Tax"),
  columnRender.renderTotalBeforeTaxColumn("Total - Before Tax"),
  columnRender.renderTotalTaxColumn("Total Tax"),
  columnRender.renderTotalAfterTaxColumn("Total - After Tax"),
  columnRender.renderNonTaxableExtraCharge("Non - Taxable Extra Charge"),
  columnRender.renderTcsAmountColumn("TCS Amount"),
  columnRender.renderGrandTotalColumn("Grand Total"),
  columnRender.renderOCNumberColumn("OC Number"),
  columnRender.renderPaymentTermColumn("Payment Term"),
];

export const salesQuotationColumns: TableProps<DataType>["columns"] = [
  columnRender.renderDocumentColumn("Doc No."),
  columnRender.renderDocumentDateColumn("Document Date"),
  columnRender.renderDocumentStatusColumn("Document Status"),
  columnRender.renderOCConversionStatusColumn("OC Conversion Status"),
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderDraftDateColumn("Draft Date"),
  columnRender.renderEnquiryNumberColumn("Enquiry Number"),
  columnRender.renderEnquiryDateColumn("Enquiry Date"),
  columnRender.renderOCCreatedColumn("OC Created"),
  columnRender.renderItemValueBeforeTaxColumn("Item Value - Before Tax"),
  columnRender.renderExtraChargeBeforeTaxColumn("Extra Charges - Before Tax"),
  columnRender.renderTotalBeforeTaxColumn("Total - Before Tax"),
  columnRender.renderTotalTaxColumn("Total Tax"),
  columnRender.renderTotalAfterTaxColumn("Total - After Tax"),
  columnRender.renderNonTaxableExtraCharge("Non - Taxable Extra Charge"),
  columnRender.renderTcsAmountColumn("TCS Amount"),
  columnRender.renderGrandTotalColumn("Grand Total"),
  columnRender.renderOCNumberColumn("OC Number"),
  columnRender.renderPaymentTermColumn("Payment Term"),
];

export const orderConfirmationColumns: TableProps<DataType>["columns"] = [
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderDocumentColumn("OC Number"),
  columnRender.renderDocumentDateColumn("OC Date"),
  columnRender.renderDeliveryDateColumn("Original Creation Date"),
  columnRender.renderDocumentStatusColumn("Document Status"),
  columnRender.renderInvoicingStatusColumn("Invoicing Status"),
  columnRender.renderPONumberColumn("PO Number"),
  columnRender.renderPODateColumn("PO Date"),
  columnRender.renderDeliveryDateColumn("OC Delivery Date"),
  columnRender.renderNumberOfItemsColumn("Number of Items"),
  columnRender.renderItemValueBeforeTaxColumn("Item Value - Before Tax"),
  columnRender.renderExtraChargeBeforeTaxColumn("Extra Charges - Before Tax"),
  columnRender.renderTotalBeforeTaxColumn("Total - Before Tax"),
  columnRender.renderTotalTaxColumn("Total Tax"),
  columnRender.renderTotalAfterTaxColumn("Total - After Tax"),
  columnRender.renderNonTaxableExtraCharge("Non - Taxable Extra Charge"),
  columnRender.renderTcsAmountColumn("TCS Amount"),
  columnRender.renderGrandTotalColumn("Grand Total"),
  columnRender.renderAdvancePaidColumn("Advance Paid"),
  columnRender.renderOCBalanceColumn("OC Balance ( Grand Total - Advance"),
  columnRender.renderPaymentTermColumn("Payment Term"),
  columnRender.renderInvoiceRaisedColumn("Invoice Raised Total Value"),
  columnRender.renderBalanceInvoiceColumn("Balance Invoice to be Raised"),
  columnRender.renderSQNumberColumn("SQ Number"),
  columnRender.renderSQDateColumn("SQ Date"),
  columnRender.renderCGSTColumn("CGST"),
  columnRender.renderSGSTColumn("SGST"),
  columnRender.renderIGSTColumn("IGST"),
  columnRender.renderCessColumn("Cess"),
  columnRender.renderDrafterNameColumn("Drafter Name"),
  columnRender.renderSenderNameColumn("Sender Name"),
  columnRender.renderStoreNameColumn("Store Name"),
  columnRender.renderCommentsColumn("Comments/Remark"),
  columnRender.renderNetworkTagsColumn("Network Tags"),
  columnRender.renderTransactionTagsColumn("Transaction Tags"),
  columnRender.renderCustomerNameColumn("Customer Delivery Address Name"),
  columnRender.renderCustomerDeliveryAddressColumn("Customer Delivery Address"),
  columnRender.renderCustomerDeliveryAddressGSTINColumn(
    "Customer Delivery Address GSTIN"
  ),
  columnRender.renderCustomerDeliveryAddressStateColumn(
    "Customer Delivery Address State"
  ),
  columnRender.renderCustomerDeliveryAddressCountryColumn(
    "Customer Delivery Address Country"
  ),
  columnRender.renderCustomerDeliveryAddressPINColumn(
    "Customer Delivery Address PIN"
  ),
  columnRender.renderCustomerNameColumn("Customer Billing Address Name"),
  columnRender.renderCustomerBillingAddressColumn("Customer Billing Address"),
  columnRender.renderCustomerBillingAddressGSTINColumn(
    "Customer Billing Address GSTIN"
  ),
  columnRender.renderCustomerBillingAddressStateColumn(
    "Customer Billing Address State"
  ),
  columnRender.renderCustomerBillingAddressCountryColumn(
    "Customer Billing Address Country"
  ),
  columnRender.renderCustomerBillingAddressPINColumn(
    "Customer Billing Address PIN"
  ),
  columnRender.renderAttachmentsColumn("Attachments"),
  columnRender.renderProformaInvoiceRaisedTotalValueColumn(
    "Proforma Invoice Raised Total Value"
  ),
  columnRender.renderLastProrformaInvoiceNumberColumn(
    "Last Proforma Invoice Number"
  ),
  columnRender.renderLastProformaInvoiceDateColumn(
    "Last Proforma Invoice Date"
  ),
  columnRender.renderLastInvoiceNumberColumn("Last Invoice Number"),
  columnRender.renderLastInvoiceDateColumn("Last Invoice Date"),
  columnRender.renderTotalPaymentLoggedColumn("Total Payment Logged (Party)"),
  columnRender.renderPercentageTotalPaymentLoggedColumn(
    "Percentage Total Payment Logged (Party)"
  ),
  columnRender.renderPercentageTotalPaymentToLogColumn(
    "Percentage Payment To Log (Party)"
  ),
  columnRender.renderBalanceAmountToPayColumn("Balance Amount To Pay (Party)"),
];

export const deliveryChallanColumns: TableProps<DataType>["columns"] = [
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderInvoiceNumberColumn("Invoice Number"),
  columnRender.renderInvoiceAmountColumn("Invoice Amount"),
  columnRender.renderInvoiceDateColumn("Invoice Date"),
  columnRender.renderPONumberColumn("PO Number"),
  columnRender.renderEnquiryNumberColumn("OC Number"),
  columnRender.renderTransporterNameColumn("Transporter Name"),
  columnRender.renderDispatchDateColumn("Dispatch Date"),
  columnRender.renderTrackingNumberColumn("Tracking Number"),
  columnRender.renderDocumentStatusColumn("Delivery Status"),
  columnRender.renderDeliveryDateColumn("Delivery Date"),
  columnRender.renderDeliveryLocationColumn("DeliveryLocation"),
  columnRender.renderTransportDocumentNumberColumn("Transport Document Number"),
  columnRender.renderTransportGSTNumberColumn("Transport GST Number"),
  columnRender.renderTransportVehicleNumberColumn("Transporter Vehicle Number"),
  columnRender.renderPayToTransportColumn("Pay To Transport"),
  columnRender.renderInvoiceTypeColumn("Invoice Type"),
  columnRender.renderCommentsColumn("Comments/Remark"),
  columnRender.renderDeliveryDateColumn("OC Delivery Date"),
];

export const salesReturnColumns: TableProps<DataType>["columns"] = [
  columnRender.renderDocumentColumn("Sales Return Number"),
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderDocumentDateColumn("Document Date"),
  columnRender.renderInvoiceNumberColumn("Invoice Number"),
  columnRender.renderInvoiceDateColumn("Invoice Date"),
  columnRender.renderChallanNumberColumn("Challan Number"),
  columnRender.renderChallanDateColumn("Challan Date"),
  columnRender.renderPONumberColumn("PO Number"),
  columnRender.renderPODateColumn("PO Date"),
  columnRender.renderEnquiryNumberColumn("OC Number"),
  columnRender.renderOCDateColumn("OC Date"),
  columnRender.renderStoreNameColumn("Store Name"),
  columnRender.renderCommentsColumn("Comments/Remark"),
];

export const invoiceColumn: TableProps<DataType>["columns"] = [
  columnRender.renderDocumentColumn("Invoice Number"),
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderDocumentDateColumn("Document Date"),
  columnRender.renderDocumentStatusColumn("Document Status"),
  columnRender.renderPaymentStatusColumn("Payment Status"),
  columnRender.renderPONumberColumn("PO Number"),
  columnRender.renderPODateColumn("PO Date"),
  columnRender.renderEnquiryNumberColumn("OC Number"),
  columnRender.renderOCDateColumn("OC Date"),
  columnRender.renderDeliveryDateColumn("OC Delivery Date"),
  columnRender.renderNumberOfItemsColumn("Number of Items"),
  columnRender.renderItemValueBeforeTaxColumn("Item Value - Before Tax"),
  columnRender.renderExtraChargeBeforeTaxColumn("Extra Charges - Before Tax"),
  columnRender.renderTotalBeforeTaxColumn("Total - Before Tax"),
  columnRender.renderTotalTaxColumn("Total Tax"),
  columnRender.renderTotalAfterTaxColumn("Total - After Tax"),
  columnRender.renderNonTaxableExtraCharge("Non - Taxable Extra Charge"),
  columnRender.renderTcsAmountColumn("TCS Amount"),
  columnRender.renderGrandTotalColumn("Invoice Grand Total"),
  columnRender.renderAdvancePaidColumn("Advance Paid"),
  columnRender.renderBalanceAmountColumn("Balance Amount"),
  columnRender.renderCreditNoteNumber("Credit Note Number"),
  columnRender.renderTotalCreditNoteAmount("Total Credit Note Amount"),
  columnRender.renderDebitNoteNumber("Debit Note Number"),
  columnRender.renderTotalDebitNoteAmount("Total Debit Note Amount"),
  columnRender.renderPaymentTermColumn("Payment Term"),
  columnRender.renderPaymentDueDate("Payment Due Date"),
  columnRender.renderCustomerReferenceColumn("Customer Reference"),
  columnRender.renderLastModifiedDateColumn("Last Modified Date"),
  columnRender.renderPlaceofSupplyColumn("Place of Supply"),
  columnRender.renderDrafterNameColumn("OC Drafter Name"),
  columnRender.renderSenderNameColumn("OC Sender Name"),
  columnRender.renderChallanNumberColumn("Challan Number"),
  columnRender.renderChallanDateColumn("Challan Date"),
  columnRender.renderCGSTColumn("CGST"),
  columnRender.renderSGSTColumn("SGST"),
  columnRender.renderIGSTColumn("IGST"),
  columnRender.renderCessColumn("Cess"),
  columnRender.renderDrafterNameColumn("Drafter Name"),
  columnRender.renderSenderNameColumn("Sender Name"),
  columnRender.renderVendorCodeColumn("Vendor Code"),
  columnRender.renderStoreNameColumn("Store Name"),
  columnRender.renderCommentsColumn("Comments/Remark"),
  columnRender.renderCustomerNameColumn("Customer Delivery Address Name"),
  columnRender.renderCustomerDeliveryAddressColumn("Customer Delivery Address"),
  columnRender.renderCustomerDeliveryAddressGSTINColumn(
    "Customer Delivery Address GSTIN"
  ),
  columnRender.renderCustomerDeliveryAddressCityColumn(
    "Customer Delivery Address City"
  ),
  columnRender.renderCustomerDeliveryAddressStateColumn(
    "Customer Delivery Address State"
  ),
  columnRender.renderCustomerDeliveryAddressCountryColumn(
    "Customer Delivery Address Country"
  ),
  columnRender.renderCustomerDeliveryAddressPINColumn(
    "Customer Delivery Address PIN"
  ),
  columnRender.renderCustomerNameColumn("Customer Billing Address Name"),
  columnRender.renderCustomerBillingAddressColumn("Customer Billing Address"),
  columnRender.renderCustomerBillingAddressGSTINColumn(
    "Customer Billing Address GSTIN"
  ),
  columnRender.renderCustomerBillingAddressCityColumn(
    "Customer Billing Address City"
  ),
  columnRender.renderCustomerBillingAddressStateColumn(
    "Customer Billing Address State"
  ),
  columnRender.renderCustomerBillingAddressCountryColumn(
    "Customer Billing Address Country"
  ),
  columnRender.renderCustomerBillingAddressPINColumn(
    "Customer Billing Address PIN"
  ),
  columnRender.renderCustomerContactColumn("Customer Contact Details"),
  columnRender.renderTransporterNameColumn("Transporter Name"),
  columnRender.renderTransportDocumentNumberColumn("Transport Document Number"),
  columnRender.renderTransportGSTNumberColumn("Transport GST Number"),
  columnRender.renderTransportVehicleNumberColumn("Transporter Vehicle Number"),
  columnRender.renderPayToTransportColumn("Pay To Transport"),
  columnRender.renderCustomerNameColumn("Customer Name"),
  columnRender.renderSalesPersonColumn("Sales Person"),
  columnRender.renderTransporterNameColumn("Transporter Name"),
  columnRender.renderSalesReturnAmountColumn("Sales Return Amount"),
  columnRender.renderSalesReturnNumberColumn("Sales Return Number"),
];
