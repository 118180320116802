import { AppConstants } from "../../Appconstants";
import {
  LineChartOutlined,
  PayCircleOutlined,
  ProjectOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  deliveryChallanColumns,
  invoiceColumn,
  orderConfirmationColumns,
  salesEnquiryColumns,
  salesQuotationColumns,
  salesReturnColumns,
} from "./reportColumns";
import { ColumnsType } from "antd/es/table";
import React from "react";

interface IItems {
  numberOfItems: number;
  itemValueBeforeTax: string;
  extraChargesBeforeTax: string;
  totalBeforeTax: string;
  totalTax: string;
  totalAfterTax: string;
  nonTaxableExtraCharge: string;
  tcsAmount: string;
  ProformaGrandTotal: string;
  advancePaid: string;
  amountToPay: string;
  paymentTerm: string;
  paymentDueDate: string;
}

interface UtilityProps {
  documents: any;
}

interface DataType {
  documentNumber: string;
  documentDate: string;
  documentType: string;
  documentStatus: string;
  poNumber: string;
  poDate: string;
  ocNumber: string;
  ocDate: string;
  ocDeliveryDate: string;
  items: IItems[];
}

interface ReportType {
  title: string;
  icon: JSX.Element;
  description: string;
  value: string;
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC",
  };
  return date.toLocaleString("en-GB", options);
};

export const getFilteredDataAndColumns = (
  documentType: string,
  documents: any[]
): { columns: ColumnsType<DataType>; filteredData: DataType[] } => {
  let columns: ColumnsType<DataType> = [];
  let filteredData: DataType[] = [];

  switch (documentType) {
    case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
      columns = salesEnquiryColumns || [];
      break;
    case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
      columns = salesQuotationColumns || [];
      break;
    case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
      columns = orderConfirmationColumns || [];
      break;
    case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
      columns = deliveryChallanColumns || [];
      break;
    case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
      columns = salesReturnColumns || [];
      break;
    case AppConstants.DOCUMENT_TYPE.INVOICE:
      columns = invoiceColumn || [];
      break;
    default:
      columns = [];
  }

  if (documents?.length) {
    filteredData = documents.filter(
      (doc: DataType) =>
        doc.documentType?.toLowerCase() === documentType.toLowerCase()
    );
  }

  return { columns, filteredData };
};


export const reportsType: ReportType[] = [
  {
    title: "Inventory Report",
    icon: React.createElement(SettingOutlined),
    description: "Find all the inventory related reports here",
    value: "inventoryReport",
  },
  {
    title: "Sales Report",
    icon: React.createElement(ProjectOutlined),
    description: "Find all the sales related reports here",
    value: "salesReport",
  },
  {
    title: "Purchase Report",
    icon: React.createElement(LineChartOutlined),
    description: "Find all the purchase related reports here",
    value: "purchaseReport",
  },
  {
    title: "Payments Report",
    icon: React.createElement(PayCircleOutlined),
    description: "Find all the payment related reports here",
    value: "payementsReport",
  },
];

export const listData: Record<string, { title: string; value: string }[]> = {
  [AppConstants.REPORT_TYPE.SALES_REPORT]: [
    {
      title: "Sales Enquiry",
      value: "Sales Enquiry",
    },
    {
      title: "Sales Quotation",
      value: "Sales Quotation",
    },
    {
      title: "Order Confirmation",
      value: "Order Confirmation",
    },
    {
      title: "Invoice",
      value: "Invoice",
    },
    {
      title: "Delivery Challan",
      value: "Delivery Challan",
    },
    {
      title: "Sales Return",
      value: "Sales Return",
    },
  ],
  [AppConstants.REPORT_TYPE.PURCHASE_REPORT]: [
    { title: "Purchase Enquiry", value: "Purchase Enquiry" },
    { title: "Purchase Order", value: "Purchase Order" },
    { title: "Goods Receipt", value: "Goods Receipt" },
    { title: "Purchase Invoice", value: "Purchase Invoice" },
  ],
  [AppConstants.REPORT_TYPE.INVENTORY_REPORT]: [],
  [AppConstants.REPORT_TYPE.PAYMENT_REPORT]: [],
};