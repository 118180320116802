import * as type from "../../types/API/accountInfoType";

export function getAccountInfo(payload: any) {
  return {
    type: type.GET_ACCOUNT_INFO,
    payload: payload,
  };
}

export function updateAccountInfo(payload: any) {
  return {
    type: type.UPDATE_ACCOUNT_INFO,
    payload: payload,
  };
}
