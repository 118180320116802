export const GET_TEAMS = 'GET_TEAMS';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILED = 'GET_TEAMS_FAILED';

export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILED = 'ADD_TEAM_FAILED';

export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAILED = 'EDIT_TEAM_FAILED';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED';