import * as type from "../../types/API/commentTypes";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const addBlogCommentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ADD_COMMENT:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.ADD_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteBlogCommentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_COMMENT:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const approveCommentsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.APPROVE_COMMENT:
      return {
        ...state,
        loading: true,
      };
    case type.APPROVE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.APPROVE_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getApprovedBlogCommentsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_APPROVE_BLOG_COMMENT:
      return {
        ...state,
        loading: true,
      };
    case type.GET_APPROVE_BLOG_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_APPROVE_BLOG_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getBlogCommentstoBeApproveReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_BLOG_COMMENTS_TO_BE_APPROVED:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BLOG_COMMENTS_TO_BE_APPROVED_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_BLOG_COMMENTS_TO_BE_APPROVED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getAllBlogsApprovedCommentsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_ALL_BLOGS_APPROVED_COMMENTS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_ALL_BLOGS_APPROVED_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_ALL_BLOGS_APPROVED_COMMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
