export const GET_BANK_DETAIL = 'GET_BANK_DETAIL';
export const GET_BANK_DETAIL_SUCCESS = 'GET_BANK_DETAIL_SUCCESS';
export const GET_BANK_DETAIL_FAILED = 'GET_BANK_DETAIL_FAILED';

export const ADD_BANK_DETAIL = 'ADD_BANK_DETAIL';
export const ADD_BANK_DETAIL_SUCCESS = 'ADD_BANK_DETAIL_SUCCESS';
export const ADD_BANK_DETAIL_FAILED = 'ADD_BANK_DETAIL_FAILED';

export const EDIT_BANK_DETAIL = 'EDIT_BANK_DETAIL';
export const EDIT_BANK_DETAIL_SUCCESS = 'EDIT_BANK_DETAIL_SUCCESS';
export const EDIT_BANK_DETAIL_FAILED = 'EDIT_BANK_DETAIL_FAILED';

export const DELETE_BANK_DETAIL = 'DELETE_BANK_DETAIL';
export const DELETE_BANK_DETAIL_SUCCESS = 'DELETE_BANK_DETAIL_SUCCESS';
export const DELETE_BANK_DETAIL_FAILED = 'DELETE_BANK_DETAIL_FAILED';