export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILED = "ADD_COMMENT_FAILED";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILED = "DELETE_COMMENT_FAILED";

export const APPROVE_COMMENT = "APPROVE_COMMENT";
export const APPROVE_COMMENT_SUCCESS = "APPROVE_COMMENT_SUCCESS";
export const APPROVE_COMMENT_FAILED = "APPROVE_COMMENT_FAILED";

export const GET_APPROVE_BLOG_COMMENT = "GET_APPROVE_BLOG_COMMENT";
export const GET_APPROVE_BLOG_COMMENT_SUCCESS = "GET_APPROVE_BLOG_COMMENT_SUCCESS";
export const GET_APPROVE_BLOG_COMMENT_FAILED = "GET_APPROVE_BLOG_COMMENT_FAILED";
 
export const GET_BLOG_COMMENTS_TO_BE_APPROVED = "GET_BLOG_COMMENTS_TO_BE_APPROVED";
export const GET_BLOG_COMMENTS_TO_BE_APPROVED_SUCCESS = "GET_BLOG_COMMENTS_TO_BE_APPROVED_SUCCESS";
export const GET_BLOG_COMMENTS_TO_BE_APPROVED_FAILED = "GET_BLOG_COMMENTS_TO_BE_APPROVED_FAILED";

export const GET_ALL_BLOGS_APPROVED_COMMENTS = "GET_ALL_BLOGS_APPROVED_COMMENTS";
export const GET_ALL_BLOGS_APPROVED_COMMENTS_SUCCESS = "GET_ALL_BLOGS_APPROVED_COMMENTS_SUCCESS";
export const GET_ALL_BLOGS_APPROVED_COMMENTS_FAILED = "GET_ALL_BLOGS_APPROVED_COMMENTS_FAILED";