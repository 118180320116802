import React from "react";
import production from '../../Utility/images/productionTwoCompressed.png';
import sales from '../../Utility/images/salesOneCompressed.png';
import user from '../../Utility/images/userreportingcompressed.png';
import Footer from "./Footer"; 
import Security from '../../Utility/images/securitycompressed.png';
import warehouse from '../../Utility/images/storecompressed.png';
import IntegrationAutomation from '../../Utility/images/integrationcompressed.png';
import User from '../../Utility/images/userexperiencecompressedremoved.png';
import './TextContent.css'
//import WOW from 'wowjs'
import 'animate.css';
import 'wowjs/css/libs/animate.css';
import '../../Utility/css/animate.css'
import '../../Utility/css/dropdown-effects/fade-down.css';
import '../../Utility/js/wow'
import WOW from "../../Utility/js/wow";


interface ITextContentProps {

}

interface ITextContentState {

}

class TextContent extends React.Component<ITextContentProps, ITextContentState> {
	constructor(props: ITextContentProps) {
		super(props);
	}
	componentDidMount() {
		try {
		  const wow = new WOW();
		  wow.init();
		} catch (error) {
		  console.error('Error initializing WOW.js:', error);
		}
	  }
	  
	// componentDidMount() {
	// 	try {
	// 	  if (window.WOW) {
	// 		const wow = new window.WOW(
	// 			{
	// 				mobile: true
	// 			}
	// 		);
	// 		wow.init();
	// 	  }
	// 	} catch (error) {
	// 	  console.error('Error initializing WOW.js:', error);
	// 	}
	//   }

	
	
	render() {
		return (<>
			{/* <section id="lnk-2" className="pt-100 ws-wrapper content-section">
				<div className="container">
					<div className="bc-5-wrapper bg--04 hidd bg--scroll r-16">
						<div className="section-overlay">

							<div className="row justify-content-center">
								<div className="col-md-11 col-lg-9">
									<div className="section-title wow fadeInUp mb-60">
										<h2 className="s-50 w-700">Build a customer-centric marketing strategy</h2>
										<p className="p-xl">Aliquam a augue suscipit luctus neque purus ipsum neque diam
											dolor primis libero tempus, blandit and cursus varius and magnis sodales
										</p>

									</div>
								</div>
							</div>

							<div className="row justify-content-center">
								<div className="col">
									<div className="bc-5-img bc-5-tablet img-block-hidden video-preview wow fadeInUp">

										<a className="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8">
											<div className="video-btn video-btn-xl bg--theme">
												<div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
											</div>
										</a>

										<img className="img-fluid" src={tablet} alt="content-image" />

									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</section>

			<section id="lnk-2" className="pt-100 ws-wrapper content-section" >
				<div className="container">
					<div className="bc-5-wrapper bg--04 hidd bg--scroll r-16">
						<div className="section-overlay">

							<div className="row justify-content-center">
								<div className="12">
									<div className="section-title  mb-60" style={{height: '50px'}}>
										<h2 className="s-50 w-600 inter" style={{marginTop:'-30px'}}>CORE FEATURES</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<section className="pt-60 ct-03 content-section division container-production">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 col-lg-7">
							<div className="img-block left-column wow fadeInRight">
								<img className="img-fluid" src={production} alt="content-image" />
							</div>
						</div>

						<div className="col-md-6 col-lg-5">
							<div className="txt-block right-column wow fadeInLeft sub-container-production">
								<h2 className="s-28 w-700">Production Management</h2>
								<div>
								<div className="cbox-1 ico-15">
									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Easy and detailed way of creating Single and Multi-level Bill Of Materials(BOMs).</p>
									</div>

								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Handle multiple types of Production like Make-To-Order or Batch-Wise Production.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Manage subcontracting (outsourcing).</p>
									</div>
								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>View cost of production.</p>
									</div>

								</div>

								<div className="cbox-1 ico-15 line-height-paragh">
									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>
									<div className="cbox-1-txt">
										<p>Scrap management.</p>
									</div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-100 ct-01 content-section division container-inventory">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 order-last order-md-2">
							<div className="txt-block left-column wow fadeInRight">
								<div className="txt-box container-inventory-text">
									<h5 className="s-28 w-700">Inventory Management</h5>

									<div className="cbox-1 ico-15">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Real-time tracking of stocks across different stores.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Create custom fields and fetch in different documents and production processes.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Create barcode to track raw materials and finished goods.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Do regular stock reconciliation with ease.</p>
										</div>
									</div>

									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Stock transfer from one store to another.</p>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="col-md-6 order-first order-md-2 container-warehouse">
							<div className="img-block right-column wow fadeInLeft">
								< img src={warehouse} alt="warehouse" className="image-warehouse" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-100 ct-03 content-section division container-sales-text">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 col-lg-7">
							<div className="img-block left-column wow fadeInRight">
								<img className="img-fluid image-sales" src={sales} alt="content-image" />
							</div>
						</div>

						<div className="col-md-6 col-lg-5 sub-container-sales">
							<div className="txt-block right-column wow fadeInLeft">

								<h5 className="s-28 w-700">Sales and Purchase Management</h5>
								<div className="cbox-1 ico-15">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Create sales and purchase-related documents.</p>
									</div>

								</div>

								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Sale order management with Goods Dispatch tracking.</p>
									</div>

								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Purchase management with Goods Received Notes(GRN) and Quality reports.  </p>
									</div>

								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Document Approval management. </p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-100 ct-01 content-section division container-user-text">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 order-last order-md-2">
							<div className="txt-block left-column wow fadeInRight">
								<div className="txt-box">
									<h2 className="s-28 w-700">User Management</h2>
									<div className="cbox-1 ico-15">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Team-wise access management.</p>
										</div>

									</div>
									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>User-wise activity history.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Tools to collaborate with internal teams.</p>
										</div>

									</div>
									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Security enhancement with IP whitelisting features.</p>
										</div>

									</div>

									<h2 className="s-28 w-700 mt-30">Reporting and Analytics</h2>
									<div className="cbox-1 ico-15">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Easy and detailed reports for all documents and production.</p>
										</div>

									</div>
									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Informative data analytics.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Report generation with customized filters and store-wise.</p>
										</div>

									</div>

									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Download customized reports by applying multiple filters.</p>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 order-first order-md-2">
							<div className="img-block right-column wow fadeInLeft">
								<img className="img-fluid image-user-graph" src={user} alt="content-image"/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="lnk-1" className="pt-100 ct-01 content-section division container-additional">
				<div className="container">
					<div className="row d-flex align-items-center"><div className="col-md-6 order-first order-md-2">
						<div className="img-block right-column wow fadeInLeft">
								<img className="img-fluid" src={IntegrationAutomation} alt="content-image" />
							</div>
					</div>
						<div className="col-md-6 order-last order-md-2">
							<div className="txt-block left-column wow fadeInRight">
								<span className="section-id">Additional features</span>
								<h2 className="s-28 w-700"> Integration Capabilities.</h2>
								<div className="cbox-1 ico-15">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Integrate with Tally, BUSY, and Zoho for accounting in one click.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Custom integration with APIs.</p>
									</div>
								</div>
								<h2 className="s-28 w-700"> Automation:</h2>
								<div className="cbox-1 ico-15">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>Automate workflow for repetitive work.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15 line-height-paragh">

									<div className="ico-wrap color--theme">
										<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
									</div>

									<div className="cbox-1-txt">
										<p>BOM creation automation.</p>
									</div>

								</div>
								<div className="cbox-1 ico-15">

									{/* <div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div> */}

									{/* <div className="cbox-1-txt">
										<p className="mb-0">Volute turpis dolores and sagittis congue</p>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-100 ct-01 content-section division container-security-reliability">
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 order-last order-md-2 container-text-security">
							<div className="txt-block left-column wow fadeInRight">
								<div className="cbox-4 container-security">
									<span className="section-id">Security and Reliability</span>
									<div className="box-title">
										<span className="flaticon-paper-sizes color--theme"></span>
										<h5 className="s-24 w-700 heading-security">Data Security</h5>
									</div>

									<div className="cbox-1 ico-15">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Data encryption with secured network protocols.</p>
										</div>

									</div>
									<div className="cbox-1 ico-15 line-height-paragh">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Security audits regularly.</p>
										</div>
									</div>

									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Secure cloud servers for data storage.</p>
										</div>
									</div>

								</div>
								<div className="cbox-4 container-security">
									<div className="box-title subcontainer-security">
										<span className="flaticon-layers-1 color--theme"></span>
										<h5 className="s-24 w-700 heading-security">Reliability</h5>
									</div>

									<div className="cbox-1 ico-15">

										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Efficient Operating time and availability.</p>
										</div>
									</div>
									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Data recovery plans for handling any disaster situation.</p>
										</div>
									</div>

								</div>
								<div className="cbox-4 container-security">
									<div className="box-title subcontainer-security">
										<span className="flaticon-pie-chart color--theme"></span>
										<h5 className="s-24 w-700 heading-security">Real-time analytics</h5>
									</div>

									<div className="cbox-1 ico-15">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Track and display real-time usage statistics of key features, which can help us
												identify the bottlenecks or area for improvements.</p>
										</div>
									</div>
									<div className="cbox-1 ico-15 line-height-paragh">
										<div className="ico-wrap color--theme">
											<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
										</div>

										<div className="cbox-1-txt">
											<p>Collecting real-time feedback, such as ratings, comments or satisfactory score.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 order-first order-md-2">
							<div className="img-block right-column wow fadeInLeft">
								<img className="img-fluid image-security" src={Security} alt="content-image" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bg--white-400 py-100 ct-04 content-section division section-user">
				<div className="container container-user-experience">
					<div className="row d-flex align-items-center">
						<div className="col-md-6 order-last order-md-2">
							<span className="section-id">User Experience</span>

							<div className="txt-block left-column wow fadeInRight">
								<div className="cbox-2 process-step">
									<div className="ico-wrap">
										<div className="cbox-2-ico bg--theme color--white">1</div>
										<span className="cbox-2-line"></span>
									</div>
									<div className="cbox-2-txt">
										<h5 className="s-22 w-700">Easy User Interface</h5>
										<p>User-friendly and easy-to-navigate interface.
										</p>
										<p>Software implementation in just 15 days without any charges.</p>
									</div>

								</div>
								<div className="cbox-2 process-step">
									<div className="ico-wrap">
										<div className="cbox-2-ico bg--theme color--white">2</div>
										<span className="cbox-2-line"></span>
									</div>

									<div className="cbox-2-txt">
										<h5 className="s-22 w-700">Customization</h5>
										<p>Flexibility in customizing processes and workflow.
										</p>
										<p>Data mapping from one document to another is used to automate the process.</p>
									</div>

								</div>
								<div className="cbox-2 process-step">

									<div className="ico-wrap">
										<div className="cbox-2-ico bg--theme color--white">3</div>
									</div>
									<div className="cbox-2-txt">
										<h5 className="s-22 w-700">Support and Training</h5>
										<p className="mb-0">Monday to Friday between 10:00 am to 7:00 pm support service.
										</p>
										<p>Training programs and informative webinars with industry experts.</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 order-first order-md-2">
							<div className="img-block wow fadeInLeft">
								<img className="img-fluid image-user" src={User} alt="content-image" />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section id="banner-13" className="pt-100 banner-section">
				<div className="container">

					<div className="banner-13-wrapper bg--03 bg--scroll r-16 block-shadow">
						<div className="banner-overlay">
							<div className="row d-flex align-items-center">
								<div className="col-md-7">
									<div className="banner-13-txt color--white">

										<h2 className="s-46 w-700">Getting started with EaseMargin today!</h2>

										<p className="p-lg">Congue laoreet turpis neque auctor turpis vitae dolor a luctus
											placerat and magna ligula cursus
										</p>

										<a href="#" className="btn r-04 btn--theme hover--tra-white" data-bs-toggle="modal" data-bs-target="#modal-3">Get srarted - it's free</a>

									</div>
								</div>
								<div className="col-md-5">
									<div className="banner-13-img text-center">
										<img className="img-fluid" src={banner} alt="banner-image" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<div className="footer">
			<Footer />
			</div>
		</>);
	}
}

export default TextContent;
