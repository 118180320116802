export const ADD_STORE = 'ADD_STORE';
export const ADD_STORE_SUCCESS = 'ADD_STORE_SUCCESS';
export const ADD_STORE_FAILED = 'ADD_STORE_FAILED';

export const GET_STORE = 'GET_STORE';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILED = 'GET_STORE_FAILED';

export const GET_STORE_BY_ITEMS = 'GET_STORE_BY_ITEMS';
export const GET_STORE_BY_ITEMS_SUCCESS = 'GET_STORE_BY_ITEMS_SUCCESS';
export const GET_STORE_BY_ITEMS_FAILED = 'GET_STORE_BY_ITEMS_FAILED';

export const GET_STORE_BY_ID = 'GET_STORE_BY_ID';
export const GET_STORE_BY_ID_SUCCESS = 'GET_STORE_BY_ID_SUCCESS';
export const GET_STORE_BY_ID_FAILED = 'GET_STORE_BY_ID_FAILED';

export const EDIT_STORE = 'EDIT_STORE';
export const EDIT_STORE_SUCCESS = 'EDIT_STORE_SUCCESS';
export const EDIT_STORE_FAILED = 'EDIT_STORE_FAILED';

export const DELETE_STORE = 'DELETE_STORE';
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
export const DELETE_STORE_FAILED = 'DELETE_STORE_FAILED';