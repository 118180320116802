export const GET_DOCUMENT_SERIES = 'GET_DOCUMENT_SERIES';
export const GET_DOCUMENT_SERIES_SUCCESS = 'GET_DOCUMENT_SERIES_SUCCESS';
export const GET_DOCUMENT_SERIES_FAILED = 'GET_DOCUMENT_SERIES_FAILED';

export const ADD_DOCUMENT_SERIES = 'ADD_DOCUMENT_SERIES';
export const ADD_DOCUMENT_SERIES_SUCCESS = 'ADD_DOCUMENT_SERIES_SUCCESS';
export const ADD_DOCUMENT_SERIES_FAILED = 'ADD_DOCUMENT_SERIES_FAILED';

export const EDIT_DOCUMENT_SERIES = 'EDIT_DOCUMENT_SERIES';
export const EDIT_DOCUMENT_SERIES_SUCCESS = 'EDIT_DOCUMENT_SERIES_SUCCESS';
export const EDIT_DOCUMENT_SERIES_FAILED = 'EDIT_DOCUMENT_SERIES_FAILED';

export const DELETE_DOCUMENT_SERIES = 'DELETE_DOCUMENT_SERIES';
export const DELETE_DOCUMENT_SERIES_SUCCESS = 'DELETE_DOCUMENT_SERIES_SUCCESS';
export const DELETE_DOCUMENT_SERIES_FAILED = 'DELETE_DOCUMENT_SERIES_FAILED';

export const UPDATE_LAST_DOCUMENT_NUMBER = 'UPDATE_LAST_DOCUMENT_NUMBER';
export const UPDATE_LAST_DOCUMENT_NUMBER_SUCCESS = 'UPDATE_LAST_DOCUMENT_NUMBER_SUCCESS';
export const UPDATE_LAST_DOCUMENT_NUMBER_FAILED = 'UPDATE_LAST_DOCUMENT_NUMBER_FAILED';