import { Button, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../Appconstants";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDocument } from "../../../redux/actions/API/documents";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { notify } from "../../../redux/actions/API/notifications";
import { updateLastDocumentNumber } from "../../../redux/actions/API/documentSeriesAction";
import { setAdvancePaymentData } from "../../../redux/actions/UI/createDocument";

interface ITotals {
  totalBeforeTax: number;
  totalTax: number;
  totalAfterTax: number;
  grandTotal: number;
}

interface ITotalCalculationProps {
  createDocument: Function;
  setAdvancePaymentData: Function;
  documentCompanyAddressAndDetails: any;
  documentCompanyFormsData: any;
  documentItemData: any;
  documentLogisticData: any;
  documentAdditionalData: any;
  documentAttachmentData: any;
  documentSignatureData: any;
  documentTermsAndCondition: any;
  documentAdditionalChargesData: any;
  addressData: any;
  documentTypeData: any;
  documentSupplierDetails: any;
  documentBuyerDetails: any;
  companyId: string,
  createDocumentState: IDataResponse;
  bankDetailData: any;
  notify: Function;
  checkIsDocumentValid: Function;
  updateLastDocumentNumber: Function
}

const TotalCalculation: React.FC<ITotalCalculationProps> = ({
  documentCompanyFormsData,
  documentItemData,
  documentLogisticData,
  documentAdditionalData,
  documentTermsAndCondition,
  documentAdditionalChargesData,
  documentTypeData,
  createDocument,
  documentSupplierDetails,
  documentBuyerDetails,
  companyId,
  createDocumentState,
  bankDetailData,
  notify,
  checkIsDocumentValid,
  updateLastDocumentNumber,
  setAdvancePaymentData
}) => {
  const openNotificationWithIcon = (type: any, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const prevPropsRef = useRef<any>();
  const [totals, setTotals] = useState<ITotals>({
    totalBeforeTax: 0,
    totalTax: 0,
    totalAfterTax: 0,
    grandTotal: 0,
  });

  const [advancePayment, setAdvancePayment] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); 
  const navigate = useNavigate();

  useEffect(() => {
    if (prevPropsRef?.current?.createDocumentState?.loading && !createDocumentState?.loading) {
      if (createDocumentState?.error?.length > 0) {

      } else {
        updateLastDocumentNumber({companyId: Number(companyId), nextNumber: Number(documentCompanyFormsData.currentSeriesNumber) + 1, seriesId: Number(documentCompanyFormsData.seriesId) });
        openNotificationWithIcon("success", documentTypeData + ' created successfully');
        setLoading(false);
        navigate("/previewDocument?documentNumber=" + documentCompanyFormsData.documentNumber);
        notify({
          notification: 'New document ' + documentCompanyFormsData.documentNumber + ' created by admin',
          companyId: companyId,
          createdBy: companyId,
          createdByName: 'Admin',
          status: 1, // Default status
          ip_address: '127.0.0.1'
        });
      }
    }
    prevPropsRef.current = {createDocumentState};
  }, [createDocumentState]);

  useEffect(() => {
    let totalBeforeTax = 0;
    let totalTax = 0;
    let totalAfterTax = 0;
    let totalAdditionPrice = 0;
    
    console.log(documentItemData);
    if (documentItemData && documentItemData.length > 0) {
      documentItemData.forEach((item: any) => {
        totalBeforeTax += item.totalBeforeTax || 0;
        totalTax += item.totalTax || 0;
        totalAfterTax += item.totalAfterTax || 0;
      });
    }

    if (
      documentAdditionalChargesData &&
      documentAdditionalChargesData.length > 0
    ) {
      documentAdditionalChargesData.forEach((charge: any) => {
        const price = parseFloat(charge.price || 0) || 0;
        const tax = parseFloat(charge.tax || 0) || 0;

        const currentTotal = price + (price * tax) / 100;
        totalAdditionPrice += currentTotal;
      });
    }

    const grandTotal = totalAfterTax + totalAdditionPrice;
    setTotals({ totalBeforeTax, totalTax, totalAfterTax, grandTotal });
  }, [documentItemData, documentAdditionalChargesData]);

  const validateDocument = () => {
    const { documentNumber, documentDate, store, billDate, invoiceNumber } = documentCompanyFormsData;

    let isDocumentNumberValid = documentNumber != '';
    console.log(isDocumentNumberValid);
    let isDocumentDateValid = documentDate != '';
    let isStoreValid = store != '';
    let isBillDateValid = billDate != '';
    let isInvoiceNumberValid = invoiceNumber != '';

    let isValid = false;
    // Validate based on the document type
    switch (documentTypeData) {
      case 'Sales Enquiry':
      case 'Sales Quotation':
      case 'Order Confirmation':
      case 'Delivery Challan':
        isValid = isDocumentNumberValid && isDocumentDateValid && isStoreValid;
        break;

      case 'Invoice':
        isValid = isInvoiceNumberValid && isDocumentDateValid && isStoreValid && isBillDateValid;
        break;

      case 'Sales Return':
        isValid = isDocumentNumberValid && isDocumentDateValid && isInvoiceNumberValid && isStoreValid;
        break;

      default:
        isValid = false;
    }
    return [isValid, isDocumentNumberValid, isDocumentDateValid, isStoreValid, isBillDateValid, isInvoiceNumberValid];
  };

  const handleOnBlur = () =>{
    setAdvancePaymentData(advancePayment)
  }

  const submitDocument = (type: string) => {
    let isDocumentValid = validateDocument();
    checkIsDocumentValid(isDocumentValid);

    if (isDocumentValid[0]) {
      setLoading(true);
      let documentData = {
        documentType: documentTypeData,
        documentNumber: documentCompanyFormsData.documentNumber,
        buyerName: documentBuyerDetails.buyerName,
        buyerBillingAddress: documentBuyerDetails.buyerBillingAddress,
        buyerDeliveryAddress: documentBuyerDetails.buyerDeliveryAddress,
        buyerContactNumber: documentBuyerDetails.buyerContactNumber,
        buyerEmail: documentBuyerDetails.buyerEmail,
        supplierName: documentSupplierDetails.supplierName,
        supplierBillingAddress: documentSupplierDetails.supplierBillingAddress,
        supplierDeliverAddress: documentSupplierDetails.supplierDeliverAddress,
        supplierContactNo: documentSupplierDetails.supplierContactNo,
        supplierEmail: documentSupplierDetails.supplierEmail,
        documentDate: documentCompanyFormsData.documentDate,
        ammendment: documentCompanyFormsData.ammendment,
        deliveryDate: documentCompanyFormsData.deliveryDate,
        paymentTerm: documentCompanyFormsData.paymentTerm,
        store: documentCompanyFormsData.store,
        enquiryNumber: documentCompanyFormsData.enquiryNumber,
        enquiryDate: documentCompanyFormsData.enquiryDate,
        logisticDetails: documentLogisticData[0],
        additionalDetails: documentAdditionalData[0],
        signature: "",
        companyId: Number(companyId),
        createdBy: "user123",
        status: type == 'save' ? 1 : 0,
        ip_address: "127.0.0.1",
        paymentDate: documentCompanyFormsData.paymentDate,
        POCName: documentCompanyFormsData.POCName,
        POCNumber: documentCompanyFormsData.POCNumber,
        POCDate: documentCompanyFormsData.POCDate,
        OCNumber: documentCompanyFormsData.OCNumber,
        OCDate: documentCompanyFormsData.OCDate,
        transporterName: documentCompanyFormsData.transporterName,
        TGNumber: documentCompanyFormsData.TGNumber,
        TDNumber: documentCompanyFormsData.TDNumber,
        TDDate: documentCompanyFormsData.TDDate,
        VehicleNumber: documentCompanyFormsData.VehicleNumber,
        replyDate: documentCompanyFormsData.replyDate,
        Attention: documentCompanyFormsData.kindAttention,
        invoiceNumber: documentCompanyFormsData.invoiceNumber,
        invoiceDate: documentCompanyFormsData.invoiceDate,
        billDate: documentCompanyFormsData.billDate,
        returnRecieveDate: documentCompanyFormsData.returnRecieveDate,
        creditNoteNumber: documentCompanyFormsData.creditNoteNumber,
        creditNotedate: documentCompanyFormsData.creditNotedate,

        items: documentItemData.map((item: any) => ({
          itemId: item.itemId,
          itemName: item.itemName,
          HSN: item.HSN,
          UOM: item.UOM,
          quantity: item.quantity,
          price: item.price,
          discountOne: item.discount1,
          discountTwo: item.discount2,
          totalDiscount: item.totalDiscount,
          taxType: item.taxType,
          tax: item.tax,
          totalTax: item.totalTax,
          totalBeforeTax: item.totalBeforeTax,
          totalAfterTax: item.totalAfterTax,
        })),

        additionalCharges: documentAdditionalChargesData?.map((charge: any) => ({
          chargingFor: charge.chargingFor,
          price: charge.price,
          tax: charge.tax + "%",
          total: charge.total,
          status: 1,
          ip_address: "127.0.0.1",
        })),

        bankDetails: {
          bankName: bankDetailData.bankName,
          accountName: bankDetailData.accountHolderName,
          accountNumber: bankDetailData.accountNumber,
          branch: bankDetailData.branch,
          IFSCCode: bankDetailData.IFSCCode,
          MICRCode: bankDetailData.MICRCode,
          address: bankDetailData.address,
          SWIFTCode: bankDetailData.swiftCode,
          status: bankDetailData.status,
          ip_address: "127.0.0.1",
        },
        termsCondition: documentTermsAndCondition[0],
      };
      createDocument(documentData);
    }
  };

  return (
    <div className="totalCalculation flex-grow-1">
      <Loader loading={loading}></Loader>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "40px", fontSize: '18px'}} className="col-gap-20">
        <div>
          <div className="title">Total Before Tax</div>
          <div className="title">Total Tax (CGST and SGST)</div>
          <div className="title">Total After Tax</div>
          <div className="title">Additional Charges</div>
          <div className="title">Grand Total</div>
          <div className="mt-20">Advance Payment</div>
        </div>
        <div className="values">
          <div>₹ {Number(totals.totalBeforeTax).toFixed(2)}</div>
          <div>₹ {Number(totals.totalTax).toFixed(2)}</div>
          <div>₹ {Number(totals.totalAfterTax).toFixed(2)}</div>
          <div>₹ {(totals.grandTotal - (totals.totalAfterTax ?? 0)).toFixed(2)}</div>
          <div>₹ {Number(totals.grandTotal).toFixed(2)}</div>
          <div className="mt-20">
            <Input
              type="text"
              placeholder="Advance Payment"
              value={advancePayment}
              onChange={(e) => setAdvancePayment(e.target.value)}
              onBlur={handleOnBlur}
            />
          </div>
        </div>
      </div>
      <div className="button-group mt-20">
        <div className="flexBox">
          <Button type="primary" onClick={() => {submitDocument('saveAsDraft')}}>
            {AppConstants.SAVE_BUTTON.SAVE_DRAFT_BUTTON}
          </Button>
          <Button
            type="primary"
            onClick={() => {submitDocument('save')}}          >
            {AppConstants.SAVE_BUTTON.SAVE_BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  addressData: state.api.getAddress?.data?.reverse(),
  documentTypeData: state.ui.createDocumentUIData?.documentType,
  documentCompanyAddressAndDetails: state.ui.createDocumentUIData?.documentCompanyData,
  documentCompanyFormsData: state.ui.createDocumentUIData?.formData,
  documentItemData: state.ui.createDocumentUIData?.itemData,
  documentLogisticData: state.ui.createDocumentUIData?.logisticDetails,
  documentAdditionalData: state.ui.createDocumentUIData?.additionalDetails,
  documentAttachmentData: state.ui.createDocumentUIData?.attachments,
  documentSignatureData: state.ui.createDocumentUIData?.signature,
  documentTermsAndCondition: state.ui.createDocumentUIData?.termsAndCondition,
  documentAdditionalChargesData: state.ui.createDocumentUIData?.additionalCharges,
  documentBuyerDetails: state.ui.createDocumentUIData?.buyerDetails,
  bankDetailData: state.ui.createDocumentUIData.bankDetails,
  documentSupplierDetails: state.ui.createDocumentUIData.supplierDetails,
  createDocumentState: state.api.createDocument
});

const mapDispatchToProps = (dispatch: any) => ({
  createDocument: (payload: any) => dispatch(createDocument(payload)),
  updateLastDocumentNumber: (payload: any) => dispatch(updateLastDocumentNumber(payload)),
  setAdvancePaymentData: (payload: any) => dispatch(setAdvancePaymentData(payload)),
  notify: (payload: any) => dispatch(notify(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalCalculation);
