export const tableColumns = [
  {
    title: "Transfer number",
    dataIndex: "transferNumber",
    key: "transferNumber",
    width: "200px",
  },
  {
    title: "Item ID",
    dataIndex: "itemId",
    key: "itemId",
    width: "100px",
  },
  {
    title: "Item Name",
    dataIndex: "itemName",
    key: "itemName",
    width: "200px",
  },
  {
    title: "From Store",
    dataIndex: "fromStore",
    key: "fromStore",
    width: "200px",
  },
  {
    title: "To Store",
    dataIndex: "toStore",
    key: "toStore",
    width: "200px",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "100px",
  },
  {
    title: "Transferred By",
    dataIndex: "transferredBy",
    key: "transferredBy",
    width: "150px",
  },
];
