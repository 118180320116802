import * as type from '../../types/API/documentsTypes'

export function getDocuments(payload: any) {
    return {
        type: type.GET_DOCUMENTS,
        payload: payload
    }
}

export function createDocument(payload: any) {
    return {
      type: type.CREATE_DOCUMENT,
      payload: payload,
    };
  }

export function getDocumentById(payload: any) {
    return {
        type: type.GET_DOCUMENTS_BY_ID,
        payload: payload
    }
}