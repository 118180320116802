import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Space, Timeline, Table, Input } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { itemStockTransferHistory } from "../../../redux/actions/API/itemStockTransferHistoryAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { tableColumns } from "./historyConstant";
import { TableRowSelection } from "antd/es/table/interface";
import { downloadExcel } from "../../../Utility/downloadExcel";
interface IHistoryProps {
  title: string;
  onClose: () => void;
  open: boolean;
  selectedItem: any;
  itemStockTransferHistoryData: any;
  itemStockTransferHistory: Function;
  itemStockTransferHistoryDataState: IDataResponse;
}

const HistoryData: React.FC<IHistoryProps> = ({
  title,
  onClose, 
  open,
  itemStockTransferHistoryData,
  selectedItem,
  itemStockTransferHistory,
  itemStockTransferHistoryDataState,
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const prevPropsRef = useRef<any>();

  useEffect(() => {
    setLoading(true);
    itemStockTransferHistory({ itemId: Number(selectedItem) });
    setTableData([]);
  }, [selectedItem]);

  useEffect(() => {
    if (
      itemStockTransferHistoryData &&
      Array.isArray(itemStockTransferHistoryData)
    ) {
      setTableData(itemStockTransferHistoryData);
    } else {
      setTableData([]);
    }
  }, [itemStockTransferHistoryData]);

  useEffect(() => {
    if (
      prevPropsRef?.current?.itemStockTransferHistoryDataState?.loading &&
      !itemStockTransferHistoryDataState?.loading
    ) {
      if (itemStockTransferHistoryDataState?.error) {
        setTableData([]);
      }
      setLoading(false);
    }
    prevPropsRef.current = { itemStockTransferHistoryDataState };
  }, [itemStockTransferHistoryDataState]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = { onChange: onSelectChange };

  const handleSearch = (searchString: string) => {
    setSearchText(searchString);
  };

  const highlightText = (text: string, searchText: string) => {
    if (!searchText) return text;
    const textToSearch = text.toString();
    const regex = new RegExp(`(${searchText})`, "gi");
    const parts = textToSearch.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const updatedColumns = tableColumns.map((col) => ({
    ...col,
    render: (text: any) => { 
      return <>{highlightText(text.toString(), searchText)}</>;
    },
  }));
  
  const timelineItems = tableData.map((data) => ({
    children: (
      <>
        <div>
          {highlightText(`Transferred from ${data.fromStore} to ${data.toStore} on ${new Date(data.createdAt).toLocaleString()} by ${data.transferredBy}`, searchText)}
        </div>
        <Table
          className="mt-10"
          bordered
          columns={updatedColumns}
          dataSource={[data]}
          pagination={false}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </>
    ),
  }));

  const handleDownload = () => {
    const selectedData = itemStockTransferHistoryData
      .filter((item: any) => selectedRowKeys.includes(item.id))
      .map((item: any) => ({
        "Transfer number": item.transferNumber,
        "Item ID": item.itemId,
        "Item Name": item.itemName,
        "From Store": item.fromStore,
        "To Store": item.toStore,
        Quantity: item.quantity,
        "Transferred By": item.transferredBy,
      }));
    downloadExcel(selectedData, "Selected_Items");
  };

  return (
    <>
      <Loader loading={loading} />
      <Drawer
        title={title}
        width={1200}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={handleDownload}>Download</Button>
            <Input
              placeholder="Search Timeline"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              onClick={() => {
                onClose();
                setSearchText("");
              }}
            >
              Cancel
            </Button>
          </Space>
        }
      >
        {tableData.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <SmileOutlined style={{ fontSize: "24px", color: "#999" }} />
            <p>No transfers history available to display</p>
          </div>
        ) : (
          <Timeline items={timelineItems} />
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  itemStockTransferHistoryData: state.api.itemStockTransferHistory?.data?.stockTransfers,
  itemStockTransferHistoryDataState: state.api.itemStockTransferHistory,
});

const mapDispatchToProps = (dispatch: any) => ({
  itemStockTransferHistory: (payload: any) => dispatch(itemStockTransferHistory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryData);
