import * as type from '../../types/API/teamTypes'

export function getTeams(payload: any) {
    return {
        type: type.GET_TEAMS,
        payload: payload
    }
}

export function addTeams(payload: any) {
    return {
        type: type.ADD_TEAM,
        payload: payload
    }
}

export function editTeam(payload: any) {
    return {
        type: type.EDIT_TEAM,
        payload: payload
    }
}

export function deleteTeam(payload: any) {
    return {
        type: type.DELETE_TEAM,
        payload: payload
    }
}