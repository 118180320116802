import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Layout,
  List,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import {
  addUser,
  deleteUser,
  editUser,
  getUsers,
} from "../../redux/actions/API/users";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import {
  addTeams,
  deleteTeam,
  editTeam,
  getTeams,
} from "../../redux/actions/API/team";
import "./Usermanagement.css";
import Loader from "../../Home/Loader/Loader";
import {
  EditOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { downloadExcel } from "../../Utility/downloadExcel";
import { TableRowSelection } from "antd/es/table/interface";
import BulkEdit from "./bulkEdit/BulkEdit";

type NotificationType = "success" | "info" | "warning" | "error";

interface IUsermanagementState {
  addUserOpen: boolean;
  onEdit: (item: any) => void;
  editUserOpen: boolean;
  currentEditItem: DataType | null;
  submittedData: any[];
  usersData: any;
  name: string;
  email: String;
  contactNo: string;
  role: String;
  loading: boolean;
  originalUsersData: any[];
  isUsernameDisabled: boolean;
  userId: number;
  username: string;
  companyName: string;
  ip_address: string;
  status: 1;
  isEditing: boolean;
  id: number;
  isManageTeamsDrawerOpen: boolean;
  editItem: any | null;
  submittedTeamData: any[];
  team: string;
  teamDesc: string;
  isEditTeam: boolean;
  forTitle: boolean;
  filteredData: any;
  showAddTeamForm: boolean;
  isTeamsEdit: boolean;
  searchData: string;
  currentPage: number;
  pageSize: number;
  selectedRowKeys: any[];
  showBulkActions: boolean;
  forBulkEditOpen: boolean;
}
interface IUsermanagementProps {
  userId: number;
  getUsersState: IDataResponse;
  getUsers: Function;
  addUser: Function;
  addUserState: IDataResponse;
  usersData: any;
  companyId: number;
  contactNo: string;
  getTeams: Function;
  getTeamsState: IDataResponse;
  editUser: Function;
  editUserState: IDataResponse;
  deleteUser: Function;
  deleteUserState: IDataResponse;
  loginData: any;
  teamsData: any;
  addTeams: Function;
  editTeam: Function;
  deleteTeam: Function;
  addTeamsState: IDataResponse;
  editTeamstate: IDataResponse;
  deleteTeamState: IDataResponse;
}

interface DataType {
  key: string;
  name: string;
  email: string;
  contactNo: number;
  role: string;
  usersData: any;
  userId: number;
  username: string;
  companyName: string;
}

class Usermanagement extends React.Component<
  IUsermanagementProps,
  IUsermanagementState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IUsermanagementProps) {
    super(props);
    this.state = {
      addUserOpen: false,
      onEdit: (value: DataType) => {},
      editUserOpen: false,
      currentEditItem: null,
      name: "",
      email: "",
      contactNo: "",
      role: "",
      submittedData: [],
      usersData: this.props.usersData || [],
      loading: true,
      originalUsersData: [],
      isUsernameDisabled: false,
      userId: 0,
      id: 0,
      username: "",
      companyName: "",
      ip_address: "",
      status: 1,
      isEditing: false,
      isManageTeamsDrawerOpen: false,
      editItem: null,
      submittedTeamData: [],
      team: "",
      teamDesc: "",
      isEditTeam: false,
      forTitle: true,
      searchData: "",
      filteredData: this.props.teamsData,
      showAddTeamForm: false,
      isTeamsEdit: false,
      currentPage: 1,
      pageSize: 10,
      selectedRowKeys: [],
      showBulkActions: false,
      forBulkEditOpen: false,
    };
  }

  getTeamNameById = (teamId: string) => {
    let team = this.props.getTeamsState?.data?.find(
      (team: any) => team.id === teamId
    );
    return (
      <>
        <Tag color={team ? "green" : "red"} key={team?.id || "not-allocated"}>
          {team?.name ? team.name.toUpperCase() : "NOT ALLOCATED"}
        </Tag>
      </>
    );
  };

  onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    this.setState({
      selectedRowKeys: newSelectedRowKeys,
    });
  };

  rowSelection: TableRowSelection<any> = {
    onChange: this.onSelectChange,
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        sorter: (a: any, b: any) => a.username.localeCompare(b.username),
      },
      {
        title: "Contact",
        dataIndex: "contactNo",
        key: "contactNo",
        width: "150px",
        sorter: (a: any, b: any) => a.contactNo.localeCompare(b.contactNo),
      },
      {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
        sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
      },
      {
        title: "Team",
        key: "role",
        dataIndex: "role",
        render: (teamId: string) => this.getTeamNameById(teamId),
        sorter: (a: any, b: any) =>
          String(a.role).localeCompare(String(b.role)),
      },
      {
        title: "Actions",
        key: "action",
        width: "80px",
        render: (_: any, record: any) => (
          <div className="div-actions">
            <Tooltip title="Edit Details">
              <span
                className="actionIcons"
                onClick={() => this.handleEditUser(record)}
              >
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => this.handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <span className="actionIcons">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  checkAuth = () => {
    return this.props.loginData?.data?.token ? true : false;
  };

  componentDidMount(): void {
    const { getUsers } = this.props;
    getUsers({
      companyId: Number(this.props.companyId),
    });
    this.props.getTeams({
      companyId: Number(this.props.companyId),
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IUsermanagementProps>,
    prevState: Readonly<IUsermanagementState>,
    snapshot?: any
  ): void {
    const {
      addUserState,
      editUserState,
      deleteUserState,
      getUsers,
      companyId,
    } = this.props;

    if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
      this.setState({ showBulkActions: this.state.selectedRowKeys.length > 0 });
    }
    // Handle getUsersState changes
    if (prevProps.getUsersState.loading && !this.props.getUsersState.loading) {
      if (this.props.getUsersState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to fetch user");
      } else {
        this.setState({
          loading: false,
          usersData: this.props.getUsersState.data || [],
          originalUsersData: this.props.getUsersState.data || [],
        });
      }
    }

    // Handle addUserState changes
    if (prevProps.addUserState?.loading && !addUserState?.loading) {
      if (addUserState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.addUserState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "user added successfully");
        this.setState({
          addUserOpen: false,
        });
        this.formRef.current?.resetFields();
      }
    }

    if (prevProps.editUserState?.loading && !editUserState?.loading) {
      if (editUserState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.editUserState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        // Success case: update the users list, close the modal, and reset the form
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "User updated successfully");
        this.setState({
          addUserOpen: false,
          isEditing: false, // Reset the editing state after the update
        });
        this.formRef.current?.resetFields();
      }
    }

    if (prevProps.deleteUserState?.loading && !deleteUserState?.loading) {
      if (deleteUserState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete user");
      } else {
        getUsers({
          companyId: Number(companyId),
        });
        this.openNotificationWithIcon("success", "User deleted successfully");
        this.setState({
          addUserOpen: false,
        });
        this.formRef.current?.resetFields();
      }
    }

    // teams data update
    if (
      prevProps.addTeamsState?.loading &&
      !this.props.addTeamsState?.loading
    ) {
      if (this.props.addTeamsState?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.addTeamsState?.error?.message
        );
        this.setState({
          loading: false,
        });
      } else {
        this.formRef.current?.resetFields();
        this.setState({
          showAddTeamForm: false,
          loading: false,
        });
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team added successfully");
      }
    }

    if (
      prevProps.editTeamstate?.loading &&
      !this.props.editTeamstate?.loading
    ) {
      if (this.props.editTeamstate?.error) {
        this.openNotificationWithIcon(
          "error",
          this.props.editTeamstate?.error?.message
        );
        this.setState({
          loading: false,
          isEditTeam: true,
        });
      } else {
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Team updated successfully");
        this.setState({
          showAddTeamForm: false,
          isEditTeam: false,
          editItem: null,
          loading: false,
        });
      }
    }

    if (
      prevProps.deleteTeamState?.loading &&
      !this.props.deleteTeamState?.loading
    ) {
      if (this.props.deleteTeamState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete team");
      } else {
        this.props.getTeams({
          companyId: Number(this.props.companyId),
        });
        this.setState({
          loading: false,
        });
        this.openNotificationWithIcon("success", "Team deleted successfully");
      }
    }
    if (prevProps.teamsData !== this.props.teamsData) {
      this.setState({
        filteredData: this.filterData(this.props.teamsData),
      });
    }
  }

  filterData = (data: any[]) => {
    return data.filter(
      (item: { name: string; description: string }) =>
        item.name.toLowerCase().includes(this.state.searchData) ||
        item.name.toLowerCase().includes(this.state.searchData)
    );
  };

  getUniqueUsers(users: any[]) {
    const userMap = new Map();
    users.forEach((user) => {
      if (!userMap.has(user.name)) {
        userMap.set(user.name, user);
      }
    });
    return Array.from(userMap.values());
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (searchString: string) => {
    const { originalUsersData } = this.state;

    if (!Array.isArray(originalUsersData)) {
      console.error("Original users data is not available or is not an array.");
      return;
    }

    if (!searchString) {
      this.setState({ usersData: originalUsersData });
      return;
    }

    const filteredData = originalUsersData.filter((user: any) => {
      const searchTerm = searchString.toLowerCase();

      const nameMatch =
        typeof user.name === "string" &&
        user.name.toLowerCase().includes(searchTerm);
      const usernameMatch =
        typeof user.username === "string" &&
        user.username.toLowerCase().includes(searchTerm);
      const emailMatch =
        typeof user.email === "string" &&
        user.email.toLowerCase().includes(searchTerm);
      const roleMatch =
        typeof user.role === "string" &&
        user.role.toLowerCase().includes(searchTerm);

      return nameMatch || usernameMatch || emailMatch || roleMatch;
    });

    this.setState({ usersData: filteredData });
  };

  onAddUser = () => {
    this.setState(
      {
        addUserOpen: true,
        editUserOpen: false,
        currentEditItem: null,
        isEditing: false,
      },
      () => {
        if (this.formRef.current) {
          this.formRef.current.resetFields();
        }
      }
    );
  };

  onFinish = (value: any) => {
    console.log(value);
  };

  handleSaveUser = () => {
    this.setState({
      loading: true,
    });

    this.formRef.current
      ?.validateFields()
      .then(() => {
        const { name, username, contactNo, role, email, userId, isEditing } =
          this.state;
        const userPayload = {
          name: name || "",
          username: username || "",
          contactNo: contactNo || "",
          role: role || "",
          email: email || "",
          companyId: this.props.companyId,
          companyName: this.props.loginData?.data?.companyName,
          ip_address: "127.0.0.1",
          status: 1,
          userId: userId || "",
        };

        if (isEditing) {
          this.props.editUser(userPayload);
        } else {
          this.props.addUser(userPayload);
        }

        // Reset loading state after operation
        this.setState({
          loading: true,
        });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  clearForm = () => {
    this.setState({
      name: "",
      companyName: "",
      username: "",
      role: "",
      contactNo: "",
    });
  };

  handleEditUser = (user: any) => {
    this.setState(
      {
        isEditing: true,
        addUserOpen: true,
        name: user?.name,
        email: user?.email,
        username: user?.username,
        contactNo: user?.contactNo,
        role: user?.role,
        userId: user.id,
      },
      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            name: user?.name,
            email: user?.email,
            role: user?.role,
            contactNo: user?.contactNo,
            userId: this.props.userId,
            username: user.username,
          });
        }
      }
    );
  };

  handleDelete = (userId: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteUser({ userId: userId });
  };

  handleRoleChange = (value: any) => {
    this.setState({ role: value });
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({ role: value });
    }
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.isEditTeam && this.state.editItem) {
          // Edit existing team
          this.props.editTeam({
            companyId: Number(this.props.companyId),
            teamId: this.state.editItem.id,
            name: values.teamname,
            description: values.teamdescription,
            ip_address: "127.0.0.1",
            status: 1,
          });
        } else {
          // Add new team
          this.props.addTeams({
            companyId: Number(this.props.companyId),
            name: values.teamname,
            description: values.teamdescription,
            ip_address: "127.0.0.1",
            status: 1,
          });

          // Add the new team to the array
          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              {
                id: Date.now(), // Temporary unique ID
                name: values.teamname,
                description: values.teamdescription,
              },
            ],
          }));
        }
        this.setState({
          isEditTeam: false,
        });
      })
      .catch((errorInfo) => {
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (values: any) => {
    // Update state for editing
    this.setState(
      {
        showAddTeamForm: true,
        isEditTeam: true,
        editItem: values,
        forTitle: false,
      },
      () => {
        // form values are set after state update
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            teamname: values.name,
            teamdescription: values.description,
          });
        }
      }
    );
  };

  handleTeamDelete = (item: any) => {
    this.setState({
      loading: true,
    });
    this.props.deleteTeam({
      teamId: item.id,
    });
  };

  onTeamsSearch = (value: string) => {
    const query = value.toLowerCase();
    this.setState({
      searchData: query,
      filteredData: this.props.teamsData.filter((item: any) => {
        return (
          item.name.toLowerCase().includes(query) ||
          item.description.toLowerCase().includes(query)
        );
      }),
    });
  };

  onPageChange = (page: number, pageSize: number) => {
    this.setState({ currentPage: page });
    this.setState({ pageSize: pageSize });
  };

  handleBulkDelete = () => {
    // Implement bulk delete logic here
  };

  handleBulkEdit = () => {
    // Implement bulk edit logic here
    this.setState({
      forBulkEditOpen: true,
    });
  };

  downloadSelectedRow = () => {
    const selectedData = this.state.usersData
      .filter((user: any) => this.state.selectedRowKeys.includes(user.id))
      .map((user: any) => ({
        Name: user.name,
        Email: user.email,
        Username: user.username,
        Contact: user.contactNo,
        "Company Name": user.companyName,
        Team: user?.team || "N/A",
      }));

    console.log(selectedData, "selectedData");
    downloadExcel(selectedData, "Selected_Users");
  };

  render() {
    const { addUserOpen, usersData, loading, currentPage, pageSize } =
      this.state;
    const teamOptions =
      this.props.getTeamsState?.data?.map((team: any) => ({
        value: team.id,
        label: team.name,
      })) || [];
    const startIndex = (currentPage - 1) * pageSize;
    const currentData = usersData.slice(startIndex, startIndex + pageSize);

    return (
      <>
        <Loader loading={loading}></Loader>
        <Layout className="layout-main layout">
          <div className="flexBox mb-flexFlow">
            <Breadcrumb className="breadcrumbs">
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Setting</Breadcrumb.Item>
              <Breadcrumb.Item>
                <div>
                  User <span>Management</span>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className="mt-10"></div>
          </div>
          <Content className="content-section">
            <div className="mb-10">
              <div className="flexBox">
                <div>
                  <Button type="link" onClick={this.onAddUser}>
                    <PlusOutlined /> Add User
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      this.setState({
                        isManageTeamsDrawerOpen: true,
                      });
                    }}
                  >
                    <SettingOutlined /> Manage Teams
                  </Button>
                  <Button type="link" onClick={this.downloadSelectedRow}>
                    <DownloadOutlined /> Download
                  </Button>
                </div>
                <div className="flexBox">
                  {this.state.showBulkActions &&
                  this.state.selectedRowKeys.length > 1 ? (
                    <div className="flexBox">
                      <Button type="link" onClick={this.handleBulkEdit}>
                        <EditOutlined /> Bulk Edit
                      </Button>
                      <Button type="link" danger>
                        <DeleteOutlined /> Bulk Delete
                      </Button>
                    </div>
                  ) : (
                    []
                  )}
                  <Input
                    placeholder="Search users"
                    onChange={(e) => this.onSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Table
              bordered
              columns={this.getColumns()}
              dataSource={currentData}
              rowKey="id"
              scroll={{ x: 768 }}
              pagination={false}
              sticky
              rowSelection={this.rowSelection}
            />
            <Pagination
              total={usersData?.length}
              pageSize={pageSize}
              onChange={this.onPageChange}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </Content>
        </Layout>
        <Drawer
          title={this.state.isEditing ? "Edit user" : "Add a user"}
          width={720}
          onClose={() => {
            this.setState({
              addUserOpen: false,
              editUserOpen: false,
            });
          }}
          open={addUserOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addUserOpen: false,
                    editUserOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.handleSaveUser} type="primary">
                {this.state.isEditing ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form layout="vertical" requiredMark ref={this.formRef}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter name" },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Name can only contain alphabets and spaces",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter name"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please enter email" },
                    {
                      pattern: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter email"
                    value={this.state.email as string}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    disabled={this.state.isEditing}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    { required: true, message: "Please enter user name" },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message:
                        "User name can only contain alphabets and spaces",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter user name"
                    value={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    disabled={this.state.isEditing}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contactNo"
                  label="Contact Number"
                  rules={[
                    { required: true, message: "Please enter contact number" },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: "Contact number should be of 10 digits.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter contact number"
                    value={this.state.contactNo}
                    onChange={(e) =>
                      this.setState({ contactNo: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="role"
                  label="Team"
                  rules={[{ required: true, message: "Please select a team" }]}
                >
                  <Select
                    placeholder="Team"
                    onChange={this.handleRoleChange}
                    onSearch={() => {}}
                    showSearch
                    value={this.state.role}
                    // defaultValue={this.state.role}
                  >
                    {teamOptions.map((team: any) => (
                      <Select.Option
                        key={team.value}
                        value={team.value}
                        onChange={(value: any) =>
                          this.setState({ role: value })
                        }
                      >
                        {team.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
        <Drawer
          title={"Manage Teams"}
          closeIcon={false}
          placement="right"
          size={"large"}
          open={this.state.isManageTeamsDrawerOpen}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    showAddTeamForm: false,
                    isManageTeamsDrawerOpen: false,
                    isEditTeam: false,
                  });
                }}
              >
                Cancel
              </Button>
              {this.state.showAddTeamForm && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {this.state.isEditTeam ? "Update" : "Submit"}
                </Button>
              )}
            </Space>
          }
        >
          {this.state.showAddTeamForm && (
            <>
              <p
                className="backLinkSM"
                onClick={() => {
                  this.setState({
                    showAddTeamForm: false,
                  });
                }}
              >
                <ArrowLeftOutlined /> Back to Manage Teams
              </p>
            </>
          )}
          <Alert
            message="Manage your existing teams here. You can add new teams, edit the details of existing ones, or delete teams that are no longer needed. Select a team to update its information or remove it, or click 'Add Team' to create a new one"
            type="info"
            showIcon
          />
          {this.state.showAddTeamForm && (
            <Form
              layout="vertical"
              onFinish={() => {}}
              ref={this.formRef}
              className="form-team mt-20"
            >
              <Row gutter={0}>
                <Col span={24}>
                  <Form.Item
                    name="teamname"
                    label="Team Name"
                    rules={[
                      { required: true, message: "Please enter team name" },
                      {
                        pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/, // Must contain at least one letter, but allows alphanumeric and spaces
                        message:
                          "Team name should contain at least one letter and no special characters",
                      },
                    ]}
                    className="form-item-name"
                  >
                    <Input
                      placeholder="Please enter team name"
                      className="input-teamname"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={0}>
                <Col span={24}>
                  <Form.Item
                    name="teamdescription"
                    label="Team Description"
                    rules={[
                      {
                        required: false,
                        message: "please enter team description",
                      },
                    ]}
                    className="form-item-desc"
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter team description"
                      className="input-teamDesc"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
          {!this.state.showAddTeamForm && (
            <>
              <div className="flexBox mt-20">
                <Input
                  placeholder="Search teams"
                  onChange={(e: any) => {
                    this.onTeamsSearch(e?.target?.value);
                  }}
                  className=""
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      showAddTeamForm: true,
                      isEditTeam: false,
                    });
                  }}
                >
                  Add New Team
                </Button>
              </div>
              <List
                className="mt-20"
                itemLayout="horizontal"
                dataSource={this.state.filteredData}
                style={{
                  height: "auto",
                  borderRadius: "10px",
                  overflow: "auto",
                  padding: "0 16px",
                  border: "1px solid rgba(140, 140, 140, 0.35)",
                }}
                renderItem={(item: any, index: number) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<a href="https://ant.design">{item.name}</a>}
                      description={item.description}
                    />
                    <div className="action-button">
                      <Tooltip title="Edit Details">
                        <div onClick={() => this.handleEdit(item)}>
                          <span className="actionIcons">
                            <EditOutlined />
                          </span>
                        </div>
                      </Tooltip>
                      <Popconfirm
                        title="This team might contain users. Are you sure to delete this?"
                        onConfirm={() => this.handleTeamDelete(item)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div>
                          <span className="actionIcons">
                            <DeleteOutlined />
                          </span>
                        </div>
                      </Popconfirm>
                    </div>
                  </List.Item>
                )}
              />
            </>
          )}
        </Drawer>
        <BulkEdit
          visible={this.state.forBulkEditOpen}
          onClose={() => this.setState({ forBulkEditOpen: false })}
          selectedItems={this.state.selectedRowKeys}
          title={"Users Bulk Edit"}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  usersData: state.api.getUsers?.data?.reverse(),
  userId: state.api.login?.data?.id,
  companyId: state.api.login?.data?.companyId,
  getUsersState: state.api?.getUsers,
  addUserState: state.api?.addUser,
  getTeamsState: state.api?.getTeams,
  contactNo: state.api.contactNo,
  editUserState: state.api.editUser,
  deleteUserState: state.api.deleteUser,
  loginData: state.api.login,
  teamsData: state.api.getTeams?.data?.reverse(),
  addTeamsState: state.api.addTeam,
  editTeamstate: state.api.editTeam,
  deleteTeamState: state.api.deleteTeam,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (payload: any) => dispatch(getUsers(payload)),
  addUser: (payload: any) => dispatch(addUser(payload)),
  getTeams: (payload: any) => dispatch(getTeams(payload)),
  editUser: (payload: any) => dispatch(editUser(payload)),
  deleteUser: (payload: any) => dispatch(deleteUser(payload)),
  addTeams: (payload: any) => dispatch(addTeams(payload)),
  editTeam: (payload: any) => dispatch(editTeam(payload)),
  deleteTeam: (payload: any) => dispatch(deleteTeam(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usermanagement);
