import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import RootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/index";
import { createLogger } from 'redux-logger';

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const isProduction = process.env.REACT_APP_IS_PRODUCTION;
const loggerMiddleware = createLogger();

const persistConfig = {
    key: "easemargin",
    storage
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

const composer = isProduction === "true" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

let middleWares = composer(applyMiddleware(loggerMiddleware, sagaMiddleware));

// const composer = compose


if (`${process.env.REACT_APP_PRODUCTION}` === 'true') {
    middleWares = composer(applyMiddleware(sagaMiddleware));
}


const store = createStore(persistedReducer, middleWares);
const Persistor = persistStore(store);

export{Persistor};

sagaMiddleware.run(rootSaga);

export default store;
