import * as type from "../../types/API/notificationTypes";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const notifyReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.NOTIFY:
      return {
        ...state,
        loading: true,
      };
    case type.NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.NOTIFY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getNotificationsReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_NOTIFICATIONS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
