export const SET_COMPANY_DATA = "SET_COMPANY_DATA";
export const SET_SUPPLIER_DATA = "SET_SUPPLIER_DATA";
export const SET_DOCUMENT_TYPE = "SET_DOCUMENT_TYPE";
export const SET_BANK_DETAILS = "SET_BANK_DETAILS";
export const SET_ADVANCE_PAYMENT = "SET_ADVANCE_PAYMENT";
export const SET_ADDITIONAL_DETAIL_DATA = "SET_ADDITIONAL_DETAIL_DATA";
export const SET_LOGISTIC_DETAIL_DATA = "SET_LOGISTIC_DETAIL_DATA"; 
export const SET_DOCUMENT_ITEM_GRID_DATA = "SET_DOCUMENT_ITEM_GRID_DATA";
export const SET_DOCUMENT_ADDITIONAL_CHARGES = "SET_DOCUMENT_ADDITIONAL_CHARGES";
export const SET_DOCUMENT_ITEM_FORM_DATA = "SET_DOCUMENT_ITEM_FORM_DATA"; 
export const SET_DOCUMENTS_TERMS_AND_CONDITION_DATA = "SET_DOCUMENTS_TERMS_AND_CONDITION_DATA";
export const SET_DOCUMENT_ATTACHMENTS_DATA = "SET_DOCUMENT_ATTACHMENTS_DATA";
export const SET_DOCUMENTS_SIGNATURE_DATA = "SET_DOCUMENTS_SIGNATURE_DATA";
export const RESET_CREATE_DOCUMENT_DATA = "RESET_CREATE_DOCUMENT_DATA";
