import React, { useState } from "react";
import { Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./TermsAndCondition.css";
import { AppConstants } from "../../../Appconstants";
import { connect } from "react-redux"; 
import { setDocumentTermsAndConditionsData } from "../../../redux/actions/UI/createDocument";

const { TextArea } = Input;

interface ITermsAndConditionProps {
  setDocumentTermsAndConditionsData: Function;
  dataSource: string;
}

const TermsAndCondition: React.FC<ITermsAndConditionProps> = ({
  setDocumentTermsAndConditionsData,
  dataSource
}) => { 
  const [termsCondition, setTermsCondition] = useState("");
  
  const handleBlur = () => {
    const newData = termsCondition
    ?.toString()
    ?.split("\n")
    ?.filter((line: string) => line.trim() !== "");
    setDocumentTermsAndConditionsData(newData);
  };

  return (
    <div className="flex-grow-1">
      <div className="title mb-20">
        {AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL} &nbsp;
        <Tooltip title={AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL}>
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>
      <TextArea
        style={{ resize: "none" }}
        className="termCondition-textarea mb-20"
        rows={10}
        maxLength={1000}
        showCount
        placeholder="Write Terms and condition"
        value={dataSource ? dataSource[0] : termsCondition && typeof termsCondition === "string" ? termsCondition : ""}
        onChange={(e) => setTermsCondition(e.target.value)}
        onBlur={handleBlur}
      />
    </div>

  );
};

const mapStateToProps = (state: any) => ({
  dataSource: state.ui.documentItemsGridData?.documentTermsAndConditionData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentTermsAndConditionsData: (payload: any) => dispatch(setDocumentTermsAndConditionsData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);
