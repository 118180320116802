import React, { useState } from 'react';
import './LLMBot.css'; // Import the CSS file for styles
import LLMBot from '../Utility/images/LLMBot.jpg';
import { SendOutlined, MoreOutlined } from '@ant-design/icons'; // Importing Ant Design send icon

const ChatbotIcon: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<{ text: string; type: 'sent' | 'received' }[]>([
        { text: `👋 Hi, I'm Amy.`, type: 'received' },
        { text: `How can I you help you?`, type: 'received' }
    ]);
    const [inputValue, setInputValue] = useState('');

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const handleSendMessage = () => {
        if (inputValue.trim() === '') return;

        // Add the sent message
        setMessages((prevMessages) => [
            ...prevMessages,
            { text: inputValue, type: 'sent' },
        ]);

        // Simulate a received message after a short delay
        setTimeout(() => {
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: 'Thank you for your message!', type: 'received' },
            ]);
        }, 1000);

        setInputValue(''); // Clear the input field
    };

    return (
        <>
            {!isOpen && (
                <div className="chatbot-icon" onClick={toggleChat}>
                    <img src={LLMBot} alt='LLMBot' />
                </div>
            )}
            {isOpen && (
                <div className="chatbox">
                    <div className="chatbox-header">
                        <div className='chatbox-header-content'>
                            <div>
                                <img src={LLMBot} alt='LLMBot' /> 

                                <div className='botName'>
                                    <div>Amy</div>
                                    <div style={{fontSize: '10px'}}>Powered by AI</div>
                                </div>
                            </div>
                            <div className='flexBox'>
                                <MoreOutlined />
                                <button className="close-button" onClick={toggleChat}>
                                    &times;
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="chatbox-body">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.type}`}>
                                {msg.text}
                            </div>
                        ))}
                    </div>
                    <div className="chatbox-footer">
                        <input
                            type="text"
                            placeholder="Type a message..."
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendMessage();
                                }
                            }}
                        />
                        <button onClick={handleSendMessage} className="send-icon">
                            <SendOutlined style={{ fontSize: '20px', color: '#007bff' }} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatbotIcon;
