import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Input, Form, Row, Col, Popconfirm, Space, Table, Flex, Tag, Checkbox, Select, notification, Alert, Drawer, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SeriesData, SeriesMetaDataModalProps } from "./ISeriesMetaData";
import './SeriesMetaDataModal.css'
import { addDocumentSeries, deleteDocumentSeries, editDocumentSeries, getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { connect } from "react-redux";
import Loader from "../../../Home/Loader/Loader";
import { render } from "@testing-library/react";
type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

const SeriesMetaDataModal: React.FC<SeriesMetaDataModalProps> = ({ isVisible, onClose, onSelectSeries, documentSeriesData, setIsVisible, addDocumentSeries, addDocumentSeriesState, editDocumentSeries, editDocumentSeriesState, getDocumentSeries, getDocumentSeriesState, deleteDocumentSeries, deleteDocumentSeriesState, companyId, userId, documentType }) => {
    const prevPropsRef = useRef<any>();
    const [form] = Form.useForm();
    const [seriesName, setSeriesName] = useState<string>("");
    const [series, setSeries] = useState<string>("");
    const [number, setNumber] = useState<number | undefined>(undefined);
    const [docType, setDocType] = useState<string>("");
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editRecord, setEditRecord] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [documentNumner, setDocumentNumber] = useState<string>('');
    const [showSuccessNotification, setShowSuccessNotification] = useState<boolean>(false);

    useEffect(() => {
        getDocumentSeries({ companyId: Number(companyId) });
    }, [companyId]);

    useEffect(() => {
        if (documentSeriesData) {
            const documentNumbers = documentSeriesData.map((item: any) => `${item.prefix}-${item.number}`);
            const filteredSeries = documentSeriesData?.filter((series: { DocType: string }) => series.DocType == documentType);
            setDataSource(filteredSeries);
            // onDocumentNumberGenerated(documentNumbers);
        }
    }, [documentSeriesData]);

    useEffect(() => {
        if (!loading && documentSeriesData) {
            setDataSource(documentSeriesData);
        }
    }, [documentSeriesData, loading]);

    useEffect(() => {
        if (prevPropsRef?.current?.getDocumentSeriesState?.loading && !getDocumentSeriesState?.loading) {
            if (addDocumentSeriesState?.error?.length > 0) {
            } else {
                setLoading(false);
            }
        }
        prevPropsRef.current = {getDocumentSeriesState}
    }, [getDocumentSeriesState]);

    useEffect(() => {
        if (prevPropsRef?.current?.addDocumentSeriesState?.loading && !addDocumentSeriesState?.loading) {
            if (addDocumentSeriesState?.error?.length > 0) {
                openNotificationWithIcon("error", "Something went wrong, please try again later.");
            } else {
                openNotificationWithIcon("success", "Series added successfully");
                getDocumentSeries({ companyId: Number(companyId) });
                setIsEditing(false);
                setIsFormVisible(false);
            }
        }
        prevPropsRef.current = {addDocumentSeriesState}
    }, [addDocumentSeriesState]);

    useEffect(() => {
        if (prevPropsRef?.current?.editDocumentSeriesState?.loading && !editDocumentSeriesState?.loading) {
            if (editDocumentSeriesState?.error?.length > 0) {
                openNotificationWithIcon("error", "Something went wrong, please try again later.");
            } else {
                openNotificationWithIcon("success", "Series updated successfully");
                getDocumentSeries({ companyId: Number(companyId) });
                setIsEditing(false);
                setIsFormVisible(false);
            }
        }
        prevPropsRef.current = {editDocumentSeriesState}
    }, [editDocumentSeriesState, showSuccessNotification]);

    useEffect(() => {
        if (deleteDocumentSeriesState.loading === false && !deleteDocumentSeriesState.loading && showSuccessNotification) {
            if (deleteDocumentSeriesState.error) {
                openNotificationWithIcon("error", "Failed to delete series");
            } else {
                openNotificationWithIcon("success", "Series deleted successfully");
                setShowSuccessNotification(false)
            }
            getDocumentSeries({ companyId: Number(companyId) });
        }
    }, [deleteDocumentSeriesState, showSuccessNotification]);

    const openNotificationWithIcon = (type: NotificationType, message: string) => {
        notification[type]({
            message: message,
            duration: 2,
        });
    };

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            setNumber(value);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        form.validateFields().then((values: any) => {
            const { seriesName, series, number, docType } = values;
            const documentNumber = `${series}${number}`;
            if (isEditing && editRecord) {
                editDocumentSeries({
                    id: editRecord.id,
                    seriesName: seriesName,
                    series: series,
                    docType: docType,
                    companyId: Number(companyId),
                    userId: Number(userId),
                    ip_address: '127.0.0.1',
                });
                const updatedDataSource = dataSource.map((item) =>
                    item.id === editRecord.id
                        ? { ...item, seriesName, series, number, docType }
                        : item
                );
                // setDataSource(updatedDataSource);
                // setIsEditing(false);
                // setEditRecord(null);
                // setIsFormVisible(false);
                // setLoading(true);
            } else {
                addDocumentSeries({
                    seriesName: seriesName,
                    series: series,
                    number: number,
                    nextNumber: number,
                    default: dataSource.length > 0 ? 0 : 1,
                    docType: docType,
                    companyId: Number(companyId),
                    userId: Number(userId),
                    ip_address: '192.168.1.1',
                });
                // setDataSource([...dataSource, newSeries]);
                // const documentNumbers = [documentNumber, ...dataSource.map((series) => series.documentNumber)];
                // // onDocumentNumberGenerated(documentNumbers);
                // onSelectSeries(documentNumber);
                // form.resetFields();
                // getDocumentSeries({ companyId: Number(companyId) });
                // console.log(dataSource)
            }
        }).catch((errorInfo: any) => {
            console.log("Validation failed:", errorInfo);
        });
    };

    const handleAddNewSeries = () => {
        setIsFormVisible(true);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsFormVisible(false);
        form.resetFields()
    };

    const handleEditSeries = (record: any) => {
        setIsEditing(true);
        setEditRecord(record);
        setIsFormVisible(true);
        form.setFieldsValue({
            seriesName: record.seriesName,
            series: record.prefix,
            number: record.number,
            docType: record.DocType,
        });
    };

    const handleBackToAdd = () => {
        setIsEditing(false);
        setEditRecord(null);
        form.resetFields();
        setIsFormVisible(false);
    };

    const handleCheckboxChange = (key: string, documentNumber: string) => {
        setActiveKey(key);
        const updatedDataSource = dataSource.map(series => ({
            ...series,
            isActive: series.key === key,
        }));
        setDataSource(updatedDataSource);
        onSelectSeries(documentNumber);
    };

    const handleDelete = (id: number) => {
        setLoading(true);
        deleteDocumentSeries({ id });
    };

    const formatData = (dataSource: any) => {
        const filteredSeries = documentSeriesData?.filter((series: { DocType: string }) => series.DocType == documentType);
        let data = filteredSeries?.reverse()?.map((elem: any) => {
            return {
                DocType: elem.DocType,
                seriesName: elem.seriesName,
                prefix: elem.prefix,
                number: elem.number,
                numberData: elem.prefix + '-' + elem.number,
                nextNumber: elem.nextNumber,
                nextNumberData: elem.prefix + '-' + elem.nextNumber,
                default: elem.default,
                defaultData: elem.default == 1 ? <Tag color="green">Default</Tag> : <Tag color="blue" style={{ cursor: "pointer" }}>Set as default</Tag>
            }
        });
        // setDataSource(filteredSeries);
        return data;
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: 'checked',
            render: (_: any, record: any) => (
                <Checkbox checked={record.default == 1 ? true : false} value={record.seriesName} onChange={() => {

                }}></Checkbox>
            )
        },
        {
            title: 'Document Type',
            dataIndex: 'DocType',
            key: 'docType'
        },
        {
            title: 'Name',
            dataIndex: 'seriesName',
            key: 'seriesName'
        },
        {
            title: 'Prefix',
            dataIndex: 'prefix',
            key: 'series',
        },
        {
            title: 'Doc. Starting No.',
            dataIndex: 'numberData',
            key: 'number',
        },
        {
            title: 'Doc. Next No.',
            dataIndex: 'nextNumberData',
            key: 'nextNumber'
        },
        {
            title: 'Action',
            key: 'action',
            width: '70px',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <a onClick={() => handleEditSeries(record)}><EditOutlined /></a>
                    <Popconfirm
                        title="Are you sure to delete this?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(record.id)}
                    >
                        <a key="list-loadmore-more"><DeleteOutlined /></a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Loader loading={loading}></Loader>
            <Drawer
                title={'Customize document number'}
                placement="right"
                size={'large'}
                onClose={onClose}
                open={isVisible}
                width={'1000px'}
                extra={
                    <Space>
                        <Button onClick={onClose}>Close</Button>
                    </Space>
                }
            >
                {isFormVisible && (
                    <p className="backLinkSM" onClick={() => {
                        setIsFormVisible(false);
                        setIsEditing(false);
                        form.resetFields();
                    }}>
                        <ArrowLeftOutlined /> Back to Manage document series
                    </p>
                )}

                <Alert
                    description="A custom document number series generates unique identifiers for records using prefixes, sequential numbers, and date information, ensuring traceability and efficient management."
                    type="info"
                />

                {!isEditing && !isFormVisible && (
                    <div className="flexBox mt-10">
                        <Input
                            placeholder="Search Series"
                            onChange={(e: any) => { }}
                            className=""
                        />
                        <Button type="primary" onClick={handleAddNewSeries}>Add New Series</Button>
                    </div>
                )}
                {isFormVisible && (
                    <Form form={form} layout="vertical" requiredMark className="series-form">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Document Type" name="docType" rules={[{ required: true, message: "Please select a document type" }]}>
                                    <Select placeholder="Select document type">
                                        <Option value="Sales Enquiry">Sales Enquiry</Option>
                                        <Option value="Sales Quotation">Sales Quotation</Option>
                                        <Option value="Order Confirmation">Order Confirmation</Option>
                                        <Option value="Delivery Challan">Delivery Challan</Option>
                                        <Option value="Invoice">Invoice</Option>
                                        <Option value="Sales Return">Sales Return</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Series Name" name="seriesName">
                                    <Input
                                        value={seriesName}
                                        onChange={(e) => setSeriesName(e.target.value)}
                                        placeholder="Enter series name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Series" name="series" rules={[{
                                    required: true,
                                    message: "Please fill the prefix",
                                },]}>
                                    <Input
                                        value={series}
                                        onChange={(e) => setSeries(e.target.value)}
                                        placeholder="Enter prefix"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Number" name="number" rules={[
                                    {
                                        required: true,
                                        message: "Please fill the number",
                                    },
                                ]}>
                                    <Input
                                        type="number"
                                        value={number}
                                        onChange={handleNumberChange}
                                        placeholder="Enter number"
                                        disabled={isEditing}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="end">
                            <Col>
                                <Button onClick={handleSubmit} type="primary">
                                    {isEditing ? "Update" : "Submit"}
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                )}

                {!isEditing && !isFormVisible && (
                    <Table
                        bordered
                        dataSource={formatData(dataSource)}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: 'No data available',
                        }}
                        className="mt-20"
                    />
                )}
            </Drawer>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    documentSeriesData: state.api.getDocumentSeries?.data,
    userId: state.api.login?.data.id,
    companyId: state.api.login?.data.companyId,
    getDocumentSeriesState: state.api?.getDocumentSeries,
    addDocumentSeriesState: state.api?.addDocumentSeries,
    editDocumentSeriesState: state.api?.editDocumentSeries,
    deleteDocumentSeriesState: state.api?.deleteDocumentSeries,
    documentType: state.ui.createDocumentUIData.documentType,
});

const mapDispatchToProps = (dispatch: any) => ({
    addDocumentSeries: (payload: any) => dispatch(addDocumentSeries(payload)),
    getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
    editDocumentSeries: (payload: any) => dispatch(editDocumentSeries(payload)),
    deleteDocumentSeries: (payload: any) => dispatch(deleteDocumentSeries(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesMetaDataModal);
