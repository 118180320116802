import {
  Breadcrumb,
  Button,
  Dropdown,
  Input,
  Layout,
  Space,
  Switch,
  Table,
  Splitter,
  Pagination,
  Select,
  TableProps,
  Tag,
  Tooltip,
  Popconfirm,
  Popover,
  List,
  Checkbox,
  Menu,
  DatePicker,
  Radio,
  RadioChangeEvent,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import {
  PlusOutlined,
  DownloadOutlined,
  FastForwardFilled,
  EditOutlined,
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  CloseOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import reportData from "./Report.json";
import { TableRowSelection } from "antd/es/table/interface";
import noResult from "../../../Utility/images/noresult.png";
import "./Report.css";
import ReportType from "./ReportType";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  getFilteredDataAndColumns,
  listData,
} from "../../utility/reportsUtility";
import {
  salesEnquiryColumns,
  salesQuotationColumns,
  orderConfirmationColumns,
  deliveryChallanColumns,
  salesReturnColumns,
} from "../../utility/reportColumns";
import dayjs, { Dayjs } from "dayjs";
import { AppConstants } from "../../../Appconstants";

interface IReportsProps {
  documents: any;
  dataToPreview: any;
}

interface IItems {
  numberOfItems: number;
  itemValueBeforeTax: string;
  extraChargesBeforeTax: string;
  totalBeforeTax: string;
  totalTax: string;
  totalAfterTax: string;
  nonTaxableExtraCharge: string;
  tcsAmount: string;
  ProformaGrandTotal: string;
  advancePaid: string;
  amountToPay: string;
  paymentTerm: string;
  paymentDueDate: string;
}

interface DataType {
  documentNumber: string;
  documentDate: string;
  documentType: string;
  documentStatus: string;
  poNumber: string;
  poDate: string;
  ocNumber: string;
  ocDate: string;
  ocDeliveryDate: string;
  items: IItems[];
}

interface ITotals {
  totalBeforeTax: number;
  totalTax: number;
  totalAfterTax: number;
  grandTotal: number;
}

type ReportType = keyof typeof AppConstants.REPORT_TYPE;

const Reports: React.FC<IReportsProps> = ({ documents, dataToPreview }) => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isSplitterActive, setIsSplitterActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [forBulkEditOpen, setIsforBulkEditOpen] = useState(false);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedProformaNumber, setSelectedProformaNumber] = useState<
    string | null
  >(null);
  const [selectedItemData, setSelectedItemData] = useState<any[]>([]);
  const [filteredReport, setFilteredReport] = useState<DataType[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [reportType, setReportType] = useState<ReportType | null>(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>("");
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<string>("Sales Enquiry");
  const [selectedDocuments, setSelectedDocuments] = useState<any | null>(null);
  const [columns, setColumns] = useState(salesEnquiryColumns);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean | undefined>(false); 
  const [docTypeFilter, setDocTypeFilter] = useState<string[]>([]);
  const [dealStatusFilter, setDealStatusFilter] = useState<string[]>([]);
  const [ocCreatedFilter, setOCCreatedFilter] = useState<string[]>([]); 
  const [filteredDocs, setFilteredDocs] = useState(documents);
  const [createdAtRange, setCreatedAtRange] = useState<[Dayjs | null, Dayjs | null] | null>(null); 
  const [searchTerm, setSearchTerm] = useState<string>("")

  const itemTableColumns: TableProps<DataType>["columns"] = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      key: "itemId",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "HSN",
      dataIndex: "HSN",
      key: "HSN",
    },
    {
      title: "UOM",
      dataIndex: "UOM",
      key: "UOM",
    },
    {
      title: "Item Rate",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Item Total - Before Discount",
      dataIndex: "totalBeforeTax",
      key: "totalBeforeTax",
    },
    {
      title: "Item Discount Amount",
      dataIndex: "totalDiscount",
      key: "totalDiscount",
    },
    {
      title: "Item Total - Before Tax",
      dataIndex: "totalBeforeTax",
      key: "totalBeforeTax",
    },
    {
      title: "Item Tax",
      dataIndex: "totalTax",
      key: "totalTax",
    },
    {
      title: "Item Total - After Tax",
      dataIndex: "totalAfterTax",
      key: "totalAfterTax",
    },
    {
      title: "Payment Term",
      dataIndex: "paymentTerm",
      key: "paymentTerm",
    },
  ];

  const selectedDocument = documents.find(
    (doc: any) => doc.documentNumber === selectedRowKeys[0]
  );

  useEffect(() => {
    if (reportType) {
      const defaultDocType = listData[reportType][0].value;
      setDocumentType(defaultDocType);
    }
  }, [reportType]);

  useEffect(() => {
    setData(filterData());
  }, [documents, documentType, reportType]);

  const filterReports = (data: DataType[], searchText: string): DataType[] => {
    return data.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (isSplitterActive && filteredDocuments.length > 0) {
      const firstDocumentNumber = filteredDocuments[0].documentNumber;
      setSelectedRowKeys([firstDocumentNumber]);
      setCurrentRowIndex(0);
    }
  }, [documentType, documents, isSplitterActive]);

  const filteredDocuments = documentType
    ? documents.filter((doc: any) => doc.documentType === documentType)
    : documents;

  const handleSplitViewToggle = (checked: boolean) => {
    setIsSplitterActive(checked);
    setSelectedRowKeys(checked ? [filteredDocuments[0].documentNumber] : []);
  };

  useEffect(() => {
    if (!isSplitterActive) {
      setCurrentRowIndex(0);
      setSelectedRowKeys([]);
    }
  }, [isSplitterActive]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setCurrentRowIndex(
      filteredDocuments.findIndex(
        (doc: any) => doc.documentNumber === newSelectedRowKeys[0]
      )
    );
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: isSplitterActive ? "radio" : "checkbox",
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const navigateRows = (direction: "up" | "down") => {
    let newIndex = currentRowIndex;
    if (direction === "up" && currentRowIndex > 0) newIndex -= 1;
    if (direction === "down" && currentRowIndex < filteredDocuments.length - 1)
      newIndex += 1;

    setCurrentRowIndex(newIndex);
    const newSelectedDocument = filteredDocuments[newIndex];
    setSelectedRowKeys([newSelectedDocument.documentNumber]);
    setSelectedDocuments(newSelectedDocument);
  };

  const closeSplitView = () => {
    setIsSplitterActive(false);
    setSelectedRowKeys([]);
    setLoading(false);
    setIsExpanded(false);
  };

  const filterData = () => {
    let filtered = documents;

  // Filter by Document Type
  if (documentType) {
    filtered = filtered.filter(
      (doc: any) =>
        doc.documentType.toLowerCase() === documentType.toLowerCase()
    );
  }

  // Filter by Created At Range
  if (createdAtRange && createdAtRange.length === 2) {
    const [start, end] = createdAtRange;
    const startDate = start ? dayjs(start) : null;
    const endDate = end ? dayjs(end) : null;

    if (startDate && endDate) {
      filtered = filtered.filter((doc: { createdAt: string }) => {
        const createdAtDate = dayjs(doc.createdAt);
        return (
          createdAtDate.isSameOrAfter(startDate, "day") &&
          createdAtDate.isSameOrBefore(endDate, "day")
        );
      });
    }
  }

  // Filter by Deal Status
  if (dealStatusFilter.length > 0) {
    filtered = filtered.filter((doc: { dealStatus: string }) =>
      dealStatusFilter.includes(doc.dealStatus)
    );
  }

  // Filter by OC Created
  if (ocCreatedFilter.length > 0) {
    filtered = filtered.filter((doc: { OCDate: any }) =>
      ocCreatedFilter.includes(doc.OCDate ? "Yes" : "No")
    );
  }

  // Additional Filters (if needed)
  if (searchTerm) {
    filtered = filtered.filter((doc: any) =>
      doc.documentNumber.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return filtered;
  };

  const renderReportTable = (): JSX.Element => {
    return (
      <>
        {filterReports?.length > 0 ? (
          <Table<DataType>
            className={isSplitterActive ? "splitterTable" : ""}
            columns={columns}
            rowKey="documentNumber"
            dataSource={filterData()}
            bordered
            pagination={false}
            scroll={{ y: "max-content" }}
            sticky
            rowSelection={rowSelection}
          />
        ) : (
          <div className="noData">
            <img src={noResult} alt="documentsData" />
            <p className="mt-20">
              You have not created any documents yet. Please create a document
            </p>
          </div>
        )}
      </>
    );
  };

  const setSelectedReportType = (value: ReportType) => {
    setReportType(value);
  };

  const renderItemTable = (): JSX.Element => (
    <Table
      columns={itemTableColumns}
      dataSource={selectedDocument?.items || []}
      rowKey="itemId"
      pagination={false}
      bordered
      scroll={{ x: "auto" }}
    />
  );

  const handleDocumentTypeChange = (docType: string) => {
    const { columns: newColumns, filteredData } = getFilteredDataAndColumns(
      docType,
      documents
    );
    setColumns(newColumns);
    setDataSource(filteredData);
  };

  useEffect(() => {
    handleDocumentTypeChange(documentType);
  }, [documentType, documents]);

  const handleReportTypeChange = (value: ReportType) => {
    setReportType(value);
    const defaultDocType = listData[value as ReportType][0].value;
    setDocumentType(defaultDocType);
  };

  const combinedDataSource = searchTerm ? filteredDocuments: filterData();

 const handleDocTypeFilterChange = (selectedValues: string[]) => {
    setDocTypeFilter(selectedValues);
    if  (selectedValues.length > 0) {
      setDocumentType(selectedValues[0]);
    }
  };
  
  const handleDealStatusFilterChange = (selectedValues: string[]) => {
    setDealStatusFilter(selectedValues);
    const filteredData = filterData();
    setFilteredDocs(filteredData);
  };
  
  const handleOCCreatedFilterChange = (selectedValues: string[]) => {
    setOCCreatedFilter(selectedValues);
    const filteredData = filterData();
    setFilteredDocs(filteredData);
  };
  
const applyFilters = () => {
  let filtered = documents;

  if (docTypeFilter.length > 0) {
    filtered = filtered.filter((doc: { documentType: string }) =>
      docTypeFilter.includes(doc.documentType)
    );
  }

  if (dealStatusFilter.length > 0) {
    filtered = filtered.filter((doc: { dealStatus: string }) =>
      dealStatusFilter.includes(doc.dealStatus)
    );
  }

  if (ocCreatedFilter.length > 0) {
    filtered = filtered.filter((doc: { OCDate: any }) =>
      ocCreatedFilter.includes(doc.OCDate ? "Yes" : "No")
    );
  }

  if (createdAtRange && createdAtRange.length === 2) {
    const [start, end] = createdAtRange;
    if (start && end) {
      filtered = filtered.filter((doc: { createdAt: string }) => {
        const createdAtDate = dayjs(doc.createdAt);
        return (
          createdAtDate.isSameOrAfter(start, "day") &&
          createdAtDate.isSameOrBefore(end, "day")
        );
      });
    }
  }

  // Set the filtered documents
  setFilteredDocs(filtered.length > 0 ? filtered : []);
};

const handleApplyFilters = () => {
  const filteredData = filterData();
  setFilteredDocs(filteredData);
};

const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(event.target.value);
};

const handleDateSelect = (e: RadioChangeEvent) => {
  const value = e.target.value;
  console.log(value)
  const today = dayjs();
  let startDate;

  switch (value) {
    case 'last7Days':
      startDate = today.subtract(7, 'days');
      console.log(startDate)
      break;
    case 'last30Days':
      startDate = today.subtract(30, 'days');
      break;
    case 'last3Months':
      startDate = today.subtract(3, 'months');
      break;
    case 'last6Months':
      startDate = today.subtract(6, 'months');
      break;
    default:
      startDate = null;
  }

  setCreatedAtRange([startDate, today]);
  const filteredData = filterData();
  setFilteredDocs(filteredData);
};

  const renderFilterMenu = (
    <Menu
      style={{width: 250,  padding: '10px' }}
      items={[
        {
          key: '1',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Filter by Doc Type</strong>
              <Checkbox.Group onChange={handleDocTypeFilterChange}>
                <Checkbox value="Sales Enquiry">Sales Enquiry</Checkbox>
                <Checkbox value="Sales Quotation">Sales Quotation</Checkbox>
                <Checkbox value="Order Confirmation">Order Confirmation</Checkbox>
                <Checkbox value="Delivery Challan">Delivery Challan</Checkbox>
                <Checkbox style={{width:250}} value="Invoice">Invoice</Checkbox>
                <Checkbox value="Sales Return">Sales Return</Checkbox> 
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Filter by Deal Status</strong>
              <Checkbox.Group
                style={{ display: 'block', marginTop: 8 }}
                onChange={handleDealStatusFilterChange}
              >
                <Checkbox value="draft">Draft</Checkbox>
                <Checkbox value="sent">Sent</Checkbox>
                <Checkbox style={{width:250}} value="partiallyDispatch">Partially Dispatch</Checkbox>
                <Checkbox value="dispatch">Dispatch</Checkbox>
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>OC Created</strong>
              <Checkbox.Group
                style={{ display: 'block', marginTop: 8 }} 
                onChange={handleOCCreatedFilterChange}
              >
                <Checkbox value="yes">Yes</Checkbox>
                <Checkbox value="no">No</Checkbox> 
              </Checkbox.Group>
            </div>
          ),
        },
        {
          key: '4',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Creation Date</strong>
              <DatePicker.RangePicker 
                onChange={(dates: [Dayjs | null, Dayjs | null] | null) => {
                  setCreatedAtRange(dates);  
                }}
              />
            </div>
          ),
        },
        {
          key: '5',
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <strong>Date Range</strong>
              <Radio.Group onChange={handleDateSelect}>
                <Radio value="last7Days">Last 7 Days</Radio>
                <Radio value="last30Days">Last 30 Days</Radio>
                <Radio value="last3Months">Last 3 Months</Radio>
                <Radio value="last6Months">Last 6 Months</Radio>
              </Radio.Group>
            </div>
          ),
        },
        {
          key: '6',
          label: (
            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <>
      {reportType ? (
        <Layout className="layout">
          <div>
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1,
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Report Type</Breadcrumb.Item>
              <Breadcrumb.Item>Reports</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <div className="flexBox mt-20"></div> */}
          <Content className="content-section">
            <>
              <div className="mb-10">
                <div className="flexBox">
                  <div>
                    <Button type="link" className="buttonAddItem">
                      <DownloadOutlined /> Download
                    </Button>
                  </div>
                  <div className="flexBox">
                    <div>
                      <Switch
                        onChange={handleSplitViewToggle}
                        checked={isSplitterActive}
                        className="switch-split-screen"
                      />
                      <span className="text-split">Split View</span>
                    </div>
                    <Dropdown overlay={renderFilterMenu} trigger={["click"]}>
                      <Tooltip title="Filter">
                        <Button
                          type="link"
                          onClick={() => setFilterDropdownVisible(true)}
                          icon={<FilterOutlined />}
                        />
                      </Tooltip>
                    </Dropdown>
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Search to select report type"
                      optionFilterProp="label"
                      defaultValue={reportType}
                      onChange={handleReportTypeChange}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "salesReport",
                          label: "Sales Report",
                        },
                        {
                          value: "inventoryReport",
                          label: "Inventory Report",
                        },
                        {
                          value: "purchaseReport",
                          label: "Purchase Report",
                        },
                        {
                          value: "paymentReport",
                          label: "Payment Report",
                        },
                      ]}
                    />
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select document type"
                      value={documentType}
                      onChange={(value) => {
                        setDocumentType(value);
                        handleDocumentTypeChange(value);
                      }}
                      options={listData[reportType].map((item) => ({
                        value: item.value,
                        label: item.title,
                      }))}
                    />
                    <Input placeholder="Search..." style={{ width: 200 }} value={searchTerm}
                  onChange={handleSearch} />
                  </div>
                </div>
              </div>
              {isSplitterActive ? (
                <Splitter
                  style={{
                    height: 200,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {!isExpanded && (
                    <Splitter.Panel defaultSize="40%" min={"20%"} max={"70%"}>
                      {renderReportTable()}
                    </Splitter.Panel>
                  )}
                  <Splitter.Panel>
                    <div className="slicerContainer">
                      <Header className="header-split-view">
                        <div>
                          <ArrowUpOutlined
                            onClick={() => navigateRows("up")}
                            className="iconArrowUp"
                          />
                          <span className="spanIndex">
                            {currentRowIndex + 1} of {filteredDocuments.length}
                          </span>
                          <ArrowDownOutlined
                            onClick={() => navigateRows("down")}
                          />
                        </div>
                        <div>
                          {isExpanded ? (
                            <ShrinkOutlined onClick={toggleExpand} />
                          ) : (
                            <ArrowsAltOutlined onClick={toggleExpand} />
                          )}
                          <CloseOutlined onClick={() => closeSplitView()} />
                        </div>
                      </Header>
                      {selectedDocument ? (
                        renderItemTable()
                      ) : (
                        <p>Select a document to view details.</p>
                      )}
                    </div>
                  </Splitter.Panel>
                </Splitter>
              ) : (
                renderReportTable()
              )}
              <Pagination
                total={filteredDocuments?.length}
                defaultPageSize={20}
                current={currentPage}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }}
                showSizeChanger
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                style={{ textAlign: "center", marginTop: 20 }}
              />
            </>
          </Content>
        </Layout>
      ) : (
        <ReportType
          setReportTypeValue={(data: ReportType) => {
            setSelectedReportType(data);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
  dataToPreview: state.api.getDocumentById?.data,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
