import { Breadcrumb, Button, Layout, Watermark, WatermarkProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getDocumentById } from '../../../redux/actions/API/documents';
import './PreviewDocument.css';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, DownloadOutlined, PrinterOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import logo from "../../../Utility/images/ease/transparent/ease-primary.png";
import signPlaceholder from '../../../Utility/images/signPlaceholder.png'
import { Color } from 'antd/es/color-picker';
import DocumentSlicer from './DocumentSlicer';
interface ITotals {
  tax: number;
  totalBeforeTax: number;
  totalTax: number;
  totalAfterTax: number;
  grandTotal: number;
}

interface IPreviewDocumentProps {
  getDocumentById: Function;
  dataToPreview: any;
  companyId: string;
  companyName: string,
}

const PreviewDocument: React.FC<IPreviewDocumentProps> = ({ getDocumentById, dataToPreview, companyId, companyName }) => {
  const quotationRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split('?')[1]);
    const documentNumber = params.get('documentNumber');

    if (documentNumber) {
      getDocumentById({ documentNumber, companyId: Number(companyId) });
    }
  }, [getDocumentById, companyId]);

  const downloadPDF = async () => {
    const element = quotationRef.current;
    if (element) {
      const html2pdf = (await import('html2pdf.js')).default;

      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: 'quotation.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .save();
    }
  };

  function numberToWords(num: number): string {
    const belowTwenty: string[] = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens: string[] = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands: string[] = ["", "Thousand", "Million", "Billion"];

    let words: string = "";

    const getWords = (n: number): string => {
      if (n < 20) return belowTwenty[n];
      if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " " + belowTwenty[n % 10] : "");
      if (n < 1000) return belowTwenty[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " and " + getWords(n % 100) : "");
      return "";
    };

    if (num === 0) return "Zero";

    let integerPart: number = Math.floor(num);
    let decimalPart: number = Math.round((num - integerPart) * 100); // For paisa

    let i = 0;
    while (integerPart > 0) {
      let chunk = integerPart % 1000;
      if (chunk > 0) {
        words = getWords(chunk) + (thousands[i] ? " " + thousands[i] : "") + (words ? " " + words : "");
      }
      integerPart = Math.floor(integerPart / 1000);
      i++;
    }

    if (decimalPart > 0) {
      if (words) words += " and ";
      words += getWords(decimalPart) + " Paisa";
    }

    return words + " Only";
  }

  const editDocument = () => {
    console.log("Document Edit Clicked", dataToPreview)
    // navigate("/create_document?documentType=salesQuotation");
  };

  return (
    <Layout className='layout'>
      <div>
        <Breadcrumb
          style={{
            margin: "16px 0",
            flex: 1,
          }}
        >
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Sales</Breadcrumb.Item>
          <Breadcrumb.Item>Documents</Breadcrumb.Item>
          <Breadcrumb.Item>Preview Document</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flexBox mt-20">
        <div className="divDocuments">
          <Link to="/sales_documents" className="linkToDocs">
            <p className="textDocument">
              <ArrowLeftOutlined /> {dataToPreview?.documentNumber}
            </p>
          </Link>
        </div>
        <div className="flexBox">
          {dataToPreview?.status == 1 && <>
            <Button type="link" onClick={downloadPDF}>
              <DownloadOutlined /> Download
            </Button>
            <Button
              type="link"
              onClick={() => {
                window.print();
              }}
            >
              <PrinterOutlined /> Print
            </Button>
            <Button type="link">
              <MailOutlined /> Email
            </Button>
          </>}
          <Button type="primary" onClick={editDocument}>
            <EditOutlined /> Edit
          </Button>
        </div>
      </div>
      {/* <Content className="content-permission p-20"> */}
      {dataToPreview ? (
        <div className="documentContainer" id="documentContainer" ref={quotationRef}>
          {dataToPreview.status == 0 && <div className='watermark'>Draft</div>}
          <DocumentSlicer 
            dataToPreview={dataToPreview} 
            companyName={companyName} logo={logo}
          />
        </div>
      ) : (
        <p>Data not available</p>
      )}
      {/* </Content> */}
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login?.data?.companyId,
  dataToPreview: state.api.getDocumentById?.data,
  companyName: state.api.login.data.companyName,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDocumentById: (payload: any) => dispatch(getDocumentById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDocument);
