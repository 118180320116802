import * as type from '../../types/API/notificationTypes'

export function getNotifications(payload: any) {
    return {
        type: type.GET_NOTIFICATIONS,
        payload: payload
    }
}

export function notify(payload: any) {
    return {
        type: type.NOTIFY,
        payload: payload
    }
}