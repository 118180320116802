import * as type from '../../types/API/documentSeriesType'

export function addDocumentSeries(payload: any) {
    return {
      type: type.ADD_DOCUMENT_SERIES,
      payload: payload,
    };
  }
  
  export function getDocumentSeries(payload: any) {
    return {
      type: type.GET_DOCUMENT_SERIES,
      payload: payload,
    };
  }
  
  export function editDocumentSeries(payload: any) {
    return {
      type: type.EDIT_DOCUMENT_SERIES,
      payload: payload,
    };
  }
  
  export function deleteDocumentSeries(payload: any) {
    return {
      type: type.DELETE_DOCUMENT_SERIES,
      payload: payload,
    };
  }

  export function updateLastDocumentNumber(payload: any) {
    return {
      type: type.UPDATE_LAST_DOCUMENT_NUMBER,
      payload: payload,
    };
  }
  