import { Breadcrumb, Layout } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

interface IPaymentsProps {
}

const Payments: React.FC<IPaymentsProps> = () => {
    useEffect(() => {
    }, []);

    return (
        <Layout className='layout'>
          <div>
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1,
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Payments</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flexBox mt-20"></div>
        </Layout>
    );
};

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
