import * as type from "../../types/API/itemStockTransferHistoryType";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const itemStockTransferHistoryReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ITEM_STOCK_TRANSFER_HISTORY:
      return {
        ...state,
        loading: true,
        error:null
      };
    case type.ITEM_STOCK_TRANSFER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error:null,
        data: action.payload.responseData,
      };
    case type.ITEM_STOCK_TRANSFER_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
