export const itemsCategoryOptions = [
  {
    value: 1,
    label: "Raw Materials",
  },
  {
    value: 2,
    label: "Finished Good",
  },
  {
    value: 3,
    label: "Consumables",
  },
];

export const metricsUnitOptions = [
  { value: "BAG", label: "BAG", description: "Bag" },
  { value: "BAL", label: "BAL", description: "Bale" },
  { value: "BDL", label: "BDL", description: "Bundle" },
  { value: "BKL", label: "BKL", description: "Bucket" },
  { value: "BOU", label: "BOU", description: "Bouquet" },
  { value: "BOX", label: "BOX", description: "Box" },
  { value: "BTL", label: "BTL", description: "Bottle" },
  { value: "BUN", label: "BUN", description: "Bunch" },
  { value: "CAN", label: "CAN", description: "Can" },
  { value: "CBM", label: "CBM", description: "Cubic Meter" },
  { value: "CCM", label: "CCM", description: "Cubic Centimeter" },
  { value: "CMS", label: "CMS", description: "Centimeter" },
  { value: "CTN", label: "CTN", description: "Carton" },
  { value: "DOZ", label: "DOZ", description: "Dozen" },
  { value: "DRM", label: "DRM", description: "Drum" },
  { value: "GGR", label: "GGR", description: "Great Gross" },
  { value: "GMS", label: "GMS", description: "Grams" },
  { value: "GRS", label: "GRS", description: "Gross" },
  { value: "GYD", label: "GYD", description: "Gross Yard" },
  { value: "KGS", label: "KGS", description: "Kilograms" },
  { value: "KLR", label: "KLR", description: "Kiloliter" },
  { value: "KME", label: "KME", description: "Kilometer" },
  { value: "MLT", label: "MLT", description: "Milliliter" },
  { value: "MTR", label: "MTR", description: "Meter" },
  { value: "MTS", label: "MTS", description: "Metric Ton" },
  { value: "NOS", label: "NOS", description: "Numbers" },
  { value: "PAC", label: "PAC", description: "Pack" },
  { value: "PCS", label: "PCS", description: "Pieces" },
  { value: "PRS", label: "PRS", description: "Pairs" },
  { value: "QTL", label: "QTL", description: "Quintal" },
  { value: "ROL", label: "ROL", description: "Roll" },
  { value: "SET", label: "SET", description: "Set" },
  { value: "SQF", label: "SQF", description: "Square Feet" },
  { value: "SQM", label: "SQM", description: "Square Meter" },
  { value: "SQY", label: "SQY", description: "Square Yard" },
  { value: "TBS", label: "TBS", description: "Tablets" },
  { value: "TGM", label: "TGM", description: "Ten Gross" },
  { value: "THD", label: "THD", description: "Thousand" },
  { value: "TON", label: "TON", description: "Ton" },
  { value: "TUB", label: "TUB", description: "Tube" },
  { value: "UGS", label: "UGS", description: "US Gallons" },
  { value: "UNT", label: "UNT", description: "Units" },
  { value: "YDS", label: "YDS", description: "Yards" },
];

export const taxTypeOptions = [
  { value: 1, label: "Inclusive" },
  { value: 2, label: "Exclusive" },
];

export const taxOptions = [
  { value: 0, label: "0 %" },
  { value: 0.1, label: "0.1 %" },
  { value: 0.25, label: "0.25 %" },
  { value: 3, label: "3 %" },
  { value: 5, label: "5 %" },
  { value: 6, label: "6 %" },
  { value: 12, label: "12 %" },
  { value: 18, label: "18 %" },
  { value: 28, label: "28 %" },
];
