export const GET_UOM = 'GET_UOM';
export const GET_UOM_SUCCESS = 'GET_UOM_SUCCESS';
export const GET_UOM_FAILED = 'GET_UOM_FAILED';

export const ADD_UOM = 'ADD_UOM';
export const ADD_UOM_SUCCESS = 'ADD_UOM_SUCCESS';
export const ADD_UOM_FAILED = 'ADD_UOM_FAILED';

export const EDIT_UOM = 'EDIT_UOM';
export const EDIT_UOM_SUCCESS = 'EDIT_UOM_SUCCESS';
export const EDIT_UOM_FAILED = 'EDIT_UOM_FAILED';

export const DELETE_UOM = 'DELETE_UOM';
export const DELETE_UOM_SUCCESS = 'DELETE_UOM_SUCCESS';
export const DELETE_UOM_FAILED = 'DELETE_UOM_FAILED';