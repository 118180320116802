import { SET_APP_LOCATION } from "../types/UI/appLocation";


const initialState = {
    pathname: "/"
}

export interface IAction {
    type: string;
    payload?: any;
}

const appLocationReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SET_APP_LOCATION:
            return action.payload
        default:
            return state
    }
}

export default appLocationReducer;