import * as type from "../../types/API/accountInfoType";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getAccountInfoReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_ACCOUNT_INFO:
      return {
        ...state,
        loading: true,
      };
    case type.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_ACCOUNT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const updateAccountInfoReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.UPDATE_ACCOUNT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
