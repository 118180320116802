export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILED = "GET_ADDRESS_FAILED";

export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";

export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILED = "EDIT_ADDRESS_FAILED";

export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";

export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS";
export const SET_DEFAULT_ADDRESS_SUCCESS = "SET_DEFAULT_ADDRESS_SUCCESS";
export const SET_DEFAULT_ADDRESS_FAILED = "SET_DEFAULT_ADDRESS_FAILED";
