import { call, put, takeEvery } from "redux-saga/effects";
import { AppConstants } from "../../Appconstants";
import { jwtDecode } from "jwt-decode";
import { ADD_TEAM, ADD_TEAM_FAILED, ADD_TEAM_SUCCESS, GET_TEAMS, GET_TEAMS_FAILED, GET_TEAMS_SUCCESS, EDIT_TEAM, EDIT_TEAM_FAILED, EDIT_TEAM_SUCCESS, DELETE_TEAM, DELETE_TEAM_FAILED, DELETE_TEAM_SUCCESS } from "../types/API/teamTypes";
import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILED, GET_CATEGORIES, LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCESS, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAILED, SIGNUP_USER } from "../types/API/types";
import { ADD_BLOG, ADD_BLOG_FAILED, ADD_BLOG_SUCCESS, DELETE_BLOG, DELETE_BLOG_FAILED, DELETE_BLOG_SUCCESS, EDIT_BLOG, EDIT_BLOG_FAILED, EDIT_BLOG_SUCCESS, GET_BLOGS, GET_BLOG_BY_ID, GET_BLOG_BY_ID_FAILED, GET_BLOG_BY_ID_SUCCESS, GET_BLOGS_FAILED, GET_BLOGS_SUCCESS } from "../types/API/blogTypes";
import { ADD_STORE, ADD_STORE_FAILED, ADD_STORE_SUCCESS, GET_STORE, GET_STORE_SUCCESS, GET_STORE_FAILED, EDIT_STORE, EDIT_STORE_SUCCESS, EDIT_STORE_FAILED, DELETE_STORE, DELETE_STORE_SUCCESS, DELETE_STORE_FAILED, GET_STORE_BY_ID, GET_STORE_BY_ID_SUCCESS, GET_STORE_BY_ID_FAILED, GET_STORE_BY_ITEMS_SUCCESS, GET_STORE_BY_ITEMS_FAILED, GET_STORE_BY_ITEMS } from "../types/API/storeTypes";
import { ADD_COMMENT, ADD_COMMENT_FAILED, ADD_COMMENT_SUCCESS, DELETE_COMMENT, DELETE_COMMENT_SUCCESS, DELETE_COMMENT_FAILED, APPROVE_COMMENT, APPROVE_COMMENT_SUCCESS, APPROVE_COMMENT_FAILED, GET_APPROVE_BLOG_COMMENT_SUCCESS, GET_APPROVE_BLOG_COMMENT_FAILED, GET_BLOG_COMMENTS_TO_BE_APPROVED_SUCCESS, GET_BLOG_COMMENTS_TO_BE_APPROVED_FAILED, GET_APPROVE_BLOG_COMMENT, GET_BLOG_COMMENTS_TO_BE_APPROVED, GET_ALL_BLOGS_APPROVED_COMMENTS, GET_ALL_BLOGS_APPROVED_COMMENTS_SUCCESS, GET_ALL_BLOGS_APPROVED_COMMENTS_FAILED } from "../types/API/commentTypes";
import { UPLOAD_IMAGE, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILED } from "../types/API/imageUploadTypes";
import { ADD_USER, ADD_USER_FAILED, ADD_USER_SUCCESS, DELETE_USER, DELETE_USER_FAILED, DELETE_USER_SUCCESS, EDIT_USER, EDIT_USER_FAILED, EDIT_USER_SUCCESS, GET_USERS, GET_USERS_FAILED, GET_USERS_SUCCESS } from "../types/API/userTypes";
import { ADD_ADDRESS, ADD_ADDRESS_FAILED, ADD_ADDRESS_SUCCESS, DELETE_ADDRESS, DELETE_ADDRESS_FAILED, DELETE_ADDRESS_SUCCESS, EDIT_ADDRESS, EDIT_ADDRESS_FAILED, EDIT_ADDRESS_SUCCESS, GET_ADDRESS, GET_ADDRESS_FAILED, GET_ADDRESS_SUCCESS, SET_DEFAULT_ADDRESS, SET_DEFAULT_ADDRESS_FAILED, SET_DEFAULT_ADDRESS_SUCCESS } from "../types/API/addressTypes";
import { ADD_ITEM, ADD_ITEM_FAILED, ADD_ITEM_SUCCESS, BULK_DELETE_ITEM, BULK_DELETE_ITEM_FAILED, BULK_DELETE_ITEM_SUCCESS, DELETE_ITEM, DELETE_ITEM_FAILED, DELETE_ITEM_SUCCESS, EDIT_ITEM, EDIT_ITEM_FAILED, EDIT_ITEM_SUCCESS, GET_ITEMS, GET_ITEMS_FAILED, GET_ITEMS_SUCCESS } from "../types/API/itemTypes";
import { ADD_BUYER_SUPPLIER, ADD_BUYER_SUPPLIER_FAILED, ADD_BUYER_SUPPLIER_SUCCESS, DELETE_BUYER_SUPPLIER, DELETE_BUYER_SUPPLIER_FAILED, DELETE_BUYER_SUPPLIER_SUCCESS, GET_BUYER_SUPPLIER, GET_BUYER_SUPPLIER_FAILED, GET_BUYER_SUPPLIER_SUCCESS } from "../types/API/buyerSupplier";
import { ADD_BANK_DETAIL, ADD_BANK_DETAIL_FAILED, ADD_BANK_DETAIL_SUCCESS, DELETE_BANK_DETAIL, DELETE_BANK_DETAIL_FAILED, DELETE_BANK_DETAIL_SUCCESS, EDIT_BANK_DETAIL, EDIT_BANK_DETAIL_FAILED, EDIT_BANK_DETAIL_SUCCESS, GET_BANK_DETAIL, GET_BANK_DETAIL_FAILED, GET_BANK_DETAIL_SUCCESS } from "../types/API/bankDetailType";
import { ADD_DOCUMENT_SERIES, ADD_DOCUMENT_SERIES_FAILED, ADD_DOCUMENT_SERIES_SUCCESS, DELETE_DOCUMENT_SERIES, DELETE_DOCUMENT_SERIES_FAILED, DELETE_DOCUMENT_SERIES_SUCCESS, EDIT_DOCUMENT_SERIES, EDIT_DOCUMENT_SERIES_FAILED, EDIT_DOCUMENT_SERIES_SUCCESS, GET_DOCUMENT_SERIES, GET_DOCUMENT_SERIES_FAILED, GET_DOCUMENT_SERIES_SUCCESS, UPDATE_LAST_DOCUMENT_NUMBER, UPDATE_LAST_DOCUMENT_NUMBER_FAILED, UPDATE_LAST_DOCUMENT_NUMBER_SUCCESS } from '../types/API/documentSeriesType';
import { CREATE_DOCUMENT, CREATE_DOCUMENT_FAILED, CREATE_DOCUMENT_SUCCESS, GET_DOCUMENTS, GET_DOCUMENTS_BY_ID, GET_DOCUMENTS_BY_ID_FAILED, GET_DOCUMENTS_BY_ID_SUCCESS, GET_DOCUMENTS_FAILED, GET_DOCUMENTS_SUCCESS } from "../types/API/documentsTypes";
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_FAILED, GET_NOTIFICATIONS_SUCCESS, NOTIFY, NOTIFY_FAILED, NOTIFY_SUCCESS } from "../types/API/notificationTypes";
import { GET_ACCOUNT_INFO, GET_ACCOUNT_INFO_FAILED, GET_ACCOUNT_INFO_SUCCESS, UPDATE_ACCOUNT_INFO, UPDATE_ACCOUNT_INFO_FAILED, UPDATE_ACCOUNT_INFO_SUCCESS } from "../types/API/accountInfoType";
import { STOCK_TRANSFER, STOCK_TRANSFER_FAILED, STOCK_TRANSFER_SUCCESS } from "../types/API/stockTransfer";
import { ADD_UOM, ADD_UOM_FAILED, ADD_UOM_SUCCESS, DELETE_UOM, DELETE_UOM_FAILED, DELETE_UOM_SUCCESS, EDIT_UOM, EDIT_UOM_FAILED, EDIT_UOM_SUCCESS, GET_UOM, GET_UOM_FAILED, GET_UOM_SUCCESS } from "../types/API/uomType";
import { ITEM_STOCK_TRANSFER_HISTORY, ITEM_STOCK_TRANSFER_HISTORY_FAILED, ITEM_STOCK_TRANSFER_HISTORY_SUCCESS } from "../types/API/itemStockTransferHistoryType";


function getApiWorker(endpoint) {
  var url = AppConstants.APP_API_URL + endpoint;
  return fetch(url, {
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    method: AppConstants.API_METHOD.GET,
  }).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      throw error;
    }
    return response.json();
  });
}

function postApiWorker(endpoint, requestBody, isMultipart = false) {
  const url = AppConstants.APP_API_URL + endpoint;

  // No need to manually set 'Content-Type' for multipart
  const headers = isMultipart
    ? {} // Browser will set it automatically for FormData
    : {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

  // Prepare body conditionally based on isMultipart
  const body = isMultipart ? requestBody : JSON.stringify(requestBody);

  return fetch(url, {
    headers: new Headers(headers),
    method: AppConstants.API_METHOD.POST,
    body: body,
  })
    .then((response) => {
      if (response.status === 409) {
        return response.json().then((errorResponse) => {
          const conflictError = new Error(
            errorResponse.message ||
              'Conflict: Resource already exists or another conflict occurred.'
          );
          conflictError.status = 409;
          conflictError.message = errorResponse.message;
          throw conflictError;
        });
      }

      if (!response.ok) {
        const error = new Error(`Error: ${response.statusText}`);
        error.status = response.status;
        throw error;
      }

      return response.json();
    });
}


function* login(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "authentication/login",
      requestBody.payload
    );
    yield put({
      type: LOGIN_USER_SUCCESS,
      payload: {
        responseData: {
          email: requestBody.payload.email,
          message: response.message,
          token: response.token,
          id: jwtDecode(response.token).userId,
          companyId: jwtDecode(response.token).companyId,
          username: jwtDecode(response.token).username,
          companyName: jwtDecode(response.token).companyName,
        },
      },
    });
  } catch (error) {
    yield put({ type: LOGIN_USER_FAILED, payload: { error: error } });
  }
}

function* signupUser(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "authentication/signup",
      requestBody.payload
    );
    console.log(response);
    yield put({
      type: SIGNUP_USER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: SIGNUP_USER_FAILED, payload: error });
  }
}

function* getTeams(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(postApiWorker, "teams", requestBody.payload);
    yield put({ type: GET_TEAMS_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_TEAMS_FAILED, payload: { error: error } });
  }
}

function* getItems(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(postApiWorker, "items", requestBody.payload);
    yield put({ type: GET_ITEMS_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_ITEMS_FAILED, payload: { error: error } });
  }
}

function* getDocuments(requestBody) {
  try {
    const response = yield call(postApiWorker, "documents", requestBody.payload);
    yield put({ type: GET_DOCUMENTS_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_DOCUMENTS_FAILED, payload: { error: error } });
  }
}

function* getDocumentById(requestBody) {
  try {
    const response = yield call(postApiWorker, "documents/getDocumentById", requestBody.payload);
    yield put({ type: GET_DOCUMENTS_BY_ID_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_DOCUMENTS_BY_ID_FAILED, payload: { error: error } });
  }
}

function* addItem(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "items/addItem",
      requestBody.payload
    );
    yield put({ type: ADD_ITEM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_ITEM_FAILED, payload: { error: error } });
  }
}

function* addBuyerSupplier(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "buyerSupplier/addbuyerSupplier",
      requestBody.payload
    );
    yield put({
      type: ADD_BUYER_SUPPLIER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: ADD_BUYER_SUPPLIER_FAILED, payload: { error: error } });
  }
}

function* getBuyerSupplier(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "buyerSupplier",
      requestBody.payload
    );
    yield put({
      type: GET_BUYER_SUPPLIER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_BUYER_SUPPLIER_FAILED, payload: { error: error } });
  }
}

function* deleteBuyerSupplier(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "buyerSupplier/deleteBuyerSupplier",
      requestBody.payload
    );
    yield put({
      type: DELETE_BUYER_SUPPLIER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({
      type: DELETE_BUYER_SUPPLIER_FAILED,
      payload: { error: error },
    });
  }
}

function* editItem(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "items/editItem",
      requestBody.payload
    );
    yield put({ type: EDIT_ITEM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: EDIT_ITEM_FAILED, payload: { error: error } });
  }
}

function* deleteItem(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "items/deleteItem",
      requestBody.payload
    );
    yield put({
      type: DELETE_ITEM_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_ITEM_FAILED, payload: { error: error } });
  }
}

function* bulkDeleteItem(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "items/deleteItems", 
      requestBody.payload
    );
    yield put({
      type: BULK_DELETE_ITEM_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: BULK_DELETE_ITEM_FAILED, payload: { error: error } });
  }
}

function* addTeam(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "teams/addTeam",
      requestBody.payload
    );
    yield put({ type: ADD_TEAM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_TEAM_FAILED, payload: { error: error } });
  }
}

function* editTeam(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "teams/editTeam",
      requestBody.payload
    );
    yield put({ type: EDIT_TEAM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: EDIT_TEAM_FAILED, payload: { error: error } });
  }
}

function* deleteTeam(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "teams/deleteTeam",
      requestBody.payload
    );
    yield put({
      type: DELETE_TEAM_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_TEAM_FAILED, payload: { error: error } });
  }
}

function* getUsers(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(postApiWorker, "users", requestBody.payload);
    yield put({ type: GET_USERS_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_USERS_FAILED, payload: { error: error } });
  }
}

function* addUser(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "users/addUser",
      requestBody.payload
    );
    yield put({ type: ADD_USER_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_USER_FAILED, payload: { error: error } });
  }
}

function* editUser(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "users/editUser",
      requestBody.payload
    );
    yield put({ type: EDIT_USER_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: EDIT_USER_FAILED, payload: { error: error } });
  }
}

function* deleteUser(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "users/deleteUser",
      requestBody.payload
    );
    yield put({
      type: DELETE_USER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_USER_FAILED, payload: { error: error } });
  }
}

function* getCategories(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      getApiWorker,
      "get_categories",
      requestBody.payload
    );
    yield put({
      type: GET_CATEGORIES_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_CATEGORIES_FAILED, payload: { error: error } });
  }
}

function* addBlog(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "blogs/addBlog",
      requestBody.payload
    );
    yield put({ type: ADD_BLOG_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_BLOG_FAILED, payload: { error: error } });
  }
}

function* editBlog(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "blogs/editBlog",
      requestBody.payload
    );
    yield put({ type: EDIT_BLOG_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: EDIT_BLOG_FAILED, payload: { error: error } });
  }
}

function* deleteBlog(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "blogs/deleteBlog",
      requestBody.payload
    );
    yield put({
      type: DELETE_BLOG_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_BLOG_FAILED, payload: { error: error } });
  }
}

function* getBlogById(requestBody) {
  console.log(requestBody.payload);
  try {
    const { id } = requestBody.payload;
    const response = yield call(
      getApiWorker,
      `blogs/${id}`,
      requestBody.payload.id
    );
    yield put({
      type: GET_BLOG_BY_ID_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_BLOG_BY_ID_FAILED, payload: { error: error } });
  }
}

function* getBlogs(requestBody) {
  try {
    const response = yield call(getApiWorker, "blogs", requestBody.payload);
    yield put({ type: GET_BLOGS_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_BLOGS_FAILED, payload: { error: error } });
  }
}

function* uploadImage(action) {
  console.log("Upload image action received:", action); 
  try {
    const response = yield call(postApiWorker, "uploads/uploads", action.payload, true); 
    yield put({ type: UPLOAD_IMAGE_SUCCESS, payload: { url: response.url }});
  } catch (error) {
    yield put({ type: UPLOAD_IMAGE_FAILED, payload: { error: error.message } });
  }
}

function* addStore(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(postApiWorker, "stores/addStore", requestBody.payload);
    yield put({ type: ADD_STORE_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_STORE_FAILED, payload: { error: error } });
  }
}

function* getStores(requestBody) {
  try {
    const response = yield call(postApiWorker, "stores", requestBody.payload);
    yield put({ type: GET_STORE_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_STORE_FAILED, payload: { error: error } });
  }
}

function* getStoreById(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "stores/id",
      requestBody.payload
    );
    yield put({
      type: GET_STORE_BY_ID_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_STORE_BY_ID_FAILED, payload: { error: error } });
  }
}

function* getStoreByItems(requestBody) {
  try {
    const response = yield call(postApiWorker, "stores/getStoresByItem", requestBody.payload);
    yield put({
      type: GET_STORE_BY_ITEMS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_STORE_BY_ITEMS_FAILED, payload: { error: error } });
  }
}

function* editStore(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "stores/editStore",
      requestBody.payload
    );
    yield put({
      type: EDIT_STORE_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: EDIT_STORE_FAILED, payload: { error: error } });
  }
}

function* deleteStore(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "stores/deleteStore",
      requestBody.payload
    );
    yield put({
      type: DELETE_STORE_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_STORE_FAILED, payload: { error: error } });
  }
}

function* addAddress(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "address/addAddress",
      requestBody.payload
    );
    yield put({
      type: ADD_ADDRESS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: ADD_ADDRESS_FAILED, payload: { error: error } });
  }
}

function* getAddress(requestBody) {
  try {
    const response = yield call(postApiWorker, "address", requestBody.payload);
    yield put({
      type: GET_ADDRESS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_ADDRESS_FAILED, payload: { error: error } });
  }
}

function* editAddress(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "address/editAddress",
      requestBody.payload
    );
    yield put({
      type: EDIT_ADDRESS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: EDIT_ADDRESS_FAILED, payload: { error: error } });
  }
}

function* deleteAddress(requestBody) {
    try {
        const response = yield call(postApiWorker, "address/deleteAddress", requestBody.payload);
        yield put({ type: DELETE_ADDRESS_SUCCESS, payload: { responseData: response} });
    }
    catch(error) {
        yield put({ type: DELETE_ADDRESS_FAILED, payload: {error : error} });
    }
}

function* setDefaultAddress(requestBody) {
    try {
        const response = yield call(postApiWorker, "address/setDefaultAddress", requestBody.payload);
        yield put({ type: SET_DEFAULT_ADDRESS_SUCCESS, payload: { responseData: response} });
    }
    catch(error) {
        yield put({ type: SET_DEFAULT_ADDRESS_FAILED, payload: {error : error} });
    }
}

function* addBlogComment(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "blogComments/addBlogComments",
      requestBody.payload
    );
    yield put({
      type: ADD_COMMENT_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: ADD_COMMENT_FAILED, payload: { error: error } });
  }
}

function* deleteBlogComment(requestBody) {
  try {
    console.log(requestBody);
    const response = yield call(
      postApiWorker,
      "blogComments/deleteBlogComment",
      requestBody.payload
    );
    yield put({
      type: DELETE_COMMENT_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_COMMENT_FAILED, payload: { error: error } });
  }
}

function* approveComments(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "blogComments/approveBlogComments",
      requestBody.payload
    );
    yield put({
      type: APPROVE_COMMENT_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: APPROVE_COMMENT_FAILED, payload: { error: error } });
  }
}

function* getApprovedBlogComments(requestBody) {
  console.log(requestBody);
  try {
    const response = yield call(
      postApiWorker,
      "blogComments",
      requestBody.payload
    );
    yield put({
      type: GET_APPROVE_BLOG_COMMENT_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({
      type: GET_APPROVE_BLOG_COMMENT_FAILED,
      payload: { error: error },
    });
  }
}

function* getBlogCommentstoBeApprove(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "blogComments/getCommentstoApprove",
      requestBody.payload
    );
    yield put({
      type: GET_BLOG_COMMENTS_TO_BE_APPROVED_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({
      type: GET_BLOG_COMMENTS_TO_BE_APPROVED_FAILED,
      payload: { error: error },
    });
  }
}

function* getAllBlogsApprovedComments(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "blogComments/getApprovedComments",
      requestBody.payload
    );
    yield put({
      type: GET_ALL_BLOGS_APPROVED_COMMENTS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({
      type: GET_ALL_BLOGS_APPROVED_COMMENTS_FAILED,
      payload: { error: error },
    });
  }
}

function* addDocumentSeries(requestBody) {
  console.log(requestBody.payload)
  try {
      const response = yield call(postApiWorker, "documentSeries/AddDocumentSeries", requestBody.payload);
      yield put({ type: ADD_DOCUMENT_SERIES_SUCCESS, payload: { responseData: response} });
  }
  catch(error) {
      yield put({ type: ADD_DOCUMENT_SERIES_FAILED, payload: {error : error} });
  }
}

function* getDocumentSeries(requestBody) {
  try {
      const response = yield call(postApiWorker, "documentSeries", requestBody.payload);
      yield put({ type: GET_DOCUMENT_SERIES_SUCCESS, payload: { responseData: response} });
  }
  catch(error) {
      yield put({ type: GET_DOCUMENT_SERIES_FAILED, payload: {error : error} });
  }
}

function* editDocumentSeries(requestBody) {
  try {
      const response = yield call(postApiWorker, "documentSeries/editDocumentSeries", requestBody.payload);
      yield put({ type: EDIT_DOCUMENT_SERIES_SUCCESS, payload: { responseData: response} });
  }
  catch(error) {
      yield put({ type: EDIT_DOCUMENT_SERIES_FAILED, payload: {error : error} });
  }
}

function* deleteDocumentSeries(requestBody) {
  try {
      const response = yield call(postApiWorker, "documentSeries/deleteDocumentSeries", requestBody.payload);
      yield put({ type: DELETE_DOCUMENT_SERIES_SUCCESS, payload: { responseData: response} });
  }
  catch(error) {
      yield put({ type: DELETE_DOCUMENT_SERIES_FAILED, payload: {error : error} });
  }
}

function* updateLastDocumentNumber(requestBody) {
  try {
      const response = yield call(postApiWorker, "documentSeries/updateLastDocumentNumber", requestBody.payload);
      yield put({ type: UPDATE_LAST_DOCUMENT_NUMBER_SUCCESS, payload: { responseData: response} });
  }
  catch(error) {
      yield put({ type: UPDATE_LAST_DOCUMENT_NUMBER_FAILED, payload: {error : error} });
  }
}

function* addBankDetail(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "accountDetails/addAccountDetails",
      requestBody.payload
    );
    yield put({
      type: ADD_BANK_DETAIL_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: ADD_BANK_DETAIL_FAILED, payload: { error: error } });
  }
}

function* getBankDetail(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "accountDetails",
      requestBody.payload
    );
    yield put({
      type: GET_BANK_DETAIL_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_BANK_DETAIL_FAILED, payload: { error: error } });
  }
}

function* editBankDetail(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "accountDetails/editAccountDetails",
      requestBody.payload
    );
    yield put({
      type: EDIT_BANK_DETAIL_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: EDIT_BANK_DETAIL_FAILED, payload: { error: error } });
  }
}

function* deleteBankDetail(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "accountDetails/deleteAccountDetails",
      requestBody.payload
    );
    yield put({
      type: DELETE_BANK_DETAIL_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_BANK_DETAIL_FAILED, payload: { error: error } });
  }
}

function* createDocument(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "documents/createDocument",
      requestBody.payload
    );
    yield put({
      type: CREATE_DOCUMENT_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: CREATE_DOCUMENT_FAILED, payload: { error: error } });
  }
}

function* notify(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "notification/notify",
      requestBody.payload
    );
    yield put({
      type: NOTIFY_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: NOTIFY_FAILED, payload: { error: error } });
  }
}

function* getNotifications(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "notification",
      requestBody.payload
    );
    yield put({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_FAILED, payload: { error: error } });
  }
}

function* getAccountInfo(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "getAccountInfo",
      requestBody.payload
    );
    yield put({
      type: GET_ACCOUNT_INFO_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: GET_ACCOUNT_INFO_FAILED, payload: { error: error } });
  }
}

function* updateAccountInfo(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "updateAccountInfo",
      requestBody.payload
    );
    yield put({
      type: UPDATE_ACCOUNT_INFO_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: UPDATE_ACCOUNT_INFO_FAILED, payload: { error: error } });
  }
}

function* stockTransfer(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "stores/stockTransfer",
      requestBody.payload
    );
    yield put({
      type: STOCK_TRANSFER_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: STOCK_TRANSFER_FAILED, payload: { error: error } });
  }
}

function* getUom(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(postApiWorker, "uom", requestBody.payload);
    yield put({ type: GET_UOM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: GET_UOM_FAILED, payload: { error: error } });
  }
}

function* addUom(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "uom/addUOM",
      requestBody.payload
    );
    yield put({ type: ADD_UOM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: ADD_UOM_FAILED, payload: { error: error } });
  }
}

function* editUom(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "uom/editUOM",
      requestBody.payload
    );
    yield put({ type: EDIT_UOM_SUCCESS, payload: { responseData: response } });
  } catch (error) {
    yield put({ type: EDIT_UOM_FAILED, payload: { error: error } });
  }
}

function* deleteUom(requestBody) {
  console.log(requestBody.payload);
  try {
    const response = yield call(
      postApiWorker,
      "uom/deleteUOM",
      requestBody.payload
    );
    yield put({
      type: DELETE_UOM_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: DELETE_UOM_FAILED, payload: { error: error } });
  }
}

function* itemStockTransferHistory(requestBody) {
  try {
    const response = yield call(
      postApiWorker,
      "stores/getItemStockTransferHistory",
      requestBody.payload
    );
    yield put({
      type: ITEM_STOCK_TRANSFER_HISTORY_SUCCESS,
      payload: { responseData: response },
    });
  } catch (error) {
    yield put({ type: ITEM_STOCK_TRANSFER_HISTORY_FAILED, payload: { error: error } });
  }
}

function* easeMarginSagas() {
  yield takeEvery(LOGIN_USER, login);
  yield takeEvery(SIGNUP_USER, signupUser);

  yield takeEvery(ADD_BLOG, addBlog);
  yield takeEvery(EDIT_BLOG, editBlog);
  yield takeEvery(GET_BLOG_BY_ID, getBlogById);
  yield takeEvery(GET_BLOGS, getBlogs);
  yield takeEvery(DELETE_BLOG, deleteBlog);

  yield takeEvery(ADD_STORE, addStore);
  yield takeEvery(GET_STORE, getStores);
  yield takeEvery(GET_STORE_BY_ID, getStoreById);
  yield takeEvery(GET_STORE_BY_ITEMS, getStoreByItems);
  yield takeEvery(EDIT_STORE, editStore);
  yield takeEvery(DELETE_STORE, deleteStore);

  yield takeEvery(ADD_ADDRESS, addAddress);
  yield takeEvery(GET_ADDRESS, getAddress);
  yield takeEvery(EDIT_ADDRESS, editAddress);
  yield takeEvery(DELETE_ADDRESS, deleteAddress);
  yield takeEvery(SET_DEFAULT_ADDRESS, setDefaultAddress);

  yield takeEvery(GET_CATEGORIES, getCategories);
  yield takeEvery(UPLOAD_IMAGE, uploadImage);

  yield takeEvery(GET_TEAMS, getTeams);
  yield takeEvery(ADD_TEAM, addTeam);
  yield takeEvery(EDIT_TEAM, editTeam);
  yield takeEvery(DELETE_TEAM, deleteTeam);

  yield takeEvery(GET_UOM, getUom);
  yield takeEvery(ADD_UOM, addUom);
  yield takeEvery(EDIT_UOM, editUom);
  yield takeEvery(DELETE_UOM, deleteUom);

  yield takeEvery(GET_USERS, getUsers);
  yield takeEvery(ADD_USER, addUser);
  yield takeEvery(EDIT_USER, editUser);
  yield takeEvery(DELETE_USER, deleteUser);

  yield takeEvery(ADD_COMMENT, addBlogComment);
  yield takeEvery(DELETE_COMMENT, deleteBlogComment);
  yield takeEvery(APPROVE_COMMENT, approveComments);
  yield takeEvery(GET_APPROVE_BLOG_COMMENT, getApprovedBlogComments);
  yield takeEvery(GET_BLOG_COMMENTS_TO_BE_APPROVED, getBlogCommentstoBeApprove);
  yield takeEvery(GET_ALL_BLOGS_APPROVED_COMMENTS, getAllBlogsApprovedComments);

  yield takeEvery(GET_ITEMS, getItems);
  yield takeEvery(ADD_ITEM, addItem);
  yield takeEvery(EDIT_ITEM, editItem);
  yield takeEvery(DELETE_ITEM, deleteItem);
  yield takeEvery(BULK_DELETE_ITEM, bulkDeleteItem);

  yield takeEvery(ADD_BUYER_SUPPLIER, addBuyerSupplier);
  yield takeEvery(GET_BUYER_SUPPLIER, getBuyerSupplier);
  yield takeEvery(DELETE_BUYER_SUPPLIER, deleteBuyerSupplier);

  yield takeEvery(GET_DOCUMENT_SERIES, getDocumentSeries);
  yield takeEvery(ADD_DOCUMENT_SERIES, addDocumentSeries);
  yield takeEvery(EDIT_DOCUMENT_SERIES, editDocumentSeries);
  yield takeEvery(DELETE_DOCUMENT_SERIES, deleteDocumentSeries);
  yield takeEvery(UPDATE_LAST_DOCUMENT_NUMBER, updateLastDocumentNumber);

  yield takeEvery(GET_BANK_DETAIL, getBankDetail);
  yield takeEvery(ADD_BANK_DETAIL, addBankDetail);
  yield takeEvery(EDIT_BANK_DETAIL, editBankDetail);
  yield takeEvery(DELETE_BANK_DETAIL, deleteBankDetail);

  yield takeEvery(GET_DOCUMENTS, getDocuments);
  yield takeEvery(GET_DOCUMENTS_BY_ID, getDocumentById);

  yield takeEvery(CREATE_DOCUMENT, createDocument);

  yield takeEvery(NOTIFY, notify);
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);

  yield takeEvery(GET_ACCOUNT_INFO, getAccountInfo);
  yield takeEvery(UPDATE_ACCOUNT_INFO, updateAccountInfo);

  yield takeEvery(STOCK_TRANSFER, stockTransfer);
  yield takeEvery(ITEM_STOCK_TRANSFER_HISTORY, itemStockTransferHistory);
}

export default easeMarginSagas;
