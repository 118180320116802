import * as type from '../../types/API/teamTypes';
import { IAction } from '../userMetadataReducer';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const teamsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_TEAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addTeamsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_TEAM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_TEAM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editTeamsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_TEAM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_TEAM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteTeamsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_TEAM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_TEAM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
