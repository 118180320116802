import { Breadcrumb, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import Loader from "../../Home/Loader/Loader";
import DashboardCounters from "./DashboardCounters";
import { Chart } from "react-charts";
import { Row, Col, Card, Statistic, Table, Typography, Button } from 'antd';
import { UserOutlined, DollarOutlined, ShoppingCartOutlined, StockOutlined } from '@ant-design/icons';
import { calc } from "antd/es/theme/internal";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';




// const { Titles } = Typography;
// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// Define the type for the sales report data
interface SalesReport {
    itemName: string;
    storeName: string;
    quantitySold: number;
    totalSales: number;
    dateRange: string;
    salesByStore: string[];
    unitPrice: number;
    discountApplied: string;
}

const salesData: SalesReport[] = [
    {
        itemName: 'Item A',
        storeName: 'Store 1',
        quantitySold: 150,
        totalSales: 3000,
        dateRange: '01/11/2024 - 20/11/2024',
        salesByStore: ['Store 1', 'Store 2'],
        unitPrice: 20,
        discountApplied: '10%',
    },
    {
        itemName: 'Item B',
        storeName: 'Store 2',
        quantitySold: 120,
        totalSales: 2400,
        dateRange: '01/11/2024 - 20/11/2024',
        salesByStore: ['Store 2'],
        unitPrice: 25,
        discountApplied: '5%',
    },
    {
        itemName: 'Item C',
        storeName: 'Store 3',
        quantitySold: 90,
        totalSales: 1800,
        dateRange: '01/11/2024 - 20/11/2024',
        salesByStore: ['Store 3', 'Store 4'],
        unitPrice: 20,
        discountApplied: 'None',
    },
    {
        itemName: 'Item D',
        storeName: 'Store 4',
        quantitySold: 200,
        totalSales: 4000,
        dateRange: '01/11/2024 - 20/11/2024',
        salesByStore: ['Store 4'],
        unitPrice: 25,
        discountApplied: '15%',
    },
    {
        itemName: 'Item E',
        storeName: 'Store 1',
        quantitySold: 50,
        totalSales: 1000,
        dateRange: '01/11/2024 - 20/11/2024',
        salesByStore: ['Store 1'],
        unitPrice: 20,
        discountApplied: '20%',
    },
];

// Sample Data
const topSellingProducts = [
    {
        productName: 'Product A',
        quantitySold: 150,
        unitPrice: 20,
        totalRevenue: 3000,
        category: 'Category 1',
    },
    {
        productName: 'Product B',
        quantitySold: 120,
        unitPrice: 25,
        totalRevenue: 3000,
        category: 'Category 2',
    },
    {
        productName: 'Product C',
        quantitySold: 200,
        unitPrice: 15,
        totalRevenue: 3000,
        category: 'Category 1',
    },
    {
        productName: 'Product D',
        quantitySold: 90,
        unitPrice: 50,
        totalRevenue: 4500,
        category: 'Category 3',
    },
    {
        productName: 'Product E',
        quantitySold: 110,
        unitPrice: 30,
        totalRevenue: 3300,
        category: 'Category 2',
    },
];

// Table columns definition
const columns = [
    {
        title: 'Product Name',
        dataIndex: 'productName',
        key: 'productName',
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
    },
    {
        title: 'Quantity Sold',
        dataIndex: 'quantitySold',
        key: 'quantitySold',
    },
    {
        title: 'Unit Price (USD)',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text: number) => `$${text.toFixed(2)}`,
    },
    {
        title: 'Total Revenue (USD)',
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        render: (text: number) => `$${text.toFixed(2)}`,
    },
];

export default function Dashboard() {
    const [loading, setLoading] = useState(true);
    // Sample data for counters and tables
    const [dashboardData, setDashboardData] = useState({
        totalSales: 120000,
        totalInventory: 5000,
        totalCustomers: 350,
        totalItems: 150,
    });

    const [recentSales, setRecentSales] = useState([
        { key: 1, orderId: 'ORD001', customer: 'John Doe', amount: '$5000', date: '2024-11-21' },
        { key: 2, orderId: 'ORD002', customer: 'Jane Smith', amount: '$3000', date: '2024-11-20' },
        { key: 3, orderId: 'ORD003', customer: 'Alice Johnson', amount: '$4500', date: '2024-11-18' },
    ]);

    // Columns for the Recent Sales Table
    const salesColumns = [
        {
            title: 'Order ID',
            dataIndex: 'orderId',
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
    ];

    // Define columns for the table
    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'itemName',
            key: 'itemName',
        },
        {
            title: 'Store Name',
            dataIndex: 'storeName',
            key: 'storeName',
        },
        {
            title: 'Quantity Sold',
            dataIndex: 'quantitySold',
            key: 'quantitySold',
        },
        {
            title: 'Total Sales (USD)',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: (text: number) => `$${text.toFixed(2)}`,
        },
        {
            title: 'Date Range',
            dataIndex: 'dateRange',
            key: 'dateRange',
        },
        {
            title: 'Sales by Store',
            dataIndex: 'salesByStore',
            key: 'salesByStore',
            render: (stores: string[]) => stores.join(', '),
        },
        {
            title: 'Unit Price (USD)',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (text: number) => `$${text.toFixed(2)}`,
        },
        {
            title: 'Discount Applied',
            dataIndex: 'discountApplied',
            key: 'discountApplied',
        },
    ];

    // Sample Data
    const topSellingProducts = [
        {
            productName: 'Product A',
            quantitySold: 150,
            unitPrice: 20,
            totalRevenue: 3000,
            category: 'Category 1',
        },
        {
            productName: 'Product B',
            quantitySold: 120,
            unitPrice: 25,
            totalRevenue: 3000,
            category: 'Category 2',
        },
        {
            productName: 'Product C',
            quantitySold: 200,
            unitPrice: 15,
            totalRevenue: 3000,
            category: 'Category 1',
        },
        {
            productName: 'Product D',
            quantitySold: 90,
            unitPrice: 50,
            totalRevenue: 4500,
            category: 'Category 3',
        },
        {
            productName: 'Product E',
            quantitySold: 110,
            unitPrice: 30,
            totalRevenue: 3300,
            category: 'Category 2',
        },
    ];

    // Table columns definition
    const itemcolumns = [
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Quantity Sold',
            dataIndex: 'quantitySold',
            key: 'quantitySold',
        },
        {
            title: 'Unit Price (USD)',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (text: number) => `$${text.toFixed(2)}`,
        },
        {
            title: 'Total Revenue (USD)',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            render: (text: number) => `$${text.toFixed(2)}`,
        },
    ];

    // Sample Revenue Data
    const revenueData = [
        { month: 'January', revenue: 5000 },
        { month: 'February', revenue: 6000 },
        { month: 'March', revenue: 7000 },
        { month: 'April', revenue: 8500 },
        { month: 'May', revenue: 9000 },
        { month: 'June', revenue: 9500 },
        { month: 'July', revenue: 10000 },
        { month: 'August', revenue: 11000 },
        { month: 'September', revenue: 10500 },
        { month: 'October', revenue: 12000 },
        { month: 'November', revenue: 13000 },
        { month: 'December', revenue: 14000 },
    ];

    // Chart Data
    const chartData = {
        labels: revenueData.map((data) => data.month),
        datasets: [
            {
                label: 'Revenue (USD)',
                data: revenueData.map((data) => data.revenue),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Chart Options
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Monthly Revenue Overview',
            },
        },
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            },
        },
    };


    useEffect(() => {
        setLoading(false);
    }, []);

    return <>
        <Loader loading={loading}></Loader>
        <Layout
            style={{
                padding:'15px',
                overflow: 'auto',
                marginBottom: '15px'
            }}
        >

            <Content
                style={{
                    margin: 0,
                }}
            >
                <div>
                    {/* Welcome Message */}
                    <h5 style={{ color: '#1890ff' }}>Welcome Back, Admin!</h5>
                    <p style={{ color: '#666', fontSize: '16px' }}>Your dashboard gives you a quick overview of the key metrics of your business.</p>

                    {/* Counters Row */}
                    <Row gutter={16} style={{ marginBottom: '20px' }}>
                        <Col span={6}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#e6f7ff',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Total Sales"
                                    value={dashboardData.totalSales}
                                    precision={2}
                                    prefix={<DollarOutlined style={{ color: '#52c41a' }} />}
                                    suffix="USD"
                                    valueStyle={{ color: '#52c41a', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#fffbe6',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Total Inventory"
                                    value={dashboardData.totalInventory}
                                    prefix={<StockOutlined style={{ color: '#faad14' }} />}
                                    valueStyle={{ color: '#faad14', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#fff0f6',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Total Customers"
                                    value={dashboardData.totalCustomers}
                                    prefix={<UserOutlined style={{ color: '#eb2f96' }} />}
                                    valueStyle={{ color: '#eb2f96', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card
                                bordered={false}
                                style={{
                                    backgroundColor: '#f0f5ff',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                }}
                            >
                                <Statistic
                                    title="Total Items"
                                    value={dashboardData.totalItems}
                                    prefix={<ShoppingCartOutlined style={{ color: '#1890ff' }} />}
                                    valueStyle={{ color: '#1890ff', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <div>
                        {/* <Titles level={2} style={{ color: '#1890ff' }}>Revenue Overview</Titles> */}
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card
                                    bordered={false}
                                    style={{
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Bar data={chartData} options={chartOptions}/>
                                </Card>
                            </Col>
                            <Col span={12}>
                                    <Table
                                        dataSource={topSellingProducts}
                                        columns={itemcolumns}
                                        rowKey="productName"
                                        pagination={false}
                                        style={{
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            minHeight: '365px'
                                        }}
                                    />
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-20">
                        <Row gutter={16}>
                            <Col span={24}>
                                <Table
                                    dataSource={salesData}
                                    columns={columns}
                                    rowKey="itemName"
                                    pagination={false}
                                    style={{
                                        backgroundColor: '#fff',
                                        borderRadius: '5px',
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                        minHeight: '300px'
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </Layout>
    </>;
}
