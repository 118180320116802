import * as type from "../../types/API/bankDetailType";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const bankDetailReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_BANK_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case type.GET_BANK_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_BANK_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addBankDetailReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_BANK_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_BANK_DETAIL_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_BANK_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editBankDetailReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.EDIT_BANK_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_BANK_DETAIL_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_BANK_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteBankDetailReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_BANK_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_BANK_DETAIL_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_BANK_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
