import { Input, Select, Table, Button, Popconfirm, Card, Tooltip } from "antd";
import { SearchOutlined, ReloadOutlined, DeleteOutlined} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
import { AppConstants } from "../../../Appconstants";
import "./DocumentItemGrid.css";
import CustomMetaDataModal from "../CustomMetaDataModal";
import { DataSource, ISalesQuotationProps, Item, CustomField } from "./TableMetaDataConstant";
import { ColumnType } from "antd/es/table";
import { ADD_ITEM, CUSTOM_FIELD, DISCOUNT, SUCCESSIVE_DISCOUNT} from "./TableMetaDataConstant"; 
import { setDocumentItemGridData } from "../../../redux/actions/UI/createDocument";

const { Option } = Select;

const DocumentItemGrid: React.FC<ISalesQuotationProps> = ({
  dataSource,
  getItems,
  companyId,
  itemsData,
  setDocumentItemGridData,
  documentItemValidationData
}) => {
  const prevPropsRef = useRef<any>();
  const [items, setItems] = useState<Item[]>([]);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [category, setCategory] = useState<string>("");
  const [mode, setMode] = useState<"form" | "table">("form");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [additionalColumns, setAdditionalColumns] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customColumns, setCustomColumns] = useState<CustomField[]>([]);
  const [keyCounter, setKeyCounter] = useState<number>(2);
  const [docValidItem, setDocValidItem] = useState(documentItemValidationData);

  useEffect(() => {
    if(prevPropsRef?.current?.documentItemValidationData !== documentItemValidationData) {
      console.log(documentItemValidationData);
      setDocValidItem(documentItemValidationData);
    }
    prevPropsRef.current = {
      documentItemValidationData
    };
  }, [documentItemValidationData]);

  useEffect(() => {
    setDocumentItemGridData([
      {
        key: 1,
        itemId: "",
        itemName: "",
        UOM: "",
        HSN: "",
        quantity: 0,
        price: 0,
        discount1: 0,
        discount2: 0,
        totalDiscount: 0,
        taxType: "",
        tax: 0,
        totalTax: 0,
        totalBeforeTax: 0,
        totalAfterTax: 0,
        length: 0,
        breadth: 0,
        height: 0,
      },
    ]);
  }, []);

  useEffect(() => {
    if (companyId) {
      getItems({ companyId: Number(companyId) });
    }
  }, [getItems]);

  useEffect(() => {
    if (itemsData) {
      setItems(itemsData);
    }
  }, [itemsData]);

  useEffect(() => {
    const savedFieldsKey = `customFields_${mode}_${category}`;
    const savedFields = localStorage.getItem(savedFieldsKey);
    if (savedFields) {
      setCustomFields(JSON.parse(savedFields));
    }
  }, [category, mode]);

  const getSelectedItemIds = () => {
    return dataSource
      ?.map((item: any) => item.itemId)
      .filter((id: string) => id !== "");
  };

  const columns: ColumnType<DataSource>[] = [
    {
      title: "Item ID",
      dataIndex: "itemId",
      fixed: "left",
      render: (text: string, record: DataSource, index: number) => {
        const selectedIds = getSelectedItemIds();
        return (
          <Select
            className="itemGridFields-sm"
            value={record.itemId}
            showSearch
            placeholder="Select Item"
            onChange={(value) => handleSelectChange(value, index)}
            filterOption={(input, option) => {
              const item = itemsData?.find(
                (item: Item) => item.itemId === option?.value
              );
              if (item) {
                const itemIdStr = item.itemId.toString();
                return (
                  itemIdStr.includes(input) ||
                  item.itemName.toLowerCase().includes(input.toLowerCase())
                );
              }
              return false;
            }}
          >
            {items
              .filter((item) => !selectedIds.includes(item.itemId))
              .map((item) => (
                <Option key={item.itemId} value={item.itemId}>
                  {item.itemId}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      fixed: "left",
      render: (text: string, record: DataSource, index: number) => {
        const selectedItemIds = getSelectedItemIds();
        return (
          <Select
            className="itemGridFields-md"
            value={record.itemName}
            showSearch
            placeholder="Select Item Name"
            onChange={(value) => handleSelectChange(value, index)}
            filterOption={(input, option) => {
              const item = itemsData?.find(
                (item: Item) => item.itemId === option?.value
              );
              return item
                ? item.itemName.toLowerCase().includes(input.toLowerCase())
                : false;
            }}
          >
            {items
              .filter(
                (item) =>
                  !selectedItemIds.includes(item.itemId) &&
                  !dataSource?.some(
                    (row: any) => row.itemName === item.itemName
                  )
              )
              .map((item) => (
                <Option key={item.itemId} value={item.itemId}>
                  {item.itemName}
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "HSN/SAC",
      dataIndex: "HSN",
      key: "HSN",
      render: (text: any) => <Input className="itemGridFields-sm" value={text} disabled />,
    },
    {
      title: "UOM",
      dataIndex: "UOM",
      key: "UOM",
      render: (text: string, record: DataSource) => (
        <Input className="itemGridFields-sm" value={text} disabled />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: number, record: DataSource, index: number) => (
        <Input
          className="itemGridFields-sm"
          value={text === 0 ? "" : text}
          onChange={(e) => handleQuantityChange(e.target.value, index)}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text: number, record: DataSource, index: number) => (
        <Input
          className="itemGridFields-sm"
          value={text === 0 ? "" : text}
          onChange={(e) => handlePriceChange(e.target.value, index)}
        />
      ),
    },
    ...(additionalColumns === "discount1"
      ? [
        {
          title: "Discount %",
          dataIndex: "discount1",
          key: "discount1",
          render: (text: number, record: DataSource, index: number) => (
            <Input
              className="itemGridFields-sm"
              value={text === 0 ? "" : text}
              onChange={(e) => {
                const value = e.target.value;

                if (value === "") {
                  handleDiscountChange(0, index, "discount1");
                } else {
                  const parsedValue = isNaN(parseFloat(value))
                    ? 0
                    : parseFloat(parseFloat(value)?.toFixed(2));
                  handleDiscountChange(parsedValue, index, "discount1");
                }
              }}
            />
          ),
        },
      ]
      : additionalColumns === "successiveDiscount"
        ? [
          {
            title: "Discount %",
            dataIndex: "discount1",
            key: "discount1",
            render: (text: number, record: DataSource, index: number) => (
              <Input
                className="itemGridFields-sm"
                value={text === 0 ? "" : text}
                onChange={(e) => {
                  const value = e.target.value;

                  if (value === "") {
                    handleDiscountChange(0, index, "discount1");
                  } else {
                    const parsedValue = isNaN(parseFloat(value))
                      ? 0
                      : parseFloat(parseFloat(value)?.toFixed(2));
                    handleDiscountChange(parsedValue, index, "discount1");
                  }
                }}
              />
            ),
          },
          {
            title: "Add. Discount %",
            dataIndex: "discount2",
            key: "discount2",
            render: (text: number, record: DataSource, index: number) => (
              <Input
                className="itemGridFields-sm"
                value={text === 0 ? "" : text}
                onChange={(e) => {
                  const value = e.target.value;

                  if (value === "") {
                    handleDiscountChange(0, index, "discount2");
                  } else {
                    const parsedValue = isNaN(parseFloat(value))
                      ? 0
                      : parseFloat(parseFloat(value)?.toFixed(2));
                    handleDiscountChange(parsedValue, index, "discount2");
                  }
                }}
              />
            ),
          },
          {
            title: "Total Discount %",
            dataIndex: "totalDiscount",
            key: "totalDiscount",
            render: (text: number, record: DataSource, index: number) => (
              <Input
                className="itemGridFields-sm"
                value={text === 0 ? "" : text?.toFixed(2)}
                disabled
              />
            ),
          },
        ]
        : []),
    {
      title: "Tax Type",
      dataIndex: "taxType",
      key: "taxType",
      render: (text: number) => (
        <Input className="itemGridFields-sm" value={text} disabled />
      ),
    },
    {
      title: "Tax %",
      dataIndex: "tax",
      key: "tax",
      render: (text: number, record: DataSource, index: number) => (
        <Select
          value={text === 0 ? undefined : text}
          className="itemGridFields-sm"
          onChange={(value) => handleTaxChange(value, index)}
        >
          <Option value={0}>0%</Option>
          <Option value={0.1}>0.1%</Option>
          <Option value={0.25}>0.25%</Option>
          <Option value={3}>3%</Option>
          <Option value={5}>5%</Option>
          <Option value={6}>6%</Option>
          <Option value={12}>12%</Option>
          <Option value={18}>18%</Option>
          <Option value={28}>28%</Option>
        </Select>
      ),
    },
    {
      title: "Total Tax",
      dataIndex: "totalTax",
      key: "totalTax",
      render: (text: any) => (
        <Input
          className="itemGridFields-sm"
          value={text === 0 ? "" : Number(text)?.toFixed(2)}
          disabled
        />
      ),
    },
    {
      title: "Total Before Tax",
      dataIndex: "totalBeforeTax",
      key: "totalBeforeTax",
      render: (text: number) => (
        <Input
          className="itemGridFields-sm"
          value={text === 0 ? "" : Number(text)?.toFixed(2)}
          disabled
        />
      ),
    },
    {
      title: "Total After Tax",
      dataIndex: "totalAfterTax",
      key: "totalAfterTax",
      render: (text: any) => (
        <Input
          className="itemGridFields-sm"
          value={text === 0 ? "" : Number(text)?.toFixed(2)}
          disabled
        />
      ),
    },
    ...customColumns.map((customField) => ({
      title: customField.label,
      dataIndex: customField.key,
      render: (text: any, record: DataSource, index: number) => {
        switch (customField.fieldType) {
          case "text":
            return (
              <Input
                value={record[customField.key] || customField.defaultValue}
              />
            );
          case "integer":
            return (
              <Input
                type="number"
                value={record[customField.key] || customField.defaultValue}
              />
            );
          case "select":
            return (
              <Select
                value={record[customField.key] || customField.defaultValue}
              >
                {customField.options?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            );
          case "datetime":
            return (
              <Input
                type="datetime-local"
                value={record[customField.key] || customField.defaultValue}
              />
            );
          default:
            return null;
        }
      },
    })),
    {
      title: "Actions",
      fixed: "right",
      render: (text: string, record: DataSource, index: number) => {
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Refresh">
              <span className="actionIcons">
                <ReloadOutlined onClick={() => handleResetField(index)} />
              </span>
            </Tooltip>
            {dataSource?.length > 1 && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure you want to delete this row?"
                  onConfirm={() => handleDelete(index)}
                  okText="Yes"
                  cancelText="No"
                >
                  <span className="actionIcons">
                    <DeleteOutlined />
                  </span>
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  const handleSaveCustomColumns = (fields: CustomField[]) => {
    setCustomColumns(fields);
    const savedFieldsKey = `customFields_${mode}_${category}`;
    localStorage.setItem(savedFieldsKey, JSON.stringify(fields));
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = (value: string, index: number) => {
    const selectedItem = itemsData?.find((item: Item) => item.itemId === value);
    if (selectedItem) {
      const UOM = selectedItem.metricsUnit;
        // selectedItem.UOM ===
        //   AppConstants.ITEM_METRICS.KGS.ITEM_METRIC_ID
        //   ? AppConstants.ITEM_METRICS.KGS.ITEM_METRIC
        //   : selectedItem.UOM ===
        //     AppConstants.ITEM_METRICS.UNITS.ITEM_METRIC_ID
        //     ? AppConstants.ITEM_METRICS.UNITS.ITEM_METRIC
        //     : AppConstants.ITEM_METRICS.METERS.ITEM_METRIC;

      const taxType =
        selectedItem.taxType ===
          AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX_ID
          ? AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX
          : AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX;

      const tax = parseFloat(selectedItem.tax) || 0;
      let newData: any = [...dataSource];
      const totals = calculateTotal(selectedItem.price, 1, taxType, tax);

      newData[index] = {
        ...newData[index],
        itemId: selectedItem.itemId,
        itemName: selectedItem.itemName,
        UOM: UOM,
        HSN: selectedItem.HSNCode,
        price: selectedItem.price,
        taxType: taxType,
        tax: tax,
        totalTax: totals.totalTax,
        quantity: 1,
        totalBeforeTax: totals.totalBeforeTax,
        totalAfterTax: totals.totalAfterTax,
      };
      setDocumentItemGridData(newData);
    }
  };

  const handleQuantityChange = (value: string, index: number) => {
    const quantity = parseInt(value) || 0;
    let newData: any = [...dataSource];
    const totals = calculateTotal(
      newData[index].price,
      quantity,
      newData[index].taxType,
      newData[index].tax, 
    );

    newData[index] = {
      ...newData[index],
      quantity: quantity,
      totalTax: totals.totalTax,
      totalBeforeTax: totals.totalBeforeTax,
      totalAfterTax: totals.totalAfterTax,
    };
    console.log(newData[index])
    setDocumentItemGridData(newData);
  };

  const calculateTotal = (
    price: number,
    quantity: number,
    taxType: string,
    taxRate: number = 0,
    discount1: number = 0,
    discount2: number = 0
  ) => {
    let subtotal = price * quantity;
    let totalTax = 0;
    let totalBeforeTax = subtotal;
    let totalAfterTax = subtotal;
    let totalDiscount = 0;

    // Option 1: Calculate Discount 1
    if (
      additionalColumns === "discount1" &&
      discount1 > 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX
    ) {
      totalTax = subtotal * (taxRate / 100);
      totalBeforeTax = (subtotal - totalTax) * (1 - discount1 / 100);
      totalAfterTax = totalBeforeTax + totalTax;
    }

    if (
      additionalColumns === "discount1" &&
      discount1 > 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX
    ) {
      totalTax = subtotal * (taxRate / 100);
      totalBeforeTax = subtotal * (1 - discount1 / 100);
      totalAfterTax = totalBeforeTax + totalTax;
    }

    // Option 2: Calculate Successive Discounts
    if (
      additionalColumns === "successiveDiscount" &&
      discount1 > 0 &&
      discount2 > 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX
    ) {
      const discountPercent =
        discount1 / 100 +
        discount2 / 100 -
        (discount1 / 100) * (discount2 / 100);
      totalDiscount = discountPercent * 100;
      totalTax = subtotal * (taxRate / 100);

      totalBeforeTax = (subtotal - totalTax) * (1 - discountPercent);
      totalAfterTax = totalBeforeTax + totalTax;
    }

    if (
      additionalColumns === "successiveDiscount" &&
      discount1 > 0 &&
      discount2 > 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX
    ) {
      const discountPercent =
        discount1 / 100 +
        discount2 / 100 -
        (discount1 / 100) * (discount2 / 100);

      totalDiscount = discountPercent * 100;
      totalTax = subtotal * (taxRate / 100);

      // Apply discounts directly to subtotal for exclusive tax
      totalBeforeTax = subtotal * (1 - discountPercent);
      totalAfterTax = totalBeforeTax + totalTax;
    }

    // No Discounts
    if (
      additionalColumns !== "discount1" &&
      additionalColumns !== "successiveDiscount" &&
      discount1 === 0 &&
      discount2 === 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.EXCLUSIVE.ITEM_TAX
    ) {
      totalTax = subtotal * (taxRate / 100);
      totalBeforeTax = subtotal;
      totalAfterTax = subtotal + totalTax;
    }

    if (
      additionalColumns !== "discount1" &&
      additionalColumns !== "successiveDiscount" &&
      discount1 === 0 &&
      discount2 === 0 &&
      taxType === AppConstants.ITEM_TAX_TYPES.INCLUSIVE.ITEM_TAX
    ) {
      totalTax = subtotal * (taxRate / 100);
      totalBeforeTax = subtotal - totalTax;
      totalAfterTax = subtotal;
    }

    return {
      totalBeforeTax,
      totalTax,
      totalAfterTax,
      totalDiscount,
    };
  };

  const handleDiscountChange = (value: number, index: number, field: string) => {
    let newData: any = [...dataSource];
 
    newData[index] = {...newData[index], [field]: value};

    const price = newData[index].price;
    const quantity = newData[index].quantity;
    const taxType = newData[index].taxType;
    const tax = newData[index].tax || 0;
    const discount1 = newData[index].discount1 || 0;
    const discount2 = newData[index].discount2 || 0;

    const totals = calculateTotal(price, quantity, taxType, tax, discount1, discount2);
    console.log(totals)

    newData[index] = {
      ...newData[index], 
      totalTax: totals.totalTax,
      totalBeforeTax: totals.totalBeforeTax,
      totalAfterTax: totals.totalAfterTax,
      totalDiscount: totals.totalDiscount,
    };
    setDocumentItemGridData(newData);
  };

  const handleDelete = (index: number) => {
    const newData = dataSource?.filter((elem: any, i: number) => i !== index);
    setDocumentItemGridData(newData);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredDataSource = Array.isArray(dataSource)
    ? dataSource.filter((item: any) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        String(item.itemId).toLowerCase().includes(searchTermLower) ||
        item.itemName.toLowerCase().includes(searchTermLower)
      );
    })
    : [];

  const handlePriceChange = (value: string, index: number) => {
    const price = parseFloat(value) || 0;
    let newData: any = [...dataSource];
    const quantity = newData[index].quantity;
    const taxType = newData[index].taxType;
    const tax = newData[index].tax || 0;

    const totals = calculateTotal(price, quantity, taxType, tax);

    newData[index] = {
      ...newData[index],
      price: price,
      totalTax: totals.totalTax,
      totalBeforeTax: totals.totalBeforeTax,
      totalAfterTax: totals.totalAfterTax,
    };

    setDocumentItemGridData(newData);
  };

  const handleAdditionalColumnsChange = (value: string) => {
    setAdditionalColumns(value);
  };

  // changes for Tax %
  const handleTaxChange = (value: number, index: number) => {
    const tax = value || 0;
    let newData: any = [...dataSource];

    const price = newData[index].price;
    const quantity = newData[index].quantity;
    const taxType = newData[index].taxType;
    const discount1 = newData[index].discount1 || 0;
    const discount2 = newData[index].discount2 || 0;

    const totals = calculateTotal(price, quantity, taxType, tax, discount1, discount2);
    console.log(totals)

    newData[index] = {
      ...newData[index],
      tax: tax,
      totalTax: totals.totalTax,
      totalBeforeTax: totals.totalBeforeTax,
      totalAfterTax: totals.totalAfterTax,
    };
    setDocumentItemGridData(newData);
  };

  const handleAddItem = () => {
    const newItem: DataSource = {
      key: keyCounter,
      itemId: "",
      itemName: "",
      UOM: "",
      HSN: "",
      quantity: 0,
      taxType: "",
      price: 0,
      totalAfterTax: 0,
    };
    setDocumentItemGridData([...dataSource, newItem]);
    setKeyCounter(keyCounter + 1);
  };

  const handleResetField = (index: number) => {
    let newData: any = [...dataSource];
    newData[index] = {
      ...newData[index],
      itemId: "",
      itemName: "",
      UOM: "",
      HSN: "",
      quantity: 0,
      price: 0,
      discount1: 0,
      discount2: 0,
      totalDiscount: 0,
      taxType: "",
      tax: 0,
      totalTax: 0,
      totalBeforeTax: 0,
      totalAfterTax: 0,
      length: 0,
      breadth: 0,
      height: 0,
    };
    setDocumentItemGridData(newData);
  };

  return (
    <>
      <Card className="cardTable">
        <div className="spaceTableContent">
          <div className="flexBox">
            <Button
              className="buttonAddItem"
              color="primary" variant="filled"
              onClick={handleAddItem}
            >
              {ADD_ITEM}
            </Button>
          </div>
          <div className="flexBox">
            <Input
              placeholder="Search by item Name or ID"
              className="inputSearchWIthId"
              onChange={handleSearchChange}
              suffix={
                <div className="divSuffixforInput">
                  <div className="divDivider"></div>
                  <SearchOutlined />
                </div>
              }
            />

            {/* <div className="buttonAddColumn">
              <Button onClick={openModal} type="default">
                {CUSTOM_FIELD}
              </Button>
            </div> */}

            <Select
              defaultValue="Select additional columns"
              style={{ minWidth: 200 }}
              onChange={handleAdditionalColumnsChange}
            >
              <Option value="discount1">{DISCOUNT}</Option>
              <Option value="successiveDiscount">{SUCCESSIVE_DISCOUNT}</Option>
            </Select>
          </div>
        </div>

        <Table
          dataSource={filteredDataSource}
          columns={columns}
          pagination={false}
          rowKey="key"
          className="table-component"
          scroll={{ x: "max-content" }}
        />

        <CustomMetaDataModal
          mode="table"
          customField={customFields}
          isVisible={isModalVisible}
          onSave={handleSaveCustomColumns}
          onClose={closeModal}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  itemsData: state.api.getItems?.data?.reverse(),
  getItemState: state.api.getItems,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  dataSource: state.ui.createDocumentUIData.itemData,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  setDocumentItemGridData: (payload: any) => dispatch(setDocumentItemGridData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentItemGrid);
