import * as type from "../../types/API/documentSeriesType";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const documentSeriesReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_DOCUMENT_SERIES:
      return {
        ...state,
        loading: true,
      };
    case type.GET_DOCUMENT_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_DOCUMENT_SERIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addDocumentSeriesReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ADD_DOCUMENT_SERIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_DOCUMENT_SERIES_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_DOCUMENT_SERIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editDocumentSeriesReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.EDIT_DOCUMENT_SERIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_DOCUMENT_SERIES_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_DOCUMENT_SERIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteDocumentSeriesReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_DOCUMENT_SERIES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_DOCUMENT_SERIES_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_DOCUMENT_SERIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const updateLastDocumentNumberReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.UPDATE_LAST_DOCUMENT_NUMBER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UPDATE_LAST_DOCUMENT_NUMBER_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.UPDATE_LAST_DOCUMENT_NUMBER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
