import { Radio,Tabs, Col, Form, Modal, Row, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { getBuyerSupplier } from "../../../redux/actions/API/buyerSupplier";
import { connect } from "react-redux";
import { AppConstants } from "../../../Appconstants"; 
import "./CompanyMetaDataModal.css";
import { setDocumentCompanyData, setDocumentType } from "../../../redux/actions/UI/createDocument";

interface CompanyMetaDataModalProps {
  title: string;
  isModalOpen: boolean;
  handleSubmit: (companyData: any) => void;
  handleCancel: () => void;
  buyerSupplierData: any;
  getBuyerSupplier: Function;
  companyId: number;
  setDocumentCompanyData: Function;
  setDocumentType: Function;
  companyAddressDetails: any;
  selectedCompanyNameAsProps?: string;
  isEditing?: boolean,
  buyerDefaultBillId? : number,
  buyerDefaultDelId? : number, 
}

const CompanyMetaDataModal: React.FC<CompanyMetaDataModalProps> = ({
  title,
  isModalOpen,
  handleSubmit,
  handleCancel,
  companyId,
  getBuyerSupplier,
  buyerSupplierData,
  setDocumentCompanyData,
  setDocumentType,
  companyAddressDetails,
  selectedCompanyNameAsProps,
  isEditing,
  buyerDefaultBillId,
  buyerDefaultDelId, 
}) => {
  const [form] = Form.useForm();
  const [selectedCompanyData, setSelectedCompanyData] = useState<any>(null);
  const [defaultDeliveryAddressId, setDefaultDeliveryAddressId] = useState<any>(null);
  const [defaultBillingAddressId, setDefaultBillingAddressId] = useState<any>(null);

  useEffect(() => {
    getBuyerSupplier({ companyId: Number(companyId) });
  }, [companyId, getBuyerSupplier]);

  useEffect(() => {
    handleCompanyChange(selectedCompanyNameAsProps);
    setDefaultDeliveryAddressId(buyerDefaultDelId);
    setDefaultBillingAddressId(buyerDefaultBillId);
  }, [isEditing]);

  useEffect(() => {  
    if (selectedCompanyData) {
      const deliveryAddresses = selectedCompanyData.addresses.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
      );
      const billingAddresses = selectedCompanyData.addresses.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      );

      if (deliveryAddresses.length > 0) {
        setDefaultDeliveryAddressId(deliveryAddresses[0].id);
      }

      if (billingAddresses.length > 0) {
        setDefaultBillingAddressId(billingAddresses[0].id);
      } else if (deliveryAddresses.length > 0) {
        setDefaultBillingAddressId(deliveryAddresses[0].id);
      }
    }
  }, [selectedCompanyData]);

  const handleCompanyChange = (value: string | undefined) => {
    const selected = buyerSupplierData?.find(
      (item: any) => item.companyName === value
    );
    setSelectedCompanyData(selected || null);
    setDefaultDeliveryAddressId(null);
    setDefaultBillingAddressId(null);
  };

  const getAddressType = (addressTypeId: number) => {
    return addressTypeId === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      ? AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_NAME
      : AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_NAME;
  };

  const handleModalOk = () => {
    setDocumentType(title)
    setDocumentCompanyData(companyAddressDetails);
    if (selectedCompanyData) {
      const selectedDeliveryAddress = selectedCompanyData.addresses?.find(
        (address: any) => address.id === defaultDeliveryAddressId
      );
      const selectedBillingAddress = selectedCompanyData.addresses.find(
        (address: any) => address.id === defaultBillingAddressId
      );
      const buyerDeliveryAddress = selectedDeliveryAddress.addressLineOne + ', ' + selectedDeliveryAddress.addressLineTwo + ', ' + selectedDeliveryAddress.city + ', ' + selectedDeliveryAddress.state + ', ' + selectedDeliveryAddress.country + '. ' + selectedDeliveryAddress.pincode;

      const buyerBillingAddress = selectedBillingAddress.addressLineOne + ', ' + selectedBillingAddress.addressLineTwo + ', ' + selectedBillingAddress.city + ', ' + selectedBillingAddress.state + ', ' + selectedBillingAddress.country + '. ' + selectedBillingAddress.pincode;
      
      handleSubmit({
        buyerName: selectedCompanyData.companyName,
        buyerEmail: selectedCompanyData.companyEmail,
        buyerContactNumber: selectedCompanyData.phone,
        buyerSelectedDeliveryAddressId: selectedDeliveryAddress.id,
        buyerSelectedBillingAddressId: selectedBillingAddress.id,
        buyerDeliveryAddress: buyerDeliveryAddress,
        buyerBillingAddress: buyerBillingAddress,
      });
    }
  };

  const handleCancelClick = () => {
    if (!window.location.href.includes('create_document')) {
      form.resetFields();
      setSelectedCompanyData(null);
    }
    handleCancel();
  };

  const handleRadioChange = (addressId: number, addressType: number) => {
    if (addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) {
      setDefaultDeliveryAddressId(addressId);
    } else if (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) {
      setDefaultBillingAddressId(addressId);
    }
  };

  const renderBuyerAddress = (address: any, index: number, addressType: number) => (
    <div key={index} className="addressList" style={{ position: "relative" }}>
      <div className="addressHeader flexBox">
        <div className="addressType">{getAddressType(address.addressType)}</div>

        <div className="radioContainer">
          <Radio
            checked={
              address.id ===
              (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                ? defaultBillingAddressId
                : defaultDeliveryAddressId)
            }
            onChange={() => handleRadioChange(address.id, addressType)}
          />
        </div>
      </div>
      <div className="addressDetails">
        <div>
          {`${address.addressLine1 ? address.addressLine1 : address.addressLineOne
            } ${address.addressLine2 ? address.addressLine2 : address.addressLineTwo
            } ${address.city} ${address.state} ${address.pincode} ${address.country
            }`}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancelClick}
        footer={[
          <Button key="cancel" onClick={handleCancelClick}>
            Cancel
          </Button>,
          <Button
            type="primary"
            key="ok"
            disabled={
              !selectedCompanyData ||
              !defaultDeliveryAddressId ||
              !defaultBillingAddressId
            }
            onClick={handleModalOk}
          >
            Submit
          </Button>,
        ]}
        maskClosable={false}
      >
        <Form layout="vertical" form={form}>
          <Row gutter={24}>
            <Col span={24}>
              {!isEditing && <Form.Item
                name="company"
                label="Company"
                rules={[
                  { required: true, message: "Please choose the company" },
                ]}
              >
                <Select
                  placeholder="Please choose the company"
                  onChange={handleCompanyChange}
                >
                  {buyerSupplierData?.map((item: any) => (
                    <Select.Option key={item.id} value={item.companyName}>
                      {item.companyName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>}
            </Col>
          </Row>
        </Form>

        {selectedCompanyData ? (
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Delivery Address" key="1">
              {selectedCompanyData.addresses?.filter(
                (address: any) =>
                  address.addressType ===
                  AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
              ) ? (
                selectedCompanyData.addresses
                  .filter(
                    (address: any) =>
                      address.addressType ===
                      AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
                  )
                  .map((address: any, index: number) =>
                    renderBuyerAddress(
                      address,
                      index,
                      AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
                    )
                  )
              ) : (
                <div className="addressList">
                  No delivery addresses available.
                </div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Billing Address" key="2">
              {selectedCompanyData.addresses?.filter(
                (address: any) =>
                  address.addressType ===
                  AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
              ) ? (
                selectedCompanyData.addresses
                  .filter(
                    (address: any) =>
                      address.addressType ===
                      AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                  )
                  .map((address: any, index: number) =>
                    renderBuyerAddress(
                      address,
                      index,
                      AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                    )
                  )
              ) : (
                <div className="addressList">
                  No billing addresses available.
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <div>No company selected yet.</div>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({ 
  buyerSupplierData: state.api.getBuyerSupplier?.data?.reverse(),
  companyId: state.api.login.data.companyId,
  companyAddressDetails: state.ui.documentItemsGridData?.documentCompanyData,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
  setDocumentCompanyData: (payload: any) =>
    dispatch(setDocumentCompanyData(payload)),
  setDocumentType: (payload: any) =>
    dispatch(setDocumentType(payload)),
});

export default connect(  mapStateToProps,  mapDispatchToProps)(CompanyMetaDataModal);
