// blogPostsData.ts
import BlogPost1 from '../Utility/images/blog/post-1-img.jpg'
import BlogPost2 from '../Utility/images/blog/post-2-img.jpg'
import BlogPost3 from '../Utility/images/blog/post-3-img.jpg';
import BlogPost4 from '../Utility/images/blog/post-4-img.jpg';
import BlogPost5 from '../Utility/images/blog/post-5-img.jpg';
import BlogPost6 from '../Utility/images/blog/post-6-img.jpg';
import BlogPost7 from '../Utility/images/blog/post-7-img.jpg';
import BlogPost8 from '../Utility/images/blog/post-8-img.jpg';
import BlogPost9 from '../Utility/images/blog/post-9-img.jpg';
import BlogPost10 from '../Utility/images/blog/post-10-img.jpg';
import BlogPost11 from '../Utility/images/blog/post-11-img.jpg';

export const blogPosts = [
    {
      image: BlogPost11,
      title: "Congue magna tempor and ipsum Martex sapien turpis laoreet augue",
      header: "Social Media Marketing",
      description: "Aliqum mullam blandit vitae and tempor sapien and donec lipsum gravida porta undo velna dolor libero a risus aliquet tempus posuere a tempor velna tempus posuere dolor",
      uploadedBy: "EaseMargin Team",
      date: { day: 28, month: "Apr", year: 2023 }
    },
    {
      image: BlogPost1,
      title: "Aliqum mullam porta blandit: lacus and sapien gravida",
      header: "Product News",
      description: "Egestas luctus vitae augue and ipsum ultrice quisque in cursus lacus feugiat congue diam ultrice laoreet sagittis",
      uploadedBy: "EaseMargin Team",
      date: { day: 23, month: "Apr", year: 2023 }
    },
    {
      image: BlogPost2,
      title: "Porttitor cursus fusce neque CEO egestas cursus magna sapien and suscipit ipsum",
      header: "Community",
      description: "Aliqum mullam ipsum vitae and blandit vitae tempor sapien and donec lipsum",
      uploadedBy: "Harish Chandra",
      date: { day: 9, month: "Apr", year: 2023 }
    },
    {
      image: BlogPost3,
      title: "Cubilia laoreet ipsum augue eget egestas Martex magna",
      header: "Freelancer Tips",
      description: "Luctus vitae egestas augue and ipsum ultrice quisque in cursus lacus feugiat egets congue ultrice sagittis laoreet",
      uploadedBy: "Rahul Deo Burman.",
      date: { day: 1, month: "Apr", year: 2023 }
    },
    {
      image: BlogPost4,
      title: "Phasellus blandit justo undo aliquam vitae molestie nunc sapien augue justo aliquet",
      header: "Tutorials",
      description: "Aliqum mullam ipsum vitae and blandit vitae tempor sapien and donec lipsum",
      uploadedBy: "Harish Chandra",
      date: { day: 20, month: "Mar", year: 2023 }
    },
    {
      image: BlogPost5,
      title: "Blandit justo phasellus undo aliquam diam molestie vitae",
      header: "Freelancer Tips",
      description: "Luctus vitae egestas augue and ipsum ultrice quisque in cursus lacus feugiat egets congue ultrice sagittis laoreet",
      uploadedBy: "EaseMargin Team",
      date: { day: 13, month: "Mar", year: 2023 }
    },
    {
      image: BlogPost6,
      title: "Cursus porttitor fusce neque CEO egestas cursus magna sapien and ipsum suscipit",
      header: "Extensions",
      description: "Mullam ipsum aliqum vitae and blandit vitae tempor sapien and lipsum donec",
      uploadedBy: "EaseMargin Team",
      date: { day: 23, month: "Feb", year: 2023 }
    },
    {
      image: BlogPost7,
      title: "Phasellus blandit justo undo aliquam diam vitae molestie",
      header: "Product News",
      description: "Egestas luctus vitae augue and ipsum ultrice quisque in cursus lacus feugiat congue diam ultrice laoreet sagittis",
      uploadedBy: "EaseMargin Team",
      date: { day: 2, month: "Feb", year: 2023 }
    },
    {
      image: BlogPost8,
      title: "Porttitor cursus fusce neque CEO egestas cursus magna sapien and suscipit ipsum",
      header: "Freelancer Tips",
      description: "Aliqum mullam ipsum vitae and blandit vitae tempor sapien and donec lipsum",
      uploadedBy: "Harish Chandra",
      date: { day: 26, month: "Jan", year: 2023 }
    },
    {
      image: BlogPost9,
      title: "Aliqum mullam porta blandit: lacus and sapien gravida",
      header: "Community",
      description: "Luctus vitae egestas augue and ipsum ultrice quisque in cursus lacus feugiat egets congue ultrice sagittis laoreet",
      uploadedBy: "Rahul Deo Burman",
      date: { day: 12, month: "Jan", year: 2023 }
    }
  ];
  